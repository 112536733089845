import React from "react";
import { Search } from "lucide-react";

const AccountComponent = ({ onPress, accountStatus }) => {
  const activeStyles = {
    border: "border-green-500",
    bg: "bg-green-50",
    hover: "hover:bg-green-100",
    active: "active:bg-green-200",
    dot: "bg-green-500",
    text: "text-green-700"
  };

  const inactiveStyles = {
    border: "border-red-500",
    bg: "bg-red-50",
    hover: "hover:bg-red-100",
    active: "active:bg-red-200",
    dot: "bg-red-500",
    text: "text-red-700"
  };

  const styles = accountStatus === "Active" ? activeStyles : inactiveStyles;

  return (
    <button 
      onClick={onPress}
      className="relative flex items-center group cursor-pointer"
    >
      <div className={`
        flex items-center space-x-2 rounded px-3 py-1.5
        ${styles.border} ${styles.bg} ${styles.hover} ${styles.active}
        transition-all duration-200 
        transform group-hover:scale-105
        shadow-sm group-hover:shadow-md
      `}>
        <div className={`h-2 w-2 rounded-full ${styles.dot}`}></div>
        <span className={`text-sm font-medium ${styles.text}`}>
          {accountStatus === "Active" ? "Account Active" : "Account Inactive"}
        </span>
      </div>
    </button>
  );
};

export default AccountComponent;