import React from 'react';
import { Settings, Crown, BadgePercent, Target, HelpCircle, Plus, ChevronRight } from 'lucide-react';
import PageHeader from 'pages/locationNew/header';

const MerchantCashbackDashboard = ({ 
  onUpdateRates,
  onLearnMoreClick,
  pendingRates = {
    goldCashback: 8.5,
    regularCashback: 3.5,
    spendingThreshold: 3000
  }
}) => {
  const Tab = () => {
    return (
      <button
        className="group relative flex-1 px-5 py-3.5 z-10"
      >
        <div className="relative flex items-start gap-3">
          <div className="p-1.5 rounded-md bg-[#0D4029]/10">
            <Settings className="w-3.5 h-3.5 text-[#0D4029]" />
          </div>

          <div className="flex-1 text-left">
            <div className="flex items-center gap-11">
              <span className="text-sm font-medium tracking-tight text-[#0D4029] whitespace-nowrap">
                Pending Updates
              </span>
              <span className="text-[10px] font-medium px-1.5 py-0.5 rounded-full bg-[#0D4029] text-white whitespace-nowrap">
                Draft
              </span>
            </div>
            <div className="flex items-center gap-1.5 mt-0.5">
              <p className="text-[11px] text-gray-600">
                Configure future rates
              </p>
              <ChevronRight className="w-3 h-3 text-[#0D4029] translate-x-0.5" />
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gradient-to-r from-[#0D4029]/0 via-[#0D4029] to-[#0D4029]/0 opacity-100" />
      </button>
    );
  };

  const RateCard = ({ 
    icon: Icon, 
    label, 
    value, 
    unit = '%', 
    helperText 
  }) => (
    <div className="group relative">
      <div className="relative h-52 bg-white rounded-xl border border-[#0D4029]/10 overflow-hidden transition-all duration-300 hover:shadow-lg">
        <div className="h-full p-6 flex flex-col justify-between">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="p-2 rounded-lg bg-[#0D4029]/5 group-hover:scale-110 transition-all duration-300">
                  <Icon className="w-5 h-5 text-[#0D4029]" />
                </div>
                <div className="flex items-center space-x-2">
                  <div className="w-1.5 h-1.5 rounded-full bg-[#0D4029] animate-pulse" />
                  <span className="text-xs font-medium text-[#0D4029]/70">Configurable</span>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-baseline space-x-1 mb-2">
                <span className="text-3xl font-bold text-[#0D4029] tracking-tight">
                  {value.toLocaleString()}
                </span>
                <span className="text-sm font-medium text-[#0D4029]/60">{unit}</span>
              </div>
              <span className="text-sm font-medium text-[#0D4029]/80 block">
                {label}
              </span>
            </div>
          </div>

          {helperText && (
            <div className="pt-4 border-t border-[#0D4029]/5">
              <p className="text-xs text-[#0D4029]/60 leading-relaxed">
                {helperText}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen">
     <PageHeader
        title="Loyalty & Cashback Rate Management"
        description="Configure and review rate changes before they're locked in for the next period once you activate you account."
       
      />

        <div className="border-b mb-6">
          <div className="px-8">
            <div className="w-1/2">
              <Tab />
            </div>
          </div>
        </div>

        <div className="px-8">
          <div className="mb-8">
            <p className="text-sm text-gray-600">
              Configure and review rate changes before they're locked in for the next period.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <RateCard
              icon={Crown}
              label="Gold Status Cashback"
              value={pendingRates.goldCashback}
              helperText="Enhanced rewards rate for Gold Status members. This rate applies to all qualifying purchases."
            />
            <RateCard
              icon={BadgePercent}
              label="Regular Cashback"
              value={pendingRates.regularCashback}
              helperText="Base rewards rate for all customers. Automatically applied to every purchase."
            />
            <RateCard
              icon={Target}
              label="Gold Status Threshold"
              value={pendingRates.spendingThreshold}
              unit="$"
              helperText="Quarterly spending requirement to achieve and maintain Gold Status benefits."
            />
          </div>

          <div className="flex items-center justify-between gap-4 pt-6 border-t border-gray-200">
            <button 
              onClick={() => onLearnMoreClick('pending')}
              className="flex items-center gap-2 text-sm font-medium text-[#0D4029] hover:text-opacity-80 transition-colors"
            >
              <HelpCircle className="w-4 h-4" />
              Rate Update Guidelines
            </button>

            <button 
              onClick={onUpdateRates}
              className="px-4 py-2 bg-[#0D4029] text-white text-sm font-medium rounded-lg hover:bg-opacity-90 transition-colors"
            >
              Update Pending Cashback
            </button>
          </div>
        </div>
      </div>

  );
};

export default MerchantCashbackDashboard;