import React from 'react';
import { ChevronDown } from 'lucide-react';

export const BusinessTypeStep = ({ formData, setFormData, businessTypes }) => {
  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Business Type</h2>
        <p className="text-sm text-gray-500">Select the type of business for this location</p>
      </div>
      <div className="space-y-4 relative">
        <div className="relative">
          <select
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            className="w-full appearance-none border border-gray-200 rounded-lg px-3 py-2.5 pr-10 bg-white focus:outline-none focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029] cursor-pointer text-gray-900 [&>*]:py-2 [&>option]:px-3 [&>option]:bg-white [&>option]:text-gray-900"
            style={{
              backgroundImage: 'none'
            }}
          >
            <option value="" className="py-2" disabled>Select business type</option>
            {businessTypes?.map((type) => (
              <option 
                key={type.value} 
                value={type.value}
                className="py-2 hover:bg-gray-100"
              >
                {type.label}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <ChevronDown className="h-5 w-5 text-gray-500" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessTypeStep;