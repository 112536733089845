export const ReviewStep = ({ formData }) => {
    return (
      <div className="space-y-6">
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Review Information</h2>
          <p className="text-sm text-gray-500">Please review your business information before submitting</p>
        </div>
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-medium text-gray-700">Business Name:</h3>
              <p className="text-gray-900">{formData.name}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-700">Business Type:</h3>
              <p className="text-gray-900">{formData.type}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-700">Email:</h3>
              <p className="text-gray-900">{formData.email}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-700">Phone:</h3>
              <p className="text-gray-900">{formData.number}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-700">Website:</h3>
              <p className="text-gray-900">{formData.website}</p>
            </div>
            <div>
              <h3 className="font-medium text-gray-700">Address:</h3>
              <p className="text-gray-900">{formData.address.address}</p>
            </div>
          </div>
          <div>
            <h3 className="font-medium text-gray-700">Description:</h3>
            <p className="text-gray-900">{formData.description}</p>
          </div>
        </div>
      </div>
    );
  };