import React from 'react';

function AccountLoading() {
  return (
    <div className="flex flex-col items-center justify-center w-full p-8 space-y-8">
      {/* Primary Loading Animation with Profile Icon */}
      <div className="relative">
        {/* Profile Icon Container */}
        <div className="relative w-16 h-16 mb-2">
          <div className="absolute inset-0 flex items-center justify-center">
            <svg 
              className="w-12 h-12 text-[#0D4029]" 
              viewBox="0 0 24 24" 
              fill="currentColor"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/>
            </svg>
          </div>
        </div>

        {/* Rotating Ring Animation */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="w-20 h-20 border-4 border-[#0D4029]/10 rounded-full" />
          <div 
            className="absolute inset-0 border-4 border-[#0D4029] rounded-full border-t-transparent animate-spin"
            style={{ 
              animationDuration: '1.5s', 
              animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)' 
            }} 
          />
        </div>
      </div>

      {/* Status Message */}
      <div className="space-y-2 text-center">
        <h2 
          className="text-lg font-medium text-gray-900"
          style={{ 
            fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif',
          }}
        >
          Loading Account Details
        </h2>
        <p className="text-sm text-gray-500">
          Setting up your account
        </p>
      </div>

      {/* Progress Bar */}
      <div className="w-full max-w-[200px]">
        <div className="h-1 bg-[#0D4029]/10 rounded-full overflow-hidden">
          <div 
            className="h-full bg-[#0D4029] rounded-full animate-[shimmer_2s_ease-in-out_infinite]"
            style={{
              '@keyframes shimmer': {
                '0%': { transform: 'translateX(-100%)' },
                '50%': { transform: 'translateX(100%)' },
                '100%': { transform: 'translateX(-100%)' }
              }
            }}
          />
        </div>
      </div>

      {/* Optional: Processing Steps */}
      <div className="flex flex-col items-center space-y-1">
        <div className="text-xs text-gray-400">
          • Verifying credentials
        </div>
        <div className="text-xs text-gray-400">
          • Preparing your dashboard
        </div>
      </div>
    </div>
  );
}

export default AccountLoading;