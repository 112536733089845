import React, { useState } from 'react';
import { Plus} from 'lucide-react';

const NoBoostsState = ({addBoostClick}) => (
    <div className="text-center py-16 border border-gray-200 rounded-lg">
      <div className="mx-auto w-24 h-24 border-2 border-gray-100 rounded-full flex items-center justify-center mb-4">
        <Plus className="w-8 h-8 text-gray-400" />
      </div>
      <h3 className="text-xl font-semibold text-gray-900">No boosts yet</h3>
      <p className="mt-2 text-gray-600 max-w-md mx-auto">
        Create your first boost to start attracting customers and drive more sales to your store
      </p>
      <button className="mt-6 px-5 py-2.5 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
          onClick={addBoostClick}>
        Create Boost
      </button>
    </div>
  );

export default NoBoostsState;