import React, { useState } from 'react';
import { X, Check, Loader2 } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { addEmployee } from 'features/register/registerSlice';

// Keyframes for animations
const successKeyframes = `
  @keyframes ping {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(2); opacity: 0; }
  }
  @keyframes scale {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes slideUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

// Add styles to document head
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.textContent = successKeyframes;
  document.head.appendChild(style);
}

const EmployeeModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!firstName || !lastName || !email) {
      setError('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      await fetchFeedback({
        endpoint: 'merchant/add/employee/',
        data: { 
          first_name: firstName, 
          last_name: lastName, 
          email 
        },
        requestType: "POST",
        onSuccess: (responseData) => {
          dispatch(addEmployee({
            first_name: firstName, 
            last_name: lastName, 
            email
          }));
          setIsSuccess(true);
          setTimeout(() => {
            handleClose();
          }, 2000); // Close after showing success state
        },
      });
    } catch (err) {
      setError(err.message || 'Failed to add employee. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setError('');
    setIsSuccess(false);
    setIsClosing(false);
    onClose();
  };

  if (!isOpen) return null;

  const SuccessState = () => (
    <div className="flex flex-col items-center justify-center py-6">
      <div className="relative mb-4">
        <div className="absolute inset-0 animate-[ping_1s_ease-out_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/10" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.75s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/20" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.5s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/30" />
        </div>
        <div className="relative flex h-16 w-16 items-center justify-center rounded-full bg-green-100 animate-[scale_0.3s_ease-out_0.2s_both]">
          <Check className="h-10 w-10 text-green-600 animate-[scale_0.2s_ease-out_0.4s_both]" />
        </div>
      </div>
      <div className="space-y-1 text-center animate-[fadeIn_0.5s_ease-out_0.6s_both]">
        <h3 className="text-lg font-semibold text-gray-900">
          Employee Added Successfully
        </h3>
        <p className="text-sm text-gray-500">
          An email has been sent to {email} with their access credentials.
        </p>
      </div>
    </div>
  );

  const LoadingState = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
      <div className="flex flex-col items-center space-y-4">
        <div className="relative">
          <div className="h-12 w-12 rounded-full border-4 border-[#0D4029]/20 opacity-75 animate-ping" />
          <Loader2 className="absolute inset-0 h-12 w-12 text-[#0D4029] animate-spin" />
        </div>
        <p className="text-sm font-medium text-gray-600">Adding employee...</p>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black/30 transition-opacity" 
        onClick={handleClose} 
      />
      
      <div className={`relative w-full max-w-md bg-white shadow-xl rounded-lg transform transition-all duration-300 ${
        isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
      }`}>
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Add New Employee</h2>
          <button
            onClick={handleClose}
            className="rounded-full p-1 text-gray-500 hover:bg-gray-100"
            disabled={isSubmitting}
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="px-6 py-4 relative">
          {isSuccess ? (
            <SuccessState />
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="rounded-md bg-red-50 p-4 animate-[fadeIn_0.2s_ease-out]">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}

              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="mb-1.5 block text-sm font-medium text-gray-700">
                      First Name
                    </label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 px-4 py-2.5 text-gray-900 placeholder-gray-500 transition-all duration-200 focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20 focus:outline-none disabled:bg-gray-50"
                      placeholder="Jane"
                      disabled={isSubmitting}
                    />
                  </div>
                  <div>
                    <label className="mb-1.5 block text-sm font-medium text-gray-700">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="w-full rounded-lg border border-gray-300 px-4 py-2.5 text-gray-900 placeholder-gray-500 transition-all duration-200 focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20 focus:outline-none disabled:bg-gray-50"
                      placeholder="Doe"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <div>
                  <label className="mb-1.5 block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full rounded-lg border border-gray-300 px-4 py-2.5 text-gray-900 placeholder-gray-500 transition-all duration-200 focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20 focus:outline-none disabled:bg-gray-50"
                    placeholder="jane@example.com"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              <div className="rounded-md bg-gray-50 p-4">
                <p className="text-sm text-gray-600">
                  This employee will receive an email with their POS credentials and payment processing access information.
                </p>
              </div>
            </form>
          )}

          {isSubmitting && <LoadingState />}
        </div>

        {!isSuccess && (
          <div className="border-t border-gray-200 bg-white px-6 py-4 rounded-b-lg">
            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="w-full rounded-lg bg-[#0D4029] px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-[#0D4029]/90 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2 disabled:opacity-50"
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Adding Employee...
                </span>
              ) : (
                'Add Employee'
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeModal;


