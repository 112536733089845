import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ChevronRight, Plus, Minus, Check, AlertCircle, Package } from 'lucide-react';
import PageHeader from 'pages/locationNew/header';
import { selectAllItems } from 'features/cart/cartSelector';

const MaterialsPage = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [addedToCart, setAddedToCart] = useState(null);
  const [cartItems, setCartItems] = useState({});
  const allItems = useSelector(selectAllItems);
  
  const getLatestCart = () => {
    try {
      return JSON.parse(localStorage.getItem('clavaaCart') || '{}');
    } catch (error) {
      console.error('Error reading cart from localStorage:', error);
      return {};
    }
  };

  useEffect(() => {
    setCartItems(getLatestCart());
  }, []);

  const handleQuantityChange = (productId, change) => {
    const stringId = String(productId);
    setQuantities(prev => ({
      ...prev,
      [stringId]: Math.max(0, (prev[stringId] || 0) + change)
    }));
  };

  const handleAddToCart = (product) => {
    const stringId = String(product.id);
    if (quantities[stringId] > 0) {
      const currentCart = getLatestCart();
      
      const newCartItems = {
        ...currentCart,
        [stringId]: (currentCart[stringId] || 0) + quantities[stringId]
      };
      
      localStorage.setItem('clavaaCart', JSON.stringify(newCartItems));
      setCartItems(newCartItems);
      
      setQuantities(prev => ({
        ...prev,
        [stringId]: 0
      }));
      
      setAddedToCart(stringId);
      setTimeout(() => setAddedToCart(null), 2000);
    }
  };



  const ProductCard = ({ product }) => {
    const currentCart = getLatestCart();
    const stringId = String(product.id);
    const isInCart = currentCart[stringId] > 0;
    
    return (
      <div className="group bg-white rounded-xl border border-gray-100 hover:shadow-md transition-all duration-300">
        <div className="relative overflow-hidden">
          <img 
            src={product.image || '/api/placeholder/400/400'} 
            alt={product.name}
            className="w-full aspect-square object-cover rounded-t-xl group-hover:scale-105 transition-transform duration-300"
          />
          {product.highlights && (
            <div className="absolute top-3 right-3">
              <div className="bg-[#0D4029] text-white text-xs px-3 py-1.5 rounded-full font-medium">
                {product.highlights}
              </div>
            </div>
          )}
          {isInCart && (
            <div className="absolute top-3 left-3">
              <div className="bg-green-600 text-white text-xs px-3 py-1.5 rounded-full font-medium flex items-center">
                <Check className="w-3 h-3 mr-1" />
                In Cart
              </div>
            </div>
          )}
        </div>
        <div className="p-4">
          <h3 className="font-semibold text-gray-900 text-sm group-hover:text-[#0D4029] transition-colors">
            {product.name}
          </h3>
          <p className="text-xs text-gray-600 mt-1 line-clamp-2">{product.description}</p>
          <div className="flex items-center justify-between mt-3">
            <span className="font-bold text-sm text-[#0D4029]">${product.price}</span>
            <button
              onClick={() => setSelectedProduct(product)}
              className="text-xs text-[#0D4029] hover:text-[#0D4029]/80 font-medium flex items-center group/btn"
            >
              Details
              <ChevronRight className="w-3 h-3 ml-0.5 group-hover/btn:translate-x-0.5 transition-transform" />
            </button>
          </div>
        </div>
        <div className="px-4 py-3 border-t border-gray-100 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => handleQuantityChange(stringId, -1)}
              className="w-6 h-6 flex items-center justify-center rounded-full border border-gray-200 hover:border-[#0D4029] hover:text-[#0D4029] transition-colors"
              disabled={!quantities[stringId]}
            >
              <Minus className="w-3 h-3" />
            </button>
            <span className="text-sm font-medium w-6 text-center">{quantities[stringId] || 0}</span>
            <button 
              onClick={() => handleQuantityChange(stringId, 1)}
              className="w-6 h-6 flex items-center justify-center rounded-full border border-gray-200 hover:border-[#0D4029] hover:text-[#0D4029] transition-colors"
            >
              <Plus className="w-3 h-3" />
            </button>
          </div>
          <button
            onClick={() => handleAddToCart(product)}
            className={`relative flex items-center ${
              addedToCart === stringId 
                ? 'bg-green-600 text-white' 
                : 'bg-[#0D4029] text-white'
            } text-xs px-3 py-1.5 rounded-lg hover:opacity-90 transition-all duration-300 disabled:opacity-50 disabled:hover:opacity-50`}
            disabled={!quantities[stringId]}
          >
            {addedToCart === product.id ? (
              <>
                <Check className="w-3 h-3 mr-1" />
                Added
              </>
            ) : (
              'Add to cart'
            )}
          </button>
        </div>
      </div>
    );
  };

  const ProductDetails = ({ product, onClose }) => (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50 animate-in fade-in duration-200" onClick={onClose}>
      <div 
        className="bg-white rounded-2xl max-w-2xl w-full max-h-[85vh] overflow-y-auto animate-in slide-in-from-bottom-4 duration-300"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-6">
          <div className="space-y-6">
            <div className="relative">
              <img 
                src={product.image || '/api/placeholder/400/400'} 
                alt={product.name}
                className="w-full h-72 object-cover rounded-xl"
              />
              {product.highlights && (
                <div className="absolute top-3 right-3">
                  <div className="bg-[#0D4029] text-white text-xs px-3 py-1.5 rounded-full font-medium">
                    {product.highlights}
                  </div>
                </div>
              )}
            </div>
            <div>
              <h2 className="text-xl font-bold text-gray-900">{product.name}</h2>
              <p className="text-gray-600 mt-2">{product.description}</p>
              <div className="mt-4">
                <span className="text-2xl font-bold text-[#0D4029]">${product.price}</span>
              </div>
              
              <div className="mt-4 bg-gray-50 rounded-lg p-4 flex items-start space-x-3">
                <AlertCircle className="w-5 h-5 text-[#0D4029] flex-shrink-0 mt-0.5" />
                <div>
                  <h4 className="font-medium text-sm text-gray-900">Free Shipping</h4>
                  <p className="text-xs text-gray-600 mt-1">
                    Estimated delivery: 3-5 business days
                  </p>
                </div>
              </div>
              
              {product.features && (
                <div className="mt-6">
                  <h3 className="font-semibold text-gray-900 mb-3">Features</h3>
                  <ul className="space-y-2">
                    {product.features.map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <div className="mt-1.5 mr-3 w-1 h-1 bg-[#0D4029] rounded-full"></div>
                        <span className="text-sm text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="flex space-x-3">
              <button
                onClick={onClose}
                className="flex-1 py-2.5 rounded-lg border-2 border-[#0D4029] text-[#0D4029] font-medium hover:bg-[#0D4029]/5 transition-colors"
              >
                Close
              </button>
              <button
                onClick={() => {
                  const stringId = String(product.id);
                  handleQuantityChange(stringId, 1);
                  handleAddToCart(product);
                  onClose();
                }}
                className="flex-1 bg-[#0D4029] text-white py-2.5 rounded-lg hover:bg-[#0D4029]/90 transition-colors font-medium"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <PageHeader
        title="Store Materials"
        description="Select the materials you need for your store. All items are provided free of charge to Clavaa merchants."
        backArrow={false}
      />
      <div className="px-4 py-6">
        <div className="max-w-screen-2xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 xl:gap-8">
            {Object.values(allItems).map(product => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
          {selectedProduct && (
            <ProductDetails 
              product={selectedProduct} 
              onClose={() => setSelectedProduct(null)} 
            />
          )}
        </div>
      </div>
      
      <div className="mt-12 border-t border-gray-200">
        <div className="max-w-screen-2xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h4 className="text-sm font-semibold text-gray-900 mb-2">Free Shipping</h4>
              <p className="text-sm text-gray-600">
                All materials ship free of charge to verified Clavaa merchants within the continental US.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-semibold text-gray-900 mb-2">Secure Packaging</h4>
              <p className="text-sm text-gray-600">
                Your equipment arrives safely in specially designed protective packaging.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-semibold text-gray-900 mb-2">Need Help?</h4>
              <p className="text-sm text-gray-600">
                Contact our merchant support team at <span className="text-[#0D4029]">help@clavaa.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      
    
    </div>
  );
};

export default MaterialsPage;