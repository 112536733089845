import { createSlice } from "@reduxjs/toolkit";
import { updateDashboardMetrics } from "../dashboard/dashboardSlice";
import { setPendingBalance, setBankData } from "../banking/bankSlice";
import { setDwollaId, setStatus, setActivationStatus,  } from "../user/userSlice";
import { updateRegisterData, setEmployees } from "../register/registerSlice";

import { setTransactionCount, setTransactions } from "../transactions/transactionsSlice";
import { setStoreLocation } from "../stores/storesSlice";
import { setBusinessType, setBusinessName } from "../verification/verificationInfoSlice";
import { setCashbackDetails, setCashbackHistory, setCurrentCashback } from "../cashback/cashbackSlice";
import { useStripe } from "../../config";
import { use } from "react";

// This function will be used to set the initial data
export const setInitialDashboardData = (data) => (dispatch) => {
  // Dispatch updates to various parts of the state:
  dispatch(setCashbackDetails(data.cashback_offer));
  dispatch(updateDashboardMetrics(data.dashboard_data));
  dispatch(setBankData(data.account));
  dispatch(setPendingBalance(data.pending_balance));
  dispatch(setEmployees(data.employees));
  dispatch(setActivationStatus(data.merchant_profile.activationStatus));
if (!useStripe) {
 
  if (data.merchant_profile.isNotSoleProp === false)
  { dispatch(setBusinessType("soleProprietorship"));}
  if (data.merchant_profile.businessName)
    { dispatch(setBusinessName(data.merchant_profile.businessName));}
  if (data.merchant_profile.dwolla_id)
    { dispatch(setDwollaId(data.merchant_profile.dwolla_id));}
} if (useStripe) {

}
  dispatch(setStatus(data.status));
  
  dispatch(updateRegisterData(data.register_data));
  dispatch(setTransactions(data.transactions));
  dispatch(setTransactionCount(data.transaction_count));
  dispatch(setStoreLocation(data.store_locations));
  
  // Update the state to reflect that the data has been fetched
  dispatch(initialDataSlice.actions.setHasFetched(true));
};

const initialDataSlice = createSlice({
  name: "initialData",
  initialState: {
    hasFetched: false,
  },
  reducers: {
    setHasFetched(state, action) {
      state.hasFetched = action.payload;
    },
  },
});

export const { setHasFetched } = initialDataSlice.actions;
export default initialDataSlice.reducer;
