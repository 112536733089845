import React, { useState } from "react";

import {

  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectDocuments,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import useFullFetchFeedback from "../../functions/apiFetchers/fullPageLoaders";


const DocumentsStripe = () => {
  const fetchFeedback = useFullFetchFeedback(); // Move the hook to the top level
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      try {
        const sessionData = await new Promise((resolve, reject) => {
          fetchFeedback({
            endpoint: "merchant/stripe/create/documents/token/",
            requestType: "GET",
            onSuccess: (data) => resolve(data),
            onError: (error) => reject(error),
          });
        });

        if (sessionData?.client_secret) {
          return sessionData.client_secret;
        } else {
          console.error("Client secret not found in session data.");
          return undefined;
        }
      } catch (error) {
        console.error("Failed to fetch client secret:", error);
        return undefined;
      }
    };

    // Initialize Stripe Connect instance
    return loadConnectAndInitialize({
      publishableKey: "pk_live_51QFSOAP1SGiwmmH3YlolMRKcLk2ddVxZZFwvW8wa4yFyk8ebTHk5PvjtVLV0IGVdigGEOJbPXpxavpP6bqrAXWgb00DPC8CDx8",
      fetchClientSecret: fetchClientSecret,
      appearance: {
  
      
        variables: {
        
          colorPrimary: "#3da843",
        },
      },
    });
  });

  return (
    <>
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectDocuments 
        
       
        />
        </ConnectComponentsProvider>
</>
  );
};

export default DocumentsStripe;