// CreateLocationModal.js
import React, { useState, useEffect } from 'react';
import { X, Check, Loader } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllStoreLocations } from 'features/stores/storesSelectors';
import { addStoreLocation } from 'features/stores/storesSlice';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { BusinessTypeStep } from './BusinessTypeStep';
import { BasicBusinessInfoStep } from './BasicBusinessInfoStep';
import { ContactDetailsStep } from './ContactDetailsStep';
import { LocationDetailsStep } from './LocationDetailsStep';
import { BusinessDescriptionStep } from './BusinessDescriptionStep';
import { BusinessPhotoStep } from './BusinessPhotoStep';
import { ReviewStep } from './ReviewStep';
import { ProcessingStep } from './ProcessingStep';
import { SuccessStep } from './SuccessStep';


const Dialog = ({ open, onOpenChange, children }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => onOpenChange(false)}
      />
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

const DialogContent = ({ children }) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl w-[500px] max-w-[95vw] max-h-[90vh] p-6 relative overflow-y-auto">
      {children}
    </div>
  );
};

const businessTypes = [
  { value: 'retail', label: 'Retail Store' },
  { value: 'restaurant', label: 'Restaurant' },
  { value: 'service', label: 'Service Business' },
  { value: 'office', label: 'Office Space' },
  { value: 'other', label: 'Other' }
];

const CreateLocationModal = ({ isOpen, onClose, handleLocationAdded }) => {
  const dispatch = useDispatch();
  const locationList = useSelector(selectAllStoreLocations);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const existingLocationName = locationList.length > 0 ? locationList[0].name : null;

  const phoneOptions = locationList.map((loc) => ({ label: loc.number, value: loc.number }));
  const emailOptions = locationList.map((loc) => ({ label: loc.email, value: loc.email }));
  const websiteOptions = locationList.map((loc) => ({ label: loc.website, value: loc.website }));
  const fetchFeedback = useFullFetchFeedback();

  const [formData, setFormData] = useState({
    type: '',
    name: existingLocationName || '',
    number: '',
    email: '',
    website: '',
    description: '',
    address: '',
    storePhoto: null
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setPreviewUrl(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const createLocation = async () => {
    const submissionData = {
      ...formData,
      storePhoto: selectedImage,
    };
    setStep(8); // Update to Processing step

    await fetchFeedback({
      endpoint: 'merchant/locations/',
      data: submissionData,
      requestType: "POST",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
        dispatch(addStoreLocation(responseData));
        setStep(9); // Update to Success step
      
      },
      onError: (error) => {
        console.error("Error submitting location:", error);
      },
    });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <BusinessTypeStep
            formData={formData} 
            setFormData={setFormData}
            businessTypes={businessTypes}
          />
        );
      case 2:
        return (
          <BasicBusinessInfoStep
            formData={formData}
            setFormData={setFormData}
            existingLocationName={existingLocationName}
            emailOptions={emailOptions}
          />
        );
      case 3:
        return (
          <ContactDetailsStep
            formData={formData}
            setFormData={setFormData}
            phoneOptions={phoneOptions}
            websiteOptions={websiteOptions}
          />
        );
      case 4:
        return (
          <LocationDetailsStep
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <BusinessDescriptionStep
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 6:
        return (
          <BusinessPhotoStep
            previewUrl={previewUrl}
            setSelectedImage={setSelectedImage}
            setPreviewUrl={setPreviewUrl}
            handleImageChange={handleImageChange}
            existingLocationName={existingLocationName}
          />
        );
      case 7:
        return (
          <ReviewStep
            formData={formData}
          />
        );
      case 8:
        return <ProcessingStep />;
      case 9:
        return <SuccessStep />;
      default:
        return null;
    }
  };

  const canProgress = () => {
    switch (step) {
      case 1:
        return !!formData.type;
      case 2:
        return !!formData.name && !!formData.email;
      case 3:
        return !!formData.number && !!formData.website;
      case 4:
        console.error("Address:", formData.address);
        return !!formData.address;
      case 5:
        return !!formData.description;
      case 6:
        return !!selectedImage || !!existingLocationName;
      case 7:
        return true;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (step === 7) {
      createLocation();
    } else {
      setStep(step + 1);
    }
  };

  const getStepTitle = () => {
    switch (step) {
      case 1: return 'Select Business Type';
      case 2: return 'Basic Information';
      case 3: return 'Contact Details';
      case 4: return 'Location Details';
      case 5: return 'Business Description';
      case 6: return 'Upload Photo';
      case 7: return 'Review Details';
      case 8: return 'Creating Location';
      case 9: return 'Success';
      default: return '';
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-semibold text-gray-900">{getStepTitle()}</h1>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="w-full h-1 bg-gray-100 rounded-full mb-6">
          <div
            className="h-1 bg-[#0D4029] rounded-full transition-all"
            style={{ width: `${(step / 9) * 100}%` }}
          />
        </div>

        <div className="flex-1 overflow-y-auto">
          {renderStep()}
        </div>

        {step < 8 && (
          <div className="mt-8 flex items-center justify-between border-t border-gray-100 pt-4">
            {step > 1 ? (
              <button
                onClick={() => setStep(step - 1)}
                className="text-gray-600 hover:text-gray-900 transition-colors"
                disabled={isLoading}
              >
                Back
              </button>
            ) : (
              <div />
            )}
            <button
              onClick={handleNext}
              disabled={!canProgress() || isLoading}
              className={`px-6 py-2 rounded-lg transition-colors flex items-center space-x-2 ${
                canProgress() && !isLoading
                  ? 'bg-[#0D4029] text-white hover:bg-[#0D4029]/90'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
            >
              <span>{step === 7 ? 'Create location' : 'Continue'}</span>
              {isLoading && <Loader className="w-4 h-4 animate-spin" />}
            </button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateLocationModal;