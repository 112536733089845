// components/cashback/CashbackPlans.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { Card, Input } from '../ui';
export const CashbackPlans = ({ step, regularRate, currentStep, handleSelectCashback, cashback, customValues, setCustomValues, error }) => {
    const calculateGoldPrice = (percentage) => {
        if (!regularRate) return 0;
        return Number((regularRate + regularRate * percentage).toFixed(2));
      };
    
      const plans = {
        regular: [
          { title: "Essential", price: 2.5, description: "Perfect for starting businesses" },
          { title: "Growth", price: 5.0, description: "Most popular among merchants" },
          { title: "Scale", price: 7.5, description: "For high-volume businesses" },
          { title: "Custom", price: null, description: "Tailor to your needs" }
        ],
        gold: [
          { 
            title: "Gold Basic", 
            price: calculateGoldPrice(0.35), 
            description: "35% more than regular rate" 
          },
          { 
            title: "Gold Plus", 
            price: calculateGoldPrice(0.50), 
            description: "50% more than regular rate" 
          },
          { 
            title: "Gold Elite", 
            price: calculateGoldPrice(0.75), 
            description: "75% more than regular rate" 
          },
          { title: "Custom Gold", price: null, description: "Set your premium rate" }
        ]
      };
  
    const handleClick = (plan) => {
        const isCustomPlan = plan.title.includes("Custom");
        
        if (isCustomPlan) {
          const input = document.querySelector('input[type="number"]');
          if (input) input.focus();
        }
        
        handleSelectCashback(
          currentStep === 0 ? "regular" : "gold",
          isCustomPlan ? customValues[currentStep === 0 ? "regular" : "gold"] || 0 : plan.price
        );
      };
    
      const isSelected = (plan) => {
        const currentValue = currentStep === 0 ? cashback.regular : cashback.gold;
        const customValue = currentStep === 0 ? customValues.regular : customValues.gold;
        
        if (plan.title.includes("Custom")) {
          return currentValue === customValue && customValue !== null;
        }
        return currentValue === plan.price;
      };
    
      return (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8"
        >
          {(currentStep === 0 ? plans.regular : plans.gold).map((plan, index) => {
            const isCustomPlan = plan.title.includes("Custom");
            return (
              <Card
                key={index}
                selected={isSelected(plan)}
                onClick={() => handleClick(plan)}
              >
                <div className="flex flex-col items-center space-y-4">
                  <h3 className="text-lg font-semibold text-gray-800">{plan.title}</h3>
                  {isCustomPlan ? (
                    <Input
                      value={currentStep === 0 ? customValues.regular || "" : customValues.gold || ""}
                      onChange={(e) => {
                        const value = e.target.value === "" ? "" : Number(e.target.value);
                        setCustomValues(prev => ({
                          ...prev,
                          [currentStep === 0 ? "regular" : "gold"]: value
                        }));
                        handleSelectCashback(
                          currentStep === 0 ? "regular" : "gold",
                          value
                        );
                      }}
                      error={error}
                    />
                  ) : (
                    <div className="w-20 h-20 rounded-full bg-emerald-800/10 flex items-center justify-center
                               transform transition-all duration-300 hover:scale-110">
                      <span className="text-emerald-800 text-2xl font-bold">{plan.price}%</span>
                    </div>
                  )}
                  <p className="text-sm text-gray-500 text-center">{plan.description}</p>
                </div>
              </Card>
            );
          })}
        </motion.div>
      );
    };