import React, { useState } from 'react';
import { X, AlertCircle, Eye, EyeOff, Lock, CheckCircle, Loader2 } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { updateRegisterData } from 'features/register/registerSlice';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';

// Keyframes for animations
const successKeyframes = `
  @keyframes ping {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(2); opacity: 0; }
  }
  @keyframes scale {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes slideUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

// Add styles to document head
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.textContent = successKeyframes;
  document.head.appendChild(style);
}

const UpdateCredentialsModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        await fetchFeedback({
          endpoint: 'merchant/register-account/',
          data: { 
            username: formData.username, 
            password: formData.password 
          },
          requestType: "POST",
          onSuccess: (responseData) => {
            dispatch(updateRegisterData(responseData));
            setIsLoading(false);
            setIsSuccess(true);
            setTimeout(() => {
              setIsClosing(true);
              setTimeout(handleClose, 300);
            }, 2000);
          },
        });
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.username.trim()) {
      newErrors.username = "Please enter a username";
    }
    if (!formData.password) {
      newErrors.password = "Please enter a password";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleClose = () => {
    setFormData({
      username: "",
      password: "",
      confirmPassword: ""
    });
    setErrors({});
    setIsSuccess(false);
    setIsLoading(false);
    setIsClosing(false);
    onClose();
  };

  const InputField = React.useCallback(({ label, id, type = "text", error, showPasswordToggle = false, ...props }) => (
    <div>
      <label htmlFor={id} className="mb-1.5 block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative">
        <input
          type={type}
          id={id}
          className={`w-full rounded-lg border ${
            error ? 'border-red-300 bg-red-50' : 'border-gray-300'
          } px-4 py-2.5 text-gray-900 transition-colors duration-200 ${
            showPasswordToggle ? 'pr-10' : ''
          } focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20 focus:outline-none`}
          {...props}
        />
        {showPasswordToggle && (
          <button
            type="button"
            onClick={() => props.name === 'password' ? setShowPassword(!showPassword) : setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
          >
            {(props.name === 'password' ? showPassword : showConfirmPassword) ? 
              <EyeOff className="h-4 w-4" /> : 
              <Eye className="h-4 w-4" />
            }
          </button>
        )}
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  ), [showPassword, showConfirmPassword]);

  const SuccessState = () => (
    <div className="flex flex-col items-center justify-center py-6">
      <div className="relative mb-4">
        <div className="absolute inset-0 animate-[ping_1s_ease-out_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/10" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.75s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/20" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.5s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/30" />
        </div>
        <div className="relative flex h-16 w-16 items-center justify-center rounded-full bg-green-100 animate-[scale_0.3s_ease-out_0.2s_both]">
          <CheckCircle className="h-10 w-10 text-green-600 animate-[scale_0.2s_ease-out_0.4s_both]" />
        </div>
      </div>
      <div className="space-y-1 text-center animate-[fadeIn_0.5s_ease-out_0.6s_both]">
        <h3 className="text-lg font-semibold text-gray-900">
          Credentials Updated Successfully
        </h3>
        <p className="text-sm text-gray-500">
          Your POS system credentials have been updated
        </p>
      </div>
    </div>
  );

  const LoadingState = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
      <div className="flex flex-col items-center space-y-4">
        <div className="relative">
          <div className="h-12 w-12 rounded-full border-4 border-[#0D4029]/20 opacity-75 animate-ping" />
          <Loader2 className="absolute inset-0 h-12 w-12 text-[#0D4029] animate-spin" />
        </div>
        <p className="text-sm font-medium text-gray-600">Updating credentials...</p>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/30 transition-opacity" onClick={handleClose} />
      
      <div className={`relative w-full max-w-md bg-white shadow-xl rounded-lg transform transition-all duration-300 ${
        isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
      }`}>
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">Update POS Credentials</h2>
          <button
            onClick={handleClose}
            className="rounded-full p-1 text-gray-500 hover:bg-gray-100"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="px-6 py-4 relative">
          {isSuccess ? (
            <SuccessState />
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="rounded-lg bg-amber-50 p-4">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 flex-shrink-0 text-amber-400" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-amber-800">Important Notice</h3>
                    <div className="mt-2 text-sm text-amber-700">
                      <ul className="list-disc space-y-1 pl-5">
                        <li>A new refund PIN will be generated automatically</li>
                        <li>All employees will receive the updated credentials</li>
                        <li>Previous credentials will be invalidated immediately</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <InputField
                  label="New Username"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={errors.username}
                />

                <InputField
                  label="New Password"
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password}
                  showPasswordToggle={true}
                />

                <InputField
                  label="Confirm Password"
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={errors.confirmPassword}
                  showPasswordToggle={true}
                />
              </div>
            </form>
          )}

          {isLoading && <LoadingState />}
        </div>

        {!isSuccess && (
          <div className="border-t border-gray-200 bg-white px-6 py-4 rounded-b-lg">
            <div className="flex space-x-3">
              <button
                onClick={handleClose}
                className="flex-1 rounded-lg border border-gray-300 px-4 py-3 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className="flex-1 rounded-lg bg-[#0D4029] px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-[#0D4029]/90 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2 disabled:opacity-50"
              >
                <span className="flex items-center justify-center">
                  <Lock className="mr-2 h-4 w-4" />
                  Update Credentials
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateCredentialsModal;