import React from 'react';
import { X, DollarSign, CalendarDays, Settings, Crown, ChevronRight, Clock } from 'lucide-react';

const LearningModal = ({ isOpen, onClose, activeTab }) => {
  const [page, setPage] = React.useState(1);

  const getCurrentPeriod = () => {
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    
    const periods = {
      0: { current: `Jan 1 - Apr 30, ${year}`, next: `May 1 - Aug 31, ${year}` },
      1: { current: `Jan 1 - Apr 30, ${year}`, next: `May 1 - Aug 31, ${year}` },
      2: { current: `Jan 1 - Apr 30, ${year}`, next: `May 1 - Aug 31, ${year}` },
      3: { current: `Jan 1 - Apr 30, ${year}`, next: `May 1 - Aug 31, ${year}` },
      4: { current: `May 1 - Aug 31, ${year}`, next: `Sep 1 - Dec 31, ${year}` },
      5: { current: `May 1 - Aug 31, ${year}`, next: `Sep 1 - Dec 31, ${year}` },
      6: { current: `May 1 - Aug 31, ${year}`, next: `Sep 1 - Dec 31, ${year}` },
      7: { current: `May 1 - Aug 31, ${year}`, next: `Sep 1 - Dec 31, ${year}` },
      8: { current: `Sep 1 - Dec 31, ${year}`, next: `Jan 1 - Apr 30, ${year + 1}` },
      9: { current: `Sep 1 - Dec 31, ${year}`, next: `Jan 1 - Apr 30, ${year + 1}` },
      10: { current: `Sep 1 - Dec 31, ${year}`, next: `Jan 1 - Apr 30, ${year + 1}` },
      11: { current: `Sep 1 - Dec 31, ${year}`, next: `Jan 1 - Apr 30, ${year + 1}` }
    };
    return periods[month];
  };

  const period = getCurrentPeriod();
  
  if (!isOpen) return null;

  const modalContent = {
    current: {
      page1: {
        title: "Current Active Period",
        subtitle: period.current,
        sections: [
          {
            icon: DollarSign,
            title: "Active Rewards",
            description: "These rates are live now. All purchases made during this period earn at these rates."
          },
          {
            icon: Clock,
            title: "4-Month Period",
            description: "Each period runs for 4 months. Your progress and benefits are tracked within this timeframe."
          }
        ]
      },
      page2: {
        title: "Key Details",
        subtitle: `Current Period: ${period.current}`,
        features: [
          {
            title: "Gold Status Progress",
            description: "Shows what current Gold members spent to achieve their status in the previous period"
          },
          {
            title: "Reward Crediting",
            description: "Get cashback right after each purchase at the current period rates"
          }
        ]
      }
    },
    next: {
      page1: {
        title: "Next Period Preview",
        subtitle: period.next,
        sections: [
          {
            icon: CalendarDays,
            title: "Future Rates",
            description: "These rates activate on day one of your next 4-month period. Customers already working toward this period's Gold status."
          },
          {
            icon: Crown,
            title: "New Gold Rules",
            description: "The spending threshold you need to reach in the current period to have Gold status in the next period."
          }
        ]
      },
      page2: {
        title: "Important Notes",
        subtitle: `Next Period: ${period.next}`,
        features: [
          {
            title: "Status Transition",
            description: "Gold status from current period carries into the first month of next period"
          },
          {
            title: "Rate Changes",
            description: "Any rate changes take effect on the first day of the new period"
          }
        ]
      }
    },
    pending: {
      page1: {
        title: "Pending Updates",
        subtitle: "Future Period Changes",
        sections: [
          {
            icon: Settings,
            title: "Draft Changes",
            description: "These rates are not confirmed for any specific period. They won't affect customers' current progress toward Gold."
          },
          {
            icon: Clock,
            title: "Future Planning",
            description: "Use this to plan future rate changes before setting them for a specific period."
          }
        ]
      },
      page2: {
        title: "Using Pending Updates",
        subtitle: "Planning Future Changes",
        features: [
          {
            title: "No Customer Impact",
            description: "Pending changes don't affect current or next period rewards"
          },
          {
            title: "Planning Tool",
            description: "Test different rates before scheduling them for a future period"
          }
        ]
      }
    }
  };
  const content = modalContent[activeTab];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/20 backdrop-blur-sm"
         onClick={e => e.target === e.currentTarget && onClose()}>
      <div className="bg-white rounded-xl w-full max-w-2xl overflow-hidden 
                    shadow-2xl shadow-[#0D4029]/10 animate-in fade-in duration-300">
        <div className="h-1 bg-gradient-to-r from-[#0D4029]/0 via-[#0D4029]/20 to-[#0D4029]/0" />
        
        <div className="flex items-center justify-between p-4">
          <div className="flex gap-3">
            <button
              onClick={() => setPage(1)}
              className={`w-2 h-2 rounded-full transition-all duration-300 transform
                       ${page === 1 ? 'bg-[#0D4029] scale-150' : 'bg-[#0D4029]/20 hover:bg-[#0D4029]/30'}`}
            />
            <button
              onClick={() => setPage(2)}
              className={`w-2 h-2 rounded-full transition-all duration-300 transform
                       ${page === 2 ? 'bg-[#0D4029] scale-150' : 'bg-[#0D4029]/20 hover:bg-[#0D4029]/30'}`}
            />
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-[#0D4029]/5 rounded-lg transition-all duration-300 hover:rotate-90"
          >
            <X className="w-5 h-5 text-[#0D4029]" />
          </button>
        </div>

        <div className="px-6 pb-6">
          {page === 1 ? (
            <div className="space-y-6">
              <div className="space-y-2">
                <h2 className="text-2xl font-semibold text-[#0D4029]">{content.page1.title}</h2>
                <p className="text-sm font-medium text-[#0D4029]/70 bg-[#0D4029]/5 px-3 py-1.5 rounded-lg inline-block">
                  {content.page1.subtitle}
                </p>
              </div>
              <div className="grid gap-4">
                {content.page1.sections.map((section, index) => (
                  <div key={index} 
                       className="group relative bg-white rounded-xl p-6 
                                border border-[#0D4029]/10 hover:border-[#0D4029]/20 
                                transition-all duration-300 hover:shadow-lg hover:shadow-[#0D4029]/5">
                    <div className="flex items-start gap-4">
                      <div className="p-3 rounded-xl bg-gradient-to-br from-[#0D4029]/[0.03] to-[#0D4029]/[0.07]
                                  transition-all duration-300 group-hover:scale-110 group-hover:rotate-3">
                        <section.icon className="w-6 h-6 text-[#0D4029]" />
                      </div>
                      <div className="space-y-2 flex-1">
                        <h3 className="text-lg font-medium text-[#0D4029] group-hover:translate-x-1 transition-transform duration-300">
                          {section.title}
                        </h3>
                        <p className="text-[#0D4029]/70 text-sm leading-relaxed">{section.description}</p>
                      </div>
                    </div>
                    <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-[#0D4029]/0 via-[#0D4029]/30 to-[#0D4029]/0 
                                transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div>
                <h2 className="text-2xl font-semibold text-[#0D4029] mb-1">{content.page2.title}</h2>
                <p className="text-[#0D4029]/70 font-medium">{content.page2.subtitle}</p>
              </div>
              <div className="bg-gradient-to-br from-[#0D4029]/[0.02] to-[#0D4029]/[0.05] rounded-xl p-6">
                <div className="grid gap-6">
                  {content.page2.features.map((feature, index) => (
                    <div key={index} className="group space-y-2 hover:translate-x-1 transition-transform duration-300">
                      <h3 className="font-medium text-[#0D4029]">{feature.title}</h3>
                      <p className="text-sm text-[#0D4029]/70 leading-relaxed">{feature.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="px-6 py-4 bg-gradient-to-t from-white via-white/90 to-transparent">
          <button
            onClick={() => setPage(page === 1 ? 2 : 1)}
            className="flex items-center gap-2 text-sm font-medium text-[#0D4029] group"
          >
            {page === 1 ? 'View details' : 'Back to overview'}
            <ChevronRight className={`w-4 h-4 transition-transform duration-300
                                   ${page === 2 ? 'rotate-180' : ''} 
                                   group-hover:translate-x-1`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LearningModal;