import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import BankPage from "./pages/bank/combined/page/bankPage.js"
import ProfilePage from './pages/profile/combined/page/profilePage.js'
import RegisterPage from "./pages/register/combined/page/registerPage.js"
import EmployeeManagementPage from "./pages/employeeManagement/combined/page/employeeManagementPage.js";
import SettingsPage from "./pages/settings/combined/page/settingPage.js";
import { Provider } from "react-redux";
import store from "./app/store";
import ReduxComponents from "./reduxComponents/main";
import DashboardPage from "./pages/dashboard/combined/page/dashboardPage.js";
import EmailPage from './pages/signUp/email/combined/page/emailPage.js';
import '@stripe/stripe-js';
import MainRouteGuard from "./functions/auth/routeGuards/mainRouteGuard.js";

import TransactionsPage from "./pages/transactions/combined/page/main.js";
import SignInPage from "./pages/signInUser/combined/page/AccountInfoPage.js";
import SignInPag2 from "./pages/signInTwo/combined/page/SignInPageTwo.js";
import SignUpPage2 from "./pages/signUpTwo/combined/page/SignUpPageTwo.js";
import SignUpPageTwoStripe from "./pages/signUpTwoStripe/combined/page/SignUpPageTwo.js";
import LocationPage from "./pages/locationNew/main.js";
import CashbackPage from "./pages/cashback/combined/page/cashbackPage.js";
import CashbackPageTwo from "./pages/cashbackTwo/main.js";

import SetPageTitle from "./pageTitle.js";
import NotFoundCombined from "./pages/notFound/combined/page/main.js";
import './firebaseConfig';
import DevelopmentPageUI from "./development/page/main.js";
import DevelopmentComponentUI from "./development/component/main.js";
import ThemeCustomization from './themes/index.js';

import { JWTProvider } from "./contexts/JWTContext.js";
import { IntlProvider } from "react-intl";

// Import your translation files
import messages_en from './locales/en.json';
import messages_es from './locales/es.json'; // Example if you support Spanish
import KycPage from "./pages/kyc/ui/main.js";
import { useStripe } from "./config";

import KycPageStripe from "./pages/kyc_stripe/main.js";
import AccountStatusCheck from "./pages/status_checking_stripe/main.js";
import StripeLinkRedirect from "./pages/stripe_redirect/main.js";
import BankPageStripe from "./pages/bank_stripe/combined/page/bankPage.js";
import LandingPage from "pages/landing/main.js";
import HelpCenterPage from "pages/help_center/main.js";
import MainPageHelpCenter from "pages/help_center/navigate.js";
import DashboardLayout from "sideBar/main.js";
import RegisterPageNew from "pages/registerNew.js/main.js";
import MainCashback from "pages/cashbackNew/main.js";
import OrderMaterialPage from "pages/orderMaterial/main.js";
import SignupPage from "pages/signUpNew/main.js";
import SignInPageNew from "pages/signInNew/main.js";

const messages = {
  en: messages_en,
  es: messages_es,
};

// Get the user's preferred locale or use 'en' as default
const locale = navigator.language.split('-')[0] || 'en';

const App = () => {
  return (
    <IntlProvider locale={locale} messages={messages[locale] || messages["en"]}>
      <JWTProvider>
        <Provider store={store}>
          <ThemeCustomization>
            <Router>
              <SetPageTitle />
              <ReduxComponents />
              <Routes>

{useStripe && (  

   <Route path="/bank" element={
       <MainRouteGuard>
    < BankPageStripe />
    </MainRouteGuard>
    } />
  

                )}
                {!useStripe && ( 
                   <MainRouteGuard>
                  <Route path="/bank" element={< BankPage/>} />
                  </MainRouteGuard>

                )}
                <Route
                  path="/develpment/component"
                  element={<DevelopmentComponentUI />}
                />
                <Route
                  path="/development/page"
                  element={<DevelopmentPageUI />}
                />
                 <Route
                  path="/"
                  element={<LandingPage />}
                />
                 <Route
                  path="/help/center/:articleId"
                  element={<MainPageHelpCenter />}
                />
                     <Route
                  path="/help/center"
                  element={<MainPageHelpCenter />}
                />
                <Route
                  path="/cashback"
                  element={
                    <MainRouteGuard>
                      <MainCashback />
                    </MainRouteGuard>
                  }
                />
                 <Route
                  path="/dashboard/material/order"
                  element={
                    <MainRouteGuard>
                      <OrderMaterialPage />
                    </MainRouteGuard>
                  }
                />
 
                <Route
                  path="/dashboard"
                  element={
                    <MainRouteGuard>
                      <DashboardPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <MainRouteGuard>
                      <LocationPage />
                    </MainRouteGuard>
                  }
                />
                {useStripe && ( 
                  <>
                 <Route
                 path="/dashboard/kyc"
                 element={
                   <MainRouteGuard>
                     <KycPageStripe />
                   </MainRouteGuard>


                 }
               />

              <Route
                 path="/dashboard/kyc/account_status"
                 element={
                   <MainRouteGuard>
                     <AccountStatusCheck />
                   </MainRouteGuard>


                 }
               />
                <Route
                 path="/dashboard/kyc/reauth"
                 element={
                   <MainRouteGuard>
                     <StripeLinkRedirect />
                   </MainRouteGuard>


                 }
               />
                 <Route
                 path="/dashboard/kyc/reauth/update"
                 element={
                   <MainRouteGuard>
                     <KycPage />
                   </MainRouteGuard>


                 }
               />

               </>)
               }
              { !useStripe && <Route
                  path="/dashboard/kyc"
                  element={
                    <MainRouteGuard>
                      <KycPage />
                    </MainRouteGuard>
                  }
                />}
                <Route
                  path="/pos/register"
                  element={
                    <MainRouteGuard>
                      <RegisterPageNew />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/employee-management"
                  element={
                    <MainRouteGuard>
                      <EmployeeManagementPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <MainRouteGuard>
                      <SettingsPage />
                    </MainRouteGuard>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <MainRouteGuard>
                      <TransactionsPage />
                    </MainRouteGuard>
                  }
                />

                <Route path="/sign-in" element={<SignInPageNew />} />
                {/* <Route path="/sign-in-2" element={<SignInPag2 />} />
                <Route path="/sign-up-2" element={<SignUpPag2 />} /> */}

                {useStripe && (   <Route path="/email" element={< SignUpPageTwoStripe />} />
                )}
                {!useStripe && ( <Route path="/email" element={< SignUpPage2/>} />
                )}
                <Route path="/sign-up" element={< SignupPage />} />
                <Route path="/sign-in2" element={< SignInPageNew />} />
            
                <Route path="*" element={<NotFoundCombined />} />
              </Routes>
            </Router>
          </ThemeCustomization>
        </Provider>
      </JWTProvider>
    </IntlProvider>
  );
};
export default App;
