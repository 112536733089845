import React, { useState } from 'react';

const TableCell = ({ children, header, onClick }) => (
  <div 
    onClick={onClick}
    className={`px-4 py-2.5 ${header ? 'bg-white border-b border-gray-200 font-medium text-xs uppercase tracking-wide text-gray-600' : 'text-gray-800'}`}>
    {children}
  </div>
);

const StatusBadge = ({ status }) => (
  <span className="px-2 py-0.5 bg-[#0D4029]/5 text-[#0D4029] text-xs font-medium">
    {status}
  </span>
);

const Amount = ({ value }) => (
  <span className="font-medium">{value}</span>
);

const TableRow = ({ data, columns, onClick }) => (
  <div 
    onClick={() => onClick(data)}
    className="grid grid-cols-8 border-b border-gray-100 hover:bg-gray-50/50 transition-colors cursor-pointer">
    {columns.map((col, i) => (
      <TableCell key={i}>
        {col.key === 'processingStatus' ? (
          <StatusBadge status={data[col.key]} />
        ) : col.key === 'transaction_amount' ? (
          <Amount value={data[col.key]} />
        ) : (
          <span className="text-sm">{data[col.key]}</span>
        )}
      </TableCell>
    ))}
  </div>
);

const CustomTable = ({
  data,
  loading,
  rowsPerPage = 10,
  totalRows = 0,
  page = 0,
  handlePageChange,
  showPagination = false,
  onRowClick,
}) => {
  const columns = [
    { key: 'date', title: 'Date' },
    { key: 'processingStatus', title: 'Status' },
    { key: 'name', title: 'Customer' },
    { key: 'register', title: 'Register' },
    { key: 'id', title: 'Invoice ID' },
    { key: 'cashbackEarned', title: 'Cashback Earned' },
    { key: 'cashbackRedeemed', title: 'Cashback Redeemed' },
    { key: 'transaction_amount', title: 'Amount' },
  ];

  return (
    <div className="w-full">
      <div className="border border-gray-200">
        <div className="grid grid-cols-8">
          {columns.map((col, i) => (
            <TableCell key={i} header>{col.title}</TableCell>
          ))}
        </div>

        {loading ? (
          Array.from({ length: rowsPerPage }).map((_, i) => (
            <div key={i} className="h-10 bg-gray-50 animate-pulse" />
          ))
        ) : data.length > 0 ? (
          data.map((row, i) => (
            <TableRow 
              key={i}
              data={row}
              columns={columns}
              onClick={onRowClick}
            />
          ))
        ) : (
          <div className="py-16 text-center">
            <h3 className="text-base font-medium text-gray-700">No transactions yet</h3>
            <p className="mt-1 text-xs text-gray-500">
              From here, you'll be able to manage, filter, and refund transactions.
            </p>
          </div>
        )}
      </div>

      {showPagination && (
        <div className="flex justify-between items-center px-4 py-2 mt-3 border border-gray-200 bg-white">
          <span className="text-xs text-gray-600">
            {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, totalRows)} of {totalRows}
          </span>
          <div className="space-x-1">
            <button
              disabled={page === 0}
              onClick={() => handlePageChange(page - 1)}
              className="px-3 py-1 text-xs font-medium border border-gray-200 hover:border-[#0D4029] hover:text-[#0D4029] disabled:opacity-50 disabled:hover:border-gray-200 disabled:hover:text-gray-800 transition-colors"
            >
              Previous
            </button>
            <button
              disabled={page >= Math.ceil(totalRows / rowsPerPage) - 1}
              onClick={() => handlePageChange(page + 1)}
              className="px-3 py-1 text-xs font-medium border border-gray-200 hover:border-[#0D4029] hover:text-[#0D4029] disabled:opacity-50 disabled:hover:border-gray-200 disabled:hover:text-gray-800 transition-colors"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;