import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DocumentsStripe from './stripe_account_comonenet';

const DocumentsModal = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="documents-modal-title"
            aria-describedby="documents-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                    overflow: 'hidden',
                }}
            >
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 3,
                        py: 2,
                        bgcolor: '#f5f5f5',
                        borderBottom: '1px solid #e0e0e0',
                    }}
                >
                    <Box>
                        <Typography
                            id="documents-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{ fontWeight: 'bold', color: '#333' }}
                        >
                            Documents
                        </Typography>
                        <Typography
                            id="documents-modal-subtitle"
                            variant="body2"
                            sx={{ color: '#666', mt: 0.5 }}
                        >
                            Manage and upload your important documents here.
                        </Typography>
                    </Box>
                    <IconButton onClick={onClose} sx={{ color: '#666' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Main Content */}
                <Box sx={{ px: 3, py: 2 }}>
                    <DocumentsStripe />
                </Box>
            </Box>
        </Modal>
    );
};

export default DocumentsModal;