import React, { useState } from 'react';
import {  SearchX } from 'lucide-react';

const NoSearchResultsState = ({setSearchTerm, setFilters, addBoostClick}) => (
    <div className="text-center py-16 border border-gray-200 rounded-lg">
      <div className="mx-auto w-24 h-24 border-2 border-gray-100 rounded-full flex items-center justify-center mb-4">
        <SearchX className="w-8 h-8 text-gray-400" />
      </div>
      <h3 className="text-xl font-semibold text-gray-900">No matches found</h3>
      <p className="mt-2 text-gray-600 max-w-md mx-auto">
        No boosts match your current search and filters. Try adjusting your criteria or create a new boost.
      </p>
      <div className="mt-6 space-x-4">
        <button 
          onClick={() => {
            setSearchTerm('');
            setFilters({ status: 'all', customerType: 'all' });
          }}
          className="px-5 py-2.5 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
        >
          Clear filters
        </button>
        <button className="px-5 py-2.5 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
            onClick={addBoostClick}
    
        >
          Create Boost
        </button>
      </div>
    </div>
  );

export default NoSearchResultsState;