// Base selector
const selectItemsState = (state) => state.items.items;

export const selectAllItems = (state) => 
  Object.values(selectItemsState(state));

export const selectItemById = (id) => (state) => 
  selectItemsState(state)[id];

export const selectItemsByIds = (ids) => (state) => 
  ids.map(id => selectItemsState(state)[id]).filter(Boolean);

export const selectFeaturedItems = (state) => 
  Object.values(selectItemsState(state)).filter(item => item.highlights);
