import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import DashboardLayout from 'sideBar/main';

const InitialLoadingPage = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => clearInterval(timer);
  }, []);

  return (
    <DashboardLayout>
      <div className="p-8">
        {/* Loading Progress Indicator */}
        <div className="mb-8 flex items-center space-x-4">
          <Loader2 className="w-5 h-5 text-[#0D4029] animate-spin" />
          <div className="text-sm text-gray-600">Setting up your dashboard ({Math.round(progress)}%)</div>
        </div>

        {/* Header Section */}
        <div className="mb-8">
          <div className="w-48 h-8 bg-gray-100 rounded animate-pulse mb-2" />
          <div className="w-96 h-4 bg-gray-100 rounded animate-pulse" />
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="bg-gray-50 rounded-lg p-6 animate-pulse">
              <div className="w-20 h-4 bg-gray-100 rounded mb-2" />
              <div className="w-32 h-8 bg-gray-100 rounded" />
            </div>
          ))}
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          {[...Array(2)].map((_, i) => (
            <div key={i} className="bg-gray-50 rounded-lg p-6">
              <div className="w-32 h-4 bg-gray-100 rounded mb-4 animate-pulse" />
              <div className="w-full h-64 bg-gray-100 rounded animate-pulse" />
            </div>
          ))}
        </div>

        {/* Table Section */}
        <div className="bg-gray-50 rounded-lg p-6">
          <div className="w-48 h-4 bg-gray-100 rounded mb-4 animate-pulse" />
          <div className="space-y-4">
            {[...Array(5)].map((_, i) => (
              <div key={i} className="w-full h-12 bg-gray-100 rounded animate-pulse" />
            ))}
          </div>
        </div>

        {/* Progress Bar */}
        <div className="fixed bottom-0 left-0 w-full h-1 bg-gray-100">
          <div 
            className="h-full bg-[#0D4029] transition-all duration-300"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InitialLoadingPage;