import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';

import { setInitialDashboardData } from '../../../features/initialData/initialDataSlice';
import { selectHasFetched } from '../../../features/initialData/initialDataSelector';
import { selectUserStatus } from '../../../features/user/userSelectors';

import { masterRequest } from '../../apiStructures/masterRequest';
import InitialLoadingPage from '../../../pages/loading/initialDataLoader';
import useFullFetchFeedback from '../../apiFetchers/fullPageLoaders';
import { setStatus } from "../../../features/user/userSlice";
import { setHasFetched } from "../../../features/initialData/initialDataSlice";
import { useStripe } from "../../../config";
import { showAlert } from '../../../features/alerts/alertSlice';
const MainRouteGuard = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const userStatus = useSelector(selectUserStatus);
  const fetched = useSelector(selectHasFetched);
  const navigate = useNavigate();


  const fetchFeedback = useFullFetchFeedback();
  useEffect(() => {
    const initializeUser = async () => {
      if (!fetched) {
        setLoading(true);
        

        
       
          await fetchFeedback({
            endpoint: useStripe 
            ? 'merchant/stripe/dashboard/data/' // Add 'stripe' to the endpoint when useStripe is true
            : 'merchant/dashboard/data/',
            requestType: 'GET',
            onSuccess: (data) => {
        
              dispatch(setStatus(data.status));
              dispatch(setHasFetched(true));
              dispatch(setInitialDashboardData(data));
            },
            onError: (error) => {
              console.error('Error fetching dashboard data:', error);
              if (error.detail) {
                dispatch(
                  showAlert({
                    type: "warning",
                    message: "This account is already registered with a shopper",
                  })
                );
                navigate("/email");
              } else {
                dispatch(
                  showAlert({
                    type: "warning",
                    message: "An error occurred. Please try again.",
                  })
                );
                navigate("/email");
              }
            }
          });
 
  
        setLoading(false); // Ensure loading is set to false after completion
      }
    };
  
    initializeUser();
  }, [dispatch, fetched]);
  

  if (loading) {
    return <InitialLoadingPage/>; // Or a loading spinner
  }

  // Redirect based on user status




    if(fetched) {
      return <>{children}</>;
    }
  
 
};

export default MainRouteGuard;
