import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';

const TransactionModal = ({ onClose, transactionId, onManage, onRefund }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
      <div ref={modalRef} className="bg-white w-full max-w-lg shadow-xl">
        {/* Header with X in corner */}
        <div className="flex justify-between items-start px-6 pt-6 pb-4">
          <div className="flex-1 text-center">
            <h2 className="text-lg font-medium text-gray-900">
              Transaction Options
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Transaction #{transactionId}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 -mt-1 -mr-2 p-2"
            aria-label="Close"
          >
            <X size={18} />
          </button>
        </div>

        {/* Bottom section with shade */}
        <div className="bg-gray-50 px-6 pt-6 pb-6 border-t border-gray-100">
          <p className="text-sm text-gray-600 text-center mb-4">
            Choose your next action for this transaction
          </p>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={onManage}
              className="px-4 py-2.5 text-sm bg-[#0D4029] text-white font-medium hover:bg-[#0D4029]/90 transition-colors focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
            >
              View Details
            </button>

            <button
              onClick={onRefund}
              className="px-4 py-2.5 text-sm text-[#0D4029] bg-white font-medium border border-gray-300 hover:border-[#0D4029] hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
            >
              Issue Refund
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionModal;