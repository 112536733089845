import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase auth functions
import { setHasFetched } from "../../features/initialData/initialDataSlice";

import { useDispatch, useSelector } from 'react-redux';

export const useAuthHelpers = () => {
  const navigate = useNavigate();
  const auth = getAuth(); // Initialize Firebase auth
  const dispatch = useDispatch(); // Initialize Redux dispatch

  const handleSignOut = async () => {
    try {
      // Sign out from Firebase
      await signOut(auth);

      // Clear user-related and authentication data from localStorage
     

      // Dispatch actions to update the authentication state in Redux
      
      dispatch(setHasFetched(false));

      // Navigate the user to the sign-in or sign-up page
      navigate('/email'); // Adjust the route as per your routing setup
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return { handleSignOut };
};
