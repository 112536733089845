import React from 'react';
import { Gift, CreditCard, BarChart, ArrowRight } from 'lucide-react';
import { useNavigate } from "react-router-dom";
const LandingPage = () => {
    const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="container mx-auto px-4 py-4 flex justify-between items-center">
      <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#3EB489' }}>
  Clavaa
</div>
        <a href="https://clavaa.com" className="text-sm text-[#0D4029] hover:opacity-80">
          Go back to Clavaa.com
        </a>
      </header>

      {/* Hero Section */}
      <section className="relative bg-gradient-to-b from-[#3EB489]/5 to-white">
        <div className="container mx-auto px-4 pt-24 pb-20 md:pt-32 md:pb-28">
          <div className="max-w-4xl mx-auto">
            <div className="text-center space-y-4">
              <div className="inline-block bg-[#3EB489]/10 px-6 py-2.5 rounded-full">
                <span className="text-[#3EB489] font-semibold">Welcome to Clavaa Dashboard</span>
              </div>
              <div className="space-y-2 max-w-3xl mx-auto">
                <h1 className="text-5xl md:text-6xl lg:text-7xl font-extrabold text-gray-900 tracking-tight">
                  Transform Your Business
                </h1>
                <p className="text-4xl md:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-[#3EB489] to-[#0D4029] bg-clip-text text-transparent">
                  With Loyalty That Works
                </p>
              </div>
              <p className="text-xl md:text-2xl text-gray-600 max-w-2xl mx-auto font-medium leading-relaxed mt-4">
                We've taken the co-branded card programs of big enterprises and built them for small businesses like yours.
              </p>
              <div className="flex justify-center gap-4 pt-8 max-w-xl mx-auto">
                <button   onClick={() => navigate("/email")} className="w-64 bg-[#3EB489] text-white px-8 py-4 rounded-lg font-semibold hover:opacity-90 text-lg shadow-lg shadow-[#3EB489]/20 transition-all duration-200 hover:shadow-xl hover:shadow-[#3EB489]/30">
                  Get Started Today
                </button>
                <button   onClick={() => navigate("/sign-in")} className="w-64 border-2 border-[#3EB489] text-[#3EB489] px-8 py-4 rounded-lg font-semibold hover:bg-[#3EB489] hover:text-white text-lg transition-all duration-200">
                  Sign In
                </button>
              </div>
              <div className="pt-10 flex flex-col sm:flex-row justify-center items-center gap-8 text-gray-700 max-w-2xl mx-auto">
                <div className="flex items-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-[#3EB489] mr-2"></div>
                  <span className="text-base font-semibold">No Swipe Fees</span>
                </div>
                <div className="flex items-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-[#3EB489] mr-2"></div>
                  <span className="text-base font-semibold">No Monthly Fees</span>
                </div>
                <div className="flex items-center">
                  <div className="w-1.5 h-1.5 rounded-full bg-[#3EB489] mr-2"></div>
                  <span className="text-base font-semibold">No Hardware Fees</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 pointer-events-none overflow-hidden">
          <div className="absolute -top-1/2 left-0 w-64 h-64 bg-[#3EB489]/10 rounded-full blur-3xl"></div>
          <div className="absolute -top-1/2 right-0 w-64 h-64 bg-[#0D4029]/10 rounded-full blur-3xl"></div>
        </div>
      </section>

      {/* Metrics Section */}
      <section className="bg-gray-50 py-16 md:py-24">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Clavaa Dashboard
            </h2>
            <p className="text-xl text-gray-600 font-medium">
              See the impact our platform makes for businesses like yours
            </p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <div className="text-center max-w-3xl mx-auto">
              <h3 className="text-2xl font-bold mb-2">Dashboard Preview</h3>
              <p className="text-gray-600 mb-8">See how our platform helps you track and grow your business</p>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="bg-[#3EB489]/5 p-6 rounded-lg flex flex-col items-center justify-center text-center">
                <h4 className="text-4xl font-bold text-[#3EB489] mb-3">2,847</h4>
                <p className="text-sm text-gray-600">Customers actively earning rewards</p>
              </div>
              <div className="bg-[#3EB489]/5 p-6 rounded-lg flex flex-col items-center justify-center text-center">
                <h4 className="text-4xl font-bold text-[#3EB489] mb-3">$37,293</h4>
                <p className="text-sm text-gray-600">Total cashback rewarded</p>
              </div>
              <div className="bg-[#3EB489]/5 p-6 rounded-lg flex flex-col items-center justify-center text-center">
                <h4 className="text-4xl font-bold text-[#3EB489] mb-3">89%</h4>
                <p className="text-sm text-gray-600">Repeat customers who keep coming back</p>
              </div>
              <div className="bg-[#3EB489]/5 p-6 rounded-lg flex flex-col items-center justify-center text-center">
                <h4 className="text-4xl font-bold text-[#3EB489] mb-3">$42,349</h4>
                <p className="text-sm text-gray-600">Swipe fee Savings from using Clavaa</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-gradient-to-b from-white to-[#3EB489]/5 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Built for Small Businesses. Powered by Loyalty.
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <Gift className="w-12 h-12 text-[#3EB489] mb-4" />
              <h3 className="text-xl font-bold mb-2">Loyalty That Drives Growth</h3>
              <p className="text-gray-600">
                Set up your loyalty program in minutes. Reward customers automatically, track rewards, and build stronger relationships.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <CreditCard className="w-12 h-12 text-[#3EB489] mb-4" />
              <h3 className="text-xl font-bold mb-2">Integrated Payments</h3>
              <p className="text-gray-600">
                Process payments seamlessly while offering rewards—no extra tools or hidden fees.
              </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <BarChart className="w-12 h-12 text-[#3EB489] mb-4" />
              <h3 className="text-xl font-bold mb-2">Actionable Insights</h3>
              <p className="text-gray-600">
                Understand how loyalty impacts your business with real-time analytics and trends.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">Getting Started is Easy</h2>
              <p className="text-xl text-gray-600 font-medium">Set up your loyalty program in minutes, not months</p>
            </div>
            <div className="grid md:grid-cols-3 gap-12">
              {[
                {
                  title: 'Create Account',
                  description: 'Sign up in 2 minutes and connect your existing payment processor'
                },
                {
                  title: 'Customize Program',
                  description: 'Set your rewards, cashback rates, and automated customer messages'
                },
                {
                  title: 'Start Growing',
                  description: 'Watch your customers earn rewards and keep coming back'
                }
              ].map((step, index) => (
                <div key={step.title} className="relative text-center">
                  <div className="mb-6 flex justify-center">
                    <div className="relative">
                      <div className="w-16 h-16 rounded-full border-2 border-[#3EB489] flex items-center justify-center bg-white">
                        <span className="text-2xl font-bold text-[#3EB489]">{index + 1}</span>
                      </div>
                      {index < 2 && (
                        <div className="hidden md:block absolute top-8 left-16 w-[calc(200%-2rem)] h-0.5 bg-[#3EB489]/20"></div>
                      )}
                    </div>
                  </div>
                  <h3 className="text-xl font-bold mb-3">{step.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <div className="text-xl font-bold text-[#0D4029]">Clavaa</div>
              <p className="text-sm text-gray-600">Loyalty and Payments Simplified.</p>
            </div>
            <div className="space-y-2 md:space-y-0">
              <div className="space-x-4">
                <a href="#" className="text-sm text-gray-600 hover:text-[#3EB489]">Privacy Policy</a>
                <span className="text-gray-300">|</span>
                <a href="#" className="text-sm text-gray-600 hover:text-[#3EB489]">Terms of Service</a>
              </div>
              <p className="text-sm text-gray-500 text-center md:text-right">
                © 2024 Clavaa. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;

