import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { setStatus } from '../../../../../features/user/userSlice';
import useFullFetchFeedback from '../../../../../functions/apiFetchers/fullPageLoaders';
import { useDispatch } from 'react-redux';
import { setDocumentData, setRetryData } from '../../../../../features/verification/verificationInfoSlice';
import { showAlert } from '../../../../../features/alerts/alertSlice';

const VerificationInProcess = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const fetchVerificationStatus = async () => {
    await fetchFeedback({
        endpoint: 'merchant/verification-status/',
        requestType: 'GET',
      
        onSuccess: (responseData) => {
            // Handle response statuses directly with setRetry and setDocument
            switch (responseData.status) {
                case "documentPending":
                    dispatch(setStatus("verifyPending"));
                    break;
                case "certified":
                    dispatch(setStatus("complete"));
                    break;
                case "retry":
                    dispatch(setStatus("retry"));
                    dispatch(setRetryData({
                        retry_controller_data: responseData?.retry_controller_data,
                        retry_business_data: responseData?.retry_business_data,
                        owner_retry_data: responseData?.owner_retry_data,
                    }));
                    break;
                case "document":
                    dispatch(setStatus("document"));
                    dispatch(setDocumentData(responseData?.documents));
                    break;
                case "suspended":
                    dispatch(setStatus("verifySuspended"));
                    break;
                default:
                    console.warn("Unknown status:", responseData.status);
                    dispatch(showAlert({
                        type: "warning",
                        message: "Unrecognized verification status.",
                    }));
                    break;
            }
        },
        onError: (err) => {
            console.error("Error fetching verification status:", err);
            dispatch(
                showAlert({
                    type: "warning",
                    message:
                        "We are encountering an error. Try again later or contact customer service.",
                })
            );
        },
    });
};

useEffect(() => {
    
      fetchVerificationStatus();

  }, []);

  return (
    <Box
    sx={{
      border: `1px solid #E0E0E0`,
      borderRadius: '16px',
      padding: theme.spacing(4),
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-4px)',
      },
    }}
  >
    <Box textAlign="center">
      <CircularProgress size={50} sx={{ color: '#3EB489', marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Fetching Status
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        We’re currently verifying your information in the background. This will be updated shortly, please check back in a moment.
      </Typography>
    </Box>
    </Box>
  );
};

export default VerificationInProcess;
