import React, { useState } from "react";
import {
  ChevronRight,
  ThumbsUp,
  ThumbsDown,
  Copy,
  Clock,
  ArrowLeft,
  MessageCircle,
  AlertCircle,
  CheckCircle,
  Info,
} from "lucide-react";

// Section Renderer Component
const renderSection = (section) => {
  switch (section.type) {
    case "text":
      return (
        <section id={section.id} key={section.id} className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">{section.title}</h2>
          <p className="text-gray-700 text-lg leading-relaxed">{section.content}</p>
        </section>
      );
    case "list":
      return (
        <section id={section.id} key={section.id} className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">{section.title}</h2>
          <div className="bg-white border rounded-lg overflow-hidden">
            <div className="px-8 py-6 bg-gray-50 border-b">
              <h3 className="font-semibold text-gray-800">{section.subtitle}</h3>
            </div>
            <div className="p-8 space-y-6">
              {section.items.map((item, index) => (
                <div key={index} className="flex items-start pb-6 border-b last:border-b-0">
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg">{item.title}</h4>
                    <p className="text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      );
    case "checklist":
      return (
        <section id={section.id} key={section.id} className="mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">{section.title}</h2>
          <ul className="list-disc pl-8 space-y-4 text-lg">
            {section.items.map((item, index) => (
              <li key={index} className="text-gray-700">{item}</li>
            ))}
          </ul>
        </section>
      );
    case "tip":
      return (
        <section id={section.id} key={section.id} className="mb-12">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 rounded-lg p-6">
            <div className="flex gap-4">
              <AlertCircle className="w-6 h-6 text-yellow-600" />
              <div>
                <h4 className="font-semibold text-yellow-800 mb-2">{section.title}</h4>
                <p className="text-yellow-700">{section.content}</p>
              </div>
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
};

// Main Article Page Component
const ArticlePage = ({ article, onBack }) => {
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  if (!article) {
    return <div className="text-center py-16 text-gray-700">Article not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white border-b sticky top-0 z-10 shadow-sm">
        <div className="container mx-auto px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button onClick={onBack} className="text-gray-600 hover:text-[#3EB489] flex items-center gap-2">
                <ArrowLeft className="w-6 h-6" />
                Back to Help Center
              </button>
              <span className="text-gray-400">|</span>
              <div className="text-gray-600 text-lg">{article.breadcrumbs}</div>
            </div>
            <button className="flex items-center gap-3 bg-[#3EB489] text-white px-6 py-3 rounded-lg hover:opacity-90">
              <MessageCircle className="w-5 h-5" />
              Need Help?
            </button>
          </div>
        </div>
      </header>

      <div className="container mx-auto px-6 py-10 flex gap-12">
        {/* Sidebar - Table of Contents */}
        <aside className="w-80 hidden lg:block">
          <div className="sticky top-28 bg-white rounded-lg shadow-lg p-6">
            <h3 className="font-bold text-gray-900 text-xl mb-6">Quick Navigation</h3>
            <nav className="space-y-4">
              {article.sections.map((section) => (
                <a key={section.id} href={`#${section.id}`} className="block text-gray-700 hover:text-[#3EB489]">
                  {section.title}
                </a>
              ))}
            </nav>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1 max-w-5xl">
          <article className="bg-white rounded-lg shadow-lg p-10">
            {/* Article Header */}
            <header className="mb-12 pb-8 border-b">
              <h1 className="text-5xl font-bold text-gray-900 mb-6">{article.title}</h1>
              <div className="flex items-center gap-6 text-gray-500 text-lg">
                <Clock className="w-5 h-5" />
                {article.readTime}
              </div>
            </header>

            {/* Quick Overview */}
            {article.overview && (
              <div className="bg-[#3EB489]/10 rounded-lg p-8 mb-12">
                <div className="flex items-start gap-4 mb-6">
                  <Info className="w-6 h-6 text-[#3EB489]" />
                  <div>
                    <h2 className="font-bold text-gray-900 text-2xl">What You'll Learn</h2>
                    <p className="text-gray-700">{article.overview}</p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  {article.learningPoints.map((point, index) => (
                    <div key={index} className="flex items-start gap-3">
                      <CheckCircle className="w-5 h-5 text-[#3EB489]" />
                      <span className="text-lg text-gray-700">{point}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Content Sections */}
            {article.sections.map((section) => renderSection(section))}

            {/* Footer */}
            <footer className="border-t pt-8 mt-12">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => navigator.clipboard.writeText(window.location.href)}
                  className="text-gray-500 hover:text-[#3EB489] flex items-center gap-2"
                >
                  <Copy className="w-5 h-5" />
                  Copy Link
                </button>
                <div className="flex items-center gap-6">
                  <span className="text-lg text-gray-600">Was this helpful?</span>
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => setFeedbackGiven(true)}
                      className={`p-3 rounded-lg hover:bg-gray-100 ${
                        feedbackGiven ? "text-[#3EB489]" : "text-gray-400"
                      }`}
                    >
                      <ThumbsUp className="w-6 h-6" />
                    </button>
                    <button
                      onClick={() => setFeedbackGiven(true)}
                      className={`p-3 rounded-lg hover:bg-gray-100 ${
                        feedbackGiven ? "text-[#3EB489]" : "text-gray-400"
                      }`}
                    >
                      <ThumbsDown className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              </div>
            </footer>
          </article>
        </main>
      </div>
    </div>
  );
};

export default ArticlePage;