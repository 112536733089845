import React from 'react';
import MerchantCashbackDashboard from './mainInside';
import DashboardLayout from 'sideBar/main';
import { set } from 'lodash';
import { selectCashbackDetails } from 'features/cashback/cashbackSelectors';
import { useSelector } from 'react-redux';
import LearningModal from './learnMore';
import UpdateCashbackDailog from './UpdateCashbackDailog';
import { UpdateCashbackDialog } from './chnageModal/cashback';
import MerchantCashbackDashboardPreActivation from './mainInsidePreActivation';
import { selectActivationStatus } from 'features/user/userSelectors';

const MainCashback = () => {

  
    const [updateRatesDialogOpen, setUpdateRatesDialogOpen] = React.useState(false);
    const [learnMoreDialogOpen, setLearnMoreDialogOpen] = React.useState(false);
    const [activeTabLearnMore, setActiveTabLearnMore] = React.useState('current');
    const cashbackDetails = useSelector(selectCashbackDetails);
      const activationStatus = useSelector(selectActivationStatus);
      const isAccountActive = activationStatus === "activated";


  const handleUpdateRates = () => {
    setUpdateRatesDialogOpen(true);
  };

  const handleLearnMore = (activeTab) => {
    setActiveTabLearnMore(activeTab);
    setLearnMoreDialogOpen(true);
  };

  const periods = {
    current: {
      goldCashback: parseFloat(cashbackDetails.last_gold).toFixed(1),
      regularCashback: parseFloat(cashbackDetails.last_general_cashback_rate).toFixed(1),
      spendingThreshold: parseFloat(cashbackDetails.last_gold_required_spend).toFixed(2),
    },
    next: {
      goldCashback: parseFloat(cashbackDetails.current_gold).toFixed(1),
      regularCashback: parseFloat(cashbackDetails.current_cashback).toFixed(1),
      spendingThreshold: parseFloat(cashbackDetails.current_gold_required_spend).toFixed(2),
    },
    pending: {
      goldCashback: parseFloat(cashbackDetails.next_gold).toFixed(1),
      regularCashback: parseFloat(cashbackDetails.next_general_cashback_rate).toFixed(1),
      spendingThreshold: parseFloat(cashbackDetails.next_gold_required_spend).toFixed(2),
    },
  };

  return (
    <>
    <DashboardLayout>
{isAccountActive && (
    <MerchantCashbackDashboard
      periods={periods}
      onUpdateRates={handleUpdateRates}
      onLearnMoreClick={handleLearnMore}
    />
    )}

    {!isAccountActive &&  
    (

    <MerchantCashbackDashboardPreActivation
        onUpdateRates={handleUpdateRates}
        onLearnMoreClick={handleLearnMore}
        pendingRates={periods.pending}
    />
    )}
    </DashboardLayout>


    <LearningModal
        isOpen={learnMoreDialogOpen}
        onClose={() => setLearnMoreDialogOpen(false)}
        activeTab={activeTabLearnMore}
      />

      <UpdateCashbackDialog
        open={updateRatesDialogOpen}
        onClose={() => setUpdateRatesDialogOpen(false)}
        />
    </>
  );
};

export default MainCashback;