import React, { useState } from "react";
import { ChevronDown, User, HelpCircle, FileText, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import DocumentsModal from "./documents/DocumentsModal";
import { useAuthHelpers } from "functions/auth/signout";


const MenuButton = ({ icon: Icon, label, onClick, className = "" }) => (
  <button
    onClick={onClick}
    className={`flex w-full items-center space-x-3 rounded px-4 py-3 text-left text-gray-700 transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200 ${className}`}
  >
    <Icon className="h-5 w-5" />
    <span className="font-medium">{label}</span>
  </button>
);

const SettingsPanel = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleSignOut } = useAuthHelpers();

  const menuItems = [
    {
      icon: User,
      label: "Account Management",
      action: () => navigate("/dashboard/kyc")
    },
    {
      icon: HelpCircle,
      label: "Help Center",
      action: () => navigate("/help/center")
    },
    {
      icon: FileText,
      label: "Documents",
      action: () => setIsModalOpen(true)
    }
  ];

  return (
    <>
      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-black/25 transition-opacity"
          onClick={onClose}
        />
      )}

      {/* Settings Panel */}
      <div
        className={`fixed right-0 top-0 z-50 flex h-screen w-80 flex-col bg-white shadow-lg transition-transform duration-200 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Header */}
        <div className="flex h-16 items-center justify-between border-b border-gray-200 px-6">
          <h2 className="text-lg font-semibold text-gray-900">Settings</h2>
          <button 
            onClick={onClose}
            className="rounded-full p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <ChevronDown className="h-6 w-6 transform rotate-90" />
          </button>
        </div>

        {/* Main Content */}
        <div className="flex flex-1 flex-col">
          <div className="p-6 space-y-2">
            {menuItems.map((item, index) => (
              <MenuButton
                key={index}
                icon={item.icon}
                label={item.label}
                onClick={item.action}
              />
            ))}
          </div>

          {/* Sign Out Button - Separated at bottom */}
          <div className="mt-auto p-6 border-t border-gray-200">
            <MenuButton
              icon={LogOut}
              label="Sign Out"
              onClick={handleSignOut}
              className="text-red-600 hover:bg-red-50 active:bg-red-100"
            />
          </div>
        </div>
      </div>

      {/* Documents Modal */}
      {isModalOpen && (
        <DocumentsModal 
          onClose={() => setIsModalOpen(false)} 
          open={isModalOpen}
        />
      )}
    </>
  );
};

export default SettingsPanel;