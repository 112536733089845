import React, { useState } from 'react';

export const BasicBusinessInfoStep = ({ formData, setFormData, existingLocationName, emailOptions }) => {
  const [emailError, setEmailError] = useState('');
  const [emailInput, setEmailInput] = useState(formData.email || '');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailInput(email);
    
    if (email && !validateEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
      setFormData({ ...formData, email: email });
    }
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Basic Business Information</h2>
        <p className="text-sm text-gray-500">Enter your business name and email</p>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Business Name
          </label>
          <input
            type="text"
            value={formData.name}
            disabled={!!existingLocationName}
            className={`w-full border border-gray-200 rounded-lg p-2.5 outline-none ${
              existingLocationName ? 'bg-gray-100' : 'focus:ring-0 focus:border-[#0D4029]'
            }`}
            placeholder="Enter business name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email Address
          </label>
          <input
            type="email"
            value={emailInput}
            className={`w-full border rounded-lg p-2.5 outline-none focus:ring-0 focus:border-[#0D4029] ${
              emailError ? 'border-red-500' : 'border-gray-200'
            }`}
            placeholder="Enter email address"
            onChange={handleEmailChange}
            list="email-options"
            aria-invalid={!!emailError}
            aria-describedby={emailError ? "email-error" : undefined}
          />
          {emailError && (
            <p id="email-error" className="mt-1 text-sm text-red-500">
              {emailError}
            </p>
          )}
          <datalist id="email-options">
            {emailOptions?.map((option) => (
              <option key={option.value} value={option.value} />
            ))}
          </datalist>
        </div>
      </div>
    </div>
  );
};

export default BasicBusinessInfoStep;