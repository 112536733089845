import React from 'react';
import { Check, ChevronRight, ShieldCheck, Timer, BadgeCheck } from 'lucide-react';

const SetupStepsModal = ({
  open,
  onClose,
  onComplete,
  accountActive,
  activationLoading,
  stepStatuses,
  onActivateAccount,
}) => {
  const steps = [
    {
      title: 'Business Details Verified (KYC)',
      description: 'Ensure your business information is verified according to regulations.',
      status: stepStatuses.kyc,
    },
    {
      title: 'Setup Location',
      description: 'Add your business location for customers to discover you in the app.',
      status: stepStatuses.locationVerified,
    },
  ];

  const allStepsCompleted = steps.every((step) => step.status);

  if (!open) return null;

  // Render content based on account state
  const renderContent = () => {
    // Active Account State
    if (accountActive) {
      return (
        <div className="flex flex-col items-center text-center px-6 py-12">
          <div className="w-16 h-16 mb-6 rounded-full bg-emerald-50 flex items-center justify-center">
            <BadgeCheck className="w-8 h-8 text-emerald-600" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">
            Your account is active
          </h2>
          <p className="text-base text-gray-600 max-w-md">
            Your account is ready to process transactions and grow your business.
          </p>
        </div>
      );
    }

    // Ready for Activation State
    if (allStepsCompleted) {
      return (
        <div className="flex flex-col items-center text-center px-6 py-12">
          <div className="w-16 h-16 mb-6 rounded-full bg-blue-50 flex items-center justify-center">
            <Timer className="w-8 h-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 mb-3">
            Ready to launch your business
          </h2>
          <p className="text-base text-gray-600 mb-8 max-w-md">
            You've completed all the requirements. Activate your account to start accepting payments.
          </p>
          <button
            onClick={onActivateAccount}
            disabled={activationLoading}
            className="w-full max-w-sm px-5 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-100 disabled:opacity-50 transition-colors"
          >
            {activationLoading ? 'Activating...' : 'Activate Account'}
          </button>
        </div>
      );
    }

    // Setup Steps State
    return (
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex items-center gap-4 mb-8">
          <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center">
            <ShieldCheck className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              Complete your setup
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Complete these steps to activate your account
            </p>
          </div>
        </div>

        <div className="space-y-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="flex items-start gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <div className={`w-6 h-6 rounded-full flex items-center justify-center flex-shrink-0 mt-1
                ${step.status ? 'bg-emerald-50' : 'bg-gray-100'}`}>
                {step.status ? (
                  <Check className="w-4 h-4 text-emerald-600" />
                ) : (
                  <div className="w-1.5 h-1.5 rounded-full bg-gray-400" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between gap-4">
                  <h3 className="text-base font-medium text-gray-900">
                    {step.title}
                  </h3>
                  {!step.status && (
                    <button
                      onClick={() => onComplete(index)}
                      className="flex items-center gap-1.5 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      Complete
                      <ChevronRight className="w-4 h-4" />
                    </button>
                  )}
                </div>
                <p className="mt-1 text-sm text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-gray-900/20 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl overflow-hidden">
        {renderContent()}
        
        <div className="px-6 py-4 border-t border-gray-100 bg-gray-50 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetupStepsModal;