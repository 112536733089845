import EmployeeManagementPage from "../../ui/organims/EmployeeManagementPage";
import EmployeeManagementLogic from "../../functional/organisms/employeeManagementLogic";

const EmployeeManagementPageComponent = ({onBackClick}) => {
  const {
    onButtonClick,
    handleCloseInfoDailog,
    showInfoDailog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    showDeleteDialog,
    employeeConstant,
    handleDelete,
  } = EmployeeManagementLogic();

  return (
    <EmployeeManagementPage
      onButtonClick={onButtonClick}
      handleCloseInfoDailog={handleCloseInfoDailog}
      showInfoDailog={showInfoDailog}
      handleOpenDeleteDialog={handleOpenDeleteDialog}
      handleCloseDeleteDialog={handleCloseDeleteDialog}
      showDeleteDialog={showDeleteDialog}
      employeeConstant={employeeConstant}
      handleDelete={handleDelete}
      onBackClick={onBackClick}
    />
  );
};

export default EmployeeManagementPageComponent;
