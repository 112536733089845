import React, { useState, useEffect, useRef } from 'react';
import { Search, ChevronDown } from 'lucide-react';

const SearchAndFilter = ({ onSearchChange, filters, onFilterChange }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRefs = useRef({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDropdown) {
        const activeDropdown = dropdownRefs.current[openDropdown];
        if (activeDropdown && !activeDropdown.contains(event.target)) {
          setOpenDropdown(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  return (
    <div className="flex items-center justify-between mb-8">
      <div className="relative flex-1 max-w-md">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search boosts"
          className="w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029] transition-colors"
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
      <div className="flex space-x-3">
        {filters.map((filter, index) => (
          <div 
            key={index} 
            className="relative"
            ref={(el) => dropdownRefs.current[filter.key] = el}
          >
            <button
              className="px-4 py-2.5 border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029] cursor-pointer transition-colors flex items-center justify-between min-w-[180px]"
              onClick={() => setOpenDropdown(openDropdown === filter.key ? null : filter.key)}
            >
              <span className="text-gray-700">
                {filter.options.find(option => option.value === filter.value)?.label}
              </span>
              <ChevronDown className="w-4 h-4 ml-2 text-gray-400" />
            </button>
            {openDropdown === filter.key && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                {filter.options.map((option) => (
                  <button
                    key={option.value}
                    className="w-full px-4 py-2 text-left hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg"
                    onClick={() => {
                      onFilterChange(filter.key, option.value);
                      setOpenDropdown(null);
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchAndFilter;