import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useFullFetchFeedback from '../../../../functions/apiFetchers/fullPageLoaders';
import { setTransactionStatusAsRefunded } from '../../../../features/transactions/transactionsSlice';

const RefundContent = ({ data, onClose, invoiceId }) => {
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleRefundSubmit = async () => {
    await fetchFeedback({
      endpoint: 'merchant/process_refund/stripe/',
      requestType: 'POST',
      loaderType: 'dialogueLoader',
      data: {
        transaction_id: data.id,
      },
      loaderMessage: 'Processing refund...',
      successMessage: 'Refund successful',
      onSuccess: (response) => {
        dispatch(setTransactionStatusAsRefunded(data.id));
        onClose();
      },
      onError: (error) => {
        console.error('Refund failed:', error);
        onClose();
      },
    });
  };

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50 p-4">
      <div ref={modalRef} className="bg-white w-full max-w-lg shadow-xl">
        {/* Header with X in corner */}
        <div className="flex justify-between items-start px-6 pt-6 pb-4">
          <div className="flex-1 text-center">
            <h2 className="text-lg font-medium text-gray-900">
              Process Refund
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Transaction #{data.id}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 -mt-1 -mr-2 p-2"
            aria-label="Close"
          >
            <X size={18} />
          </button>
        </div>

        {/* Transaction Amount */}
        <div className="px-6 py-4">
          <div className="text-center">
            <div className="text-2xl font-medium text-gray-900">
              {data.transaction_amount}
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Transaction Amount
            </p>
          </div>
        </div>

        {/* Bottom section with shade */}
        <div className="bg-gray-50 px-6 pt-6 pb-6 border-t border-gray-100">
          <p className="text-sm text-gray-600 text-center mb-4">
            This refund will be processed to the original payment method
          </p>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={handleRefundSubmit}
              className="px-4 py-2.5 text-sm bg-[#0D4029] text-white font-medium hover:bg-[#0D4029]/90 transition-colors focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
            >
              Confirm Refund
            </button>

            <button
              onClick={onClose}
              className="px-4 py-2.5 text-sm text-[#0D4029] bg-white font-medium border border-gray-300 hover:border-[#0D4029] hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundContent;