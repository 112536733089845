import React, { useState } from "react";
import CustomTable from "./transactions";
import { Box, Modal, Typography } from "@mui/material";
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";

import ManageModal from "./optionsModal";
import ManageContent from "./ManageModal";
import RefundContent from "./RefundModal";
import { set } from "lodash";

// Function to format currency in "$00.00" format
const formatCurrency = (value) => {
  return `$${parseFloat(value).toFixed(2)}`;
};


const TransactionsTable = ({
  data,
  loading,
  rowsPerPage,
  totalRows,
  page,
  handlePageChange,
}) => {
  // Preprocess data to format currency fields
  const formattedData = data.map((item) => ({
    ...item,
    transaction_amount: formatCurrency(item.transaction_amount),
    cashbackEarned: formatCurrency(item.cashbackEarned),
    cashbackRedeemed: formatCurrency(item.cashbackRedeemed),
  }));
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [nestedModalType, setNestedModalType] = useState( "manage");


  const openTransactionDetailsModal = (transaction) => {
    console.log(transaction);
    setSelectedTransaction(transaction);
    setOpenTransactionDetails(true);
  }

  const closeTransactionDetailsModal = () => {
    setOpenTransactionDetails(false);
    setSelectedTransaction(null);
    setNestedModalType("manage");


  }
  

  // Set header text and description based on viewMode
  const headerTitle =  "Manage your payments";
  const headerDescription = "Manage and control all your payment activities here.";

  return (
    <>
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        {/* Page Header */}
        <PageHeader
          title={headerTitle}
          description={headerDescription}
          backArrow={false}

       
        />

  
          <CustomTable
            title="Consumer Transactions"
         
            data={formattedData} // Pass formatted data here
            loading={loading}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            page={page}
            handlePageChange={handlePageChange}
            showPagination={true}
            onRowClick={openTransactionDetailsModal}
          />
     
      </Box>
    </Box>

    {openTransactionDetails && (
  <>
    {nestedModalType === "manage" && selectedTransaction && (
      <ManageModal
        invoiceId={selectedTransaction.id}
        onManage={() => setNestedModalType("manageTransaction")}
        onRefund={() => setNestedModalType("refund")}
        onClose={closeTransactionDetailsModal}
      />
    )}

    {nestedModalType === "manageTransaction" && selectedTransaction && (
      <ManageContent 
        data={selectedTransaction} 
        invoiceId={selectedTransaction.id}
        onClose={closeTransactionDetailsModal}
      />
    )}

    {nestedModalType === "refund" && selectedTransaction && (
      <RefundContent 
        data={selectedTransaction} 
        invoiceId={selectedTransaction.id}
        onClose={closeTransactionDetailsModal}
      />
    )}
  </>
)}

</>
  );
} 


export default TransactionsTable;