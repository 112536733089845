import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const GoogleMapsAutocomplete = ({ onChange, name, value }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    LoadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCkVBA9waHAo7hb8-Xu8ycaEXj24bl6IeE&libraries=places`,
      (error) => {
        if (error) {
          console.error('Google Maps API script not loaded');
        }
      }
    );
  }, []);

  useEffect(() => {
    if (isValidAddress(value)) {
      setInputValue(formatAddress(value));
    }
  }, [value]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSuggestionSelect = async (placeId) => {
    if (!placeId) return;

    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressObj = place.address_components.reduce((acc, component) => {
          const type = component.types[0];
          if (type === 'street_number') {
            acc.address = component.long_name + (acc.address ? ' ' + acc.address : '');
          } else if (type === 'route') {
            acc.address = (acc.address ? acc.address + ' ' : '') + component.long_name;
          } else if (type === 'locality') {
            acc.city = component.long_name;
          } else if (type === 'administrative_area_level_1') {
            acc.state = component.short_name;
          } else if (type === 'postal_code') {
            acc.zip_code = component.long_name;
          }
          return acc;
        }, { address: '', city: '', state: '', zip_code: '' });

        if (isValidAddress(addressObj)) {
          const event = {
            target: { name, value: addressObj },
          };
          onChange(event);
          setInputValue(formatAddress(addressObj));
          setSuggestions([]);
        }
      }
    });
  };

  const fetchSuggestions = (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions.map((p) => ({ label: p.description, placeId: p.place_id })));
        } else {
          setSuggestions([]);
        }
      }
    );
  };

  useEffect(() => {
    fetchSuggestions(inputValue);
  }, [inputValue]);

  const renderSuggestions = () => {
    if (!suggestions.length) return null;
    
    const rect = inputRef.current?.getBoundingClientRect();
    if (!rect) return null;

    const style = {
      position: 'fixed',
      top: `${rect.bottom + window.scrollY}px`,
      left: `${rect.left + window.scrollX}px`,
      width: `${rect.width}px`,
      zIndex: 50
    };

    return createPortal(
      <ul className="bg-white border border-gray-200 rounded-lg mt-1 max-h-60 overflow-y-auto shadow-lg" style={style}>
        {suggestions.map((suggestion, index) => (
          <li
            key={index}
            className="p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => handleSuggestionSelect(suggestion.placeId)}
          >
            {suggestion.label}
          </li>
        ))}
      </ul>,
      document.body
    );
  };

  return (
    <div>
<input
  ref={inputRef}
  type="text"
  value={inputValue}
  className="w-full border border-gray-200 rounded-lg p-2.5 outline-none focus:ring-0 focus:border-[#0D4029]"
  placeholder="Enter an address"
  onChange={handleInputChange}
/>
      {renderSuggestions()}
    </div>
  );
};

const LoadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = () => callback(null);
  script.onerror = (error) => callback(error);
  document.head.appendChild(script);
};

const isValidAddress = (address) => {
  return (
    address &&
    typeof address === 'object' &&
    address.address &&
    address.city &&
    address.state &&
    address.zip_code
  );
};

const formatAddress = (addressObj) => {
  return `${addressObj.address}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zip_code}`;
};

export default GoogleMapsAutocomplete;