import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trash2, MapPin, Mail, Phone, Globe, Store, Plus, Building2, ChevronDown } from 'lucide-react';
import { selectAllStoreLocations } from '../../features/stores/storesSelectors';
import { deleteStoreLocation } from '../../features/stores/storesSlice';
import DashboardLayout from 'sideBar/main';
import LocationModal from './AddLocationDialog';

import useFullFetchFeedback from '../../functions/apiFetchers/fullPageLoaders';
import PageHeader from 'components/molecules/pageHeader/PageHeader';
import DeleteDialog from './deleteModal';
import CreateLocationModal from './locationModal/main';




const LocationsManagement = () => {
  const locationList = useSelector(selectAllStoreLocations);
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [locationsExpanded, setLocationsExpanded] = useState([]);

  useEffect(() => {
    if (locationList) {
      setLocationsExpanded(new Array(locationList.length).fill(false));
    }
  }, [locationList]);

  const toggleExpanded = (index) => {
    setLocationsExpanded((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedLocationId(null);
    setIsDeleteLoading(false);
    setIsDeleteConfirmed(false);
  };

  const handleDeleteClick = (locationId) => {
    setSelectedLocationId(locationId);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setIsDeleteLoading(true);
  
    await fetchFeedback({
      endpoint: `merchant/locations/${selectedLocationId}/`,
      
      requestType: "DELETE",
      loaderType: "dialogueLoader",
      onSuccess: () => {
        dispatch(deleteStoreLocation(selectedLocationId));

        setIsDeleteLoading(false);
        setIsDeleteConfirmed(true);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case 'Active':
        return {
          container: 'border border-[#3EB489] bg-[#F0FAF7]',
          text: 'text-[#3EB489]',
          dot: 'bg-[#3EB489]'
        };
      case 'Pending':
        return {
          container: 'border border-yellow-200 bg-yellow-50',
          text: 'text-yellow-700',
          dot: 'bg-yellow-500'
        };
      default:
        return {
          container: 'border border-gray-200 bg-gray-50',
          text: 'text-gray-700',
          dot: 'bg-gray-500'
        };
    }
  };

  return (
    <DashboardLayout>
    
          <PageHeader
            title="Location Management"
            description="Add and showcase consumer-friendly details to help customers discover, connect, and engage with your locations effortlessly."
            action={
              <button
                onClick={() => setOpen(true)}
                className="inline-flex items-center px-4 py-2 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
              >
                Add New Location
                <Plus className="w-4 h-4 ml-2" />
              </button>
            }
          />

          {/* Summary Stats */}
          <div className="xl:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-[#F0FAF7] border border-[#3EB489]/20 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900">Total Locations</h3>
                <Store className="h-5 w-5 text-[#3EB489]" />
              </div>
              <p className="mt-2 text-3xl font-semibold text-[#3EB489]">{locationList?.length || 0}</p>
              <p className="mt-2 text-sm text-gray-600">All your registered business locations</p>
            </div>
            <div className="bg-white border border-gray-200 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900">Active Locations</h3>
                <div className="h-2 w-2 rounded-full bg-[#3EB489]" />
              </div>
              <p className="mt-2 text-3xl font-semibold text-gray-900">
                {locationList?.filter(loc => loc.status === 'Approved').length || 0}
              </p>
              <p className="mt-2 text-sm text-gray-600">Locations visible to customers</p>
            </div>
            <div className="bg-white border border-gray-200 rounded-lg p-6">
              <div className="flex items-center justify-between">
                <h3 className="text-sm font-medium text-gray-900">Pending Review</h3>
                <div className="h-2 w-2 rounded-full bg-yellow-500" />
              </div>
              <p className="mt-2 text-3xl font-semibold text-gray-900">
                {locationList?.filter(loc => loc.status === 'Pending').length || 0}
              </p>
              <p className="mt-2 text-sm text-gray-600">Awaiting quality check</p>
            </div>
          </div>

          {/* Locations Grid */}
          {!locationList || locationList.length === 0 ? (
            <div className="mt-8 rounded-lg border-2 border-dashed border-gray-200 p-12">
              <div className="text-center">
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-50">
                  <Store className="h-6 w-6 text-gray-400" />
                </div>
                <h3 className="mt-4 text-base font-medium text-gray-900">No locations added</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by adding your first business location</p>
                <div className="mt-6">
                  <button
                    onClick={() => setOpen(true)}
                    className="inline-flex items-center rounded-lg bg-[#0D4029] px-4 py-2.5 text-sm font-medium text-white hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
                  >
                    Add your first location
                    <Plus className="w-4 h-4 ml-2" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
              {locationList.map((location, index) => {
                const statusStyles = getStatusStyles(location.status);
                const isExpanded = locationsExpanded[index];
                const isBase64 = typeof location.store_photo === 'string' && location.store_photo.startsWith('data:image');
                const isHttpUrl = typeof location.store_photo === 'string' && (location.store_photo.startsWith('http') || location.store_photo.startsWith('/'));
                const logoSrc = isBase64 ? location.store_photo : isHttpUrl ? location.store_photo : typeof location.store_photo === 'string' ? `data:image/png;base64,${location.store_photo}` : null;
                
                return (
                  <div
                    key={location.id}
                    className="group relative overflow-hidden rounded-lg border border-gray-200 bg-white transition-all duration-200 hover:border-gray-300 hover:shadow-lg"
                  >
                    <div className="flex p-6">
                      {/* Location Image */}
                      <div className="relative flex-shrink-0">
                        <div className="bg-gray-50 rounded-lg p-1">
                          <img
                            src={logoSrc}
                            alt={location.name}
                            className="h-36 w-36 rounded-lg object-cover ring-1 ring-gray-200"
                          />
                        </div>
                        <div className={`absolute -right-2 -top-2 flex items-center space-x-1 rounded-md px-2.5 py-1 shadow-sm ${statusStyles.container}`}>
                          <div className={`h-1.5 w-1.5 rounded-full ${statusStyles.dot}`} />
                          <span className={`text-xs font-semibold ${statusStyles.text}`}>
                            {location.status.toUpperCase()}
                          </span>
                        </div>
                      </div>

                      {/* Location Details */}
                      <div className="ml-8 flex flex-1 flex-col">
                        <div className="flex items-start justify-between">
                          <div>
                            <h2 className="text-xl font-semibold text-gray-900 group-hover:text-[#3EB489] transition-colors">
                              {location.name}
                            </h2>
                            <div className="mt-2 flex flex-col space-y-1">
                              <div className="flex items-center text-gray-500">
                                <Building2 className="mr-1.5 h-4 w-4" />
                                <span className="text-sm font-medium">{location.type}</span>
                              </div>
                              <div className="flex items-center text-gray-500">
                                <MapPin className="mr-1.5 h-4 w-4" />
                                <span className="text-sm">{location.address}</span>
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={() => handleDeleteClick(location.id)}
                            className="rounded-md p-2 text-gray-400 opacity-0 transition-all duration-200 hover:bg-red-50 hover:text-red-600 group-hover:opacity-100"
                            title="Delete location"
                          >
                            <Trash2 className="h-5 w-5" />
                          </button>
                        </div>

                        <div className={`mt-6 grid gap-6 md:grid-cols-2 ${!isExpanded && 'hidden'}`}>
                          <div className="space-y-3">
                            <div className="flex items-center text-gray-600 group-hover:text-gray-900 transition-colors">
                              <Mail className="mr-2 h-4 w-4 text-gray-400" />
                              <span className="text-sm">{location.email}</span>
                            </div>
                            <div className="flex items-center text-gray-600 group-hover:text-gray-900 transition-colors">
                              <Phone className="mr-2 h-4 w-4 text-gray-400" />
                              <span className="text-sm">{location.number}</span>
                            </div>
                            <div className="flex items-center text-gray-600 group-hover:text-gray-900 transition-colors">
                              <Globe className="mr-2 h-4 w-4 text-gray-400" />
                              <span className="text-sm">{location.website}</span>
                            </div>
                          </div>

                          <div>
                            <div className="text-sm text-gray-600">
                              <span className="block font-medium text-gray-900">Description</span>
                              <p className="mt-1 line-clamp-3 group-hover:text-gray-900 transition-colors">
                                {location.description}
                              </p>
                            </div>
                          </div>
                        </div>

                        <button
                          onClick={() => toggleExpanded(index)}
                          className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-[#0D4029] text-[#0D4029] rounded-lg hover:bg-[#0D4029] hover:text-white transition-colors"
                        >
                          {isExpanded ? "Show Less" : "Show More"}
                          <ChevronDown className={`ml-2 h-4 w-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
 
   

      {/* Modals */}
      { open &&
      <CreateLocationModal isOpen={open} onClose={() => setOpen(false)} />
}
      {/* <LocationModal open={open} onClose={() => setOpen(false)} /> */}
      <DeleteDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title="Are you sure you want to delete this location?"
        content="This action cannot be undone. Once deleted, the location will be permanently removed."
        deleteLoading={isDeleteLoading}
        deleteConfirmation={isDeleteConfirmed}
        onConfirm={handleConfirmDelete}
      />
    </DashboardLayout>
  );
};

export default LocationsManagement;