import React, { useState } from 'react';
import { Eye, EyeOff, Plus, Send, Lock, RefreshCw, Trash2, AlertCircle, InfoIcon, Mail, UserPlus, User, KeyIcon, Shield, Monitor } from 'lucide-react';
import useFullFetchFeedback from "functions/apiFetchers/fullPageLoaders";
import CredentialsModal from './CredentialsModal';
import { deleteEmployee } from 'features/register/registerSlice';
import { useDispatch } from 'react-redux';
import PageHeader from 'components/molecules/pageHeader/PageHeader';

const POSCredentials = ({
  credentials = {
    username: "",
    register_pin: "",
    visible_password: "",
    register_count: "",
  },
  handleOpenResetPasswordDialog,
  employeeModalOpen,
  employees = []
}) => {
  const fetchFeedback = useFullFetchFeedback();

  // UI State
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);


  const [sendEmployeeModalOpen, setSendEmployeeModalOpen] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
  const [sendEmailError, setSendEmailError] = useState(false);
  const [isAllEmployees, setIsAllEmployees] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const dispatch = useDispatch();

  // API Handlers
  const deleteEmployeeSubmit = async (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    if (!employee) return;

    await fetchFeedback({
      endpoint: `merchant/delete/employee/`,
      loaderType: "dialogueLoader",
      data: { email: employee.email },
      requestType: "POST",

      onSuccess: (responseData) => {
        dispatch(deleteEmployee(employee.email));

        setShowDeleteConfirm(false);
        setSelectedEmployee(null);
      },
    });
  };

  const sendEmailToAllEmployeesSubmit = async () => {
    setIsAllEmployees(true);
    setSendEmployeeModalOpen(true);
    setSendEmailLoading(true);

    const employeeEmailList = employees.map(employee => employee.email);
    
    await fetchFeedback({
      endpoint: `merchant/send/credentials/`,
      data: { emails: employeeEmailList },
      requestType: "POST",
      onSuccess: (responseData) => {
        setSendEmailSuccess(true);
        setSendEmailLoading(false);
      },
      onError: () => {
        setSendEmailError(true);
        setSendEmailLoading(false);
      }
    });
  };

  const sendEmailToOneEmployeeSubmit = async (employeeId) => {
    const employee = employees.find(emp => emp.id === employeeId);
    if (!employee) return;

    setIsAllEmployees(false);
    setEmployeeName(employee.first_name);
    setSendEmployeeModalOpen(true);
    setSendEmailLoading(true);

    await fetchFeedback({
      endpoint: `merchant/send/credentials/`,

      data: { emails: [employee.email] },
      requestType: "POST",
      onSuccess: (responseData) => {
        setSendEmailSuccess(true);
        setSendEmailLoading(false);
      },
      onError: () => {
        setSendEmailError(true);
        setSendEmailLoading(false);
      }
    });
  };

  // UI Handlers
  const handleDeleteClick = (employee) => {
    setSelectedEmployee(employee);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedEmployee) {
      deleteEmployeeSubmit(selectedEmployee.id);
    }
  };

  return (
    <>
    

      <PageHeader
        title={"Streamline User And Employee Management"}
        description={
          "Effertless credential setup and sealmess employee oversight"
        }
        backArrow={false}
  
      />


<div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
  {/* Left Column - Credentials Management */}
  <div>
    <div className="h-[500px] rounded-lg border border-gray-200 bg-white shadow-sm">
      {/* Header */}
      <div className="border-b border-gray-200 px-6 py-5">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium text-gray-900">Store Credentials</h2>
          <button
            onClick={handleOpenResetPasswordDialog}
            className="flex items-center rounded-lg bg-[#0D4029] px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
          >
            <RefreshCw className="mr-2 h-4 w-4" />
            Update Credentials
          </button>
        </div>
      </div>
      
      {/* Content */}
      <div className="flex h-[342px] flex-col justify-between p-6">
        <div className="space-y-4">
          {/* Username */}
          <div className="flex items-center justify-between rounded-lg bg-gray-50 px-4 py-3">
            <div className="flex items-center space-x-3">
              <User className="h-4 w-4 text-gray-400" />
              <span className="text-sm font-medium text-gray-600">Username</span>
            </div>
            <span className="text-sm font-medium text-gray-900">{credentials.username}</span>
          </div>

          {/* Password */}
          <div className="flex items-center justify-between rounded-lg bg-gray-50 px-4 py-3">
            <div className="flex items-center space-x-3">
              <KeyIcon className="h-4 w-4 text-gray-400" />
              <span className="text-sm font-medium text-gray-600">Password</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-medium text-gray-900">
                {showPassword ? credentials.visible_password : '••••••••••••'}
              </span>
              <button
                onClick={() => setShowPassword(!showPassword)}
                className="text-gray-400 transition-colors hover:text-gray-600"
              >
                {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
              </button>
            </div>
          </div>

          {/* Refund PIN */}
          <div className="flex items-center justify-between rounded-lg bg-gray-50 px-4 py-3">
            <div className="flex items-center space-x-3">
              <Shield className="h-4 w-4 text-gray-400" />
              <span className="text-sm font-medium text-gray-600">Refund PIN</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="text-sm font-medium text-gray-900">
                {showPin ? credentials.register_pin : '••••'}
              </span>
              <button
                onClick={() => setShowPin(!showPin)}
                className="text-gray-400 transition-colors hover:text-gray-600"
              >
                {showPin ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
              </button>
            </div>
          </div>

          {/* Active Registers */}
          <div className="flex items-center justify-between rounded-lg bg-gray-50 px-4 py-3">
            <div className="flex items-center space-x-3">
              <Monitor className="h-4 w-4 text-gray-400" />
              <span className="text-sm font-medium text-gray-600">Active Registers</span>
            </div>
            <div className="flex items-center space-x-1.5">
              <span className="text-sm font-medium text-gray-900">{credentials.register_count}</span>
              <span className="text-sm text-gray-500">registers in use</span>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Info */}
      <div className="border-t border-gray-200 bg-gray-50 px-6 py-4">
        <div className="flex items-start">
          <InfoIcon className="mr-3 h-5 w-5 text-gray-500" />
          <p className="text-sm text-gray-600">
            These are your store's main POS credentials. When you add new employees, they'll receive these credentials securely via email.
          </p>
        </div>
      </div>
    </div>
  </div>

  {/* Right Column - Employee Management */}
  <div>
    <div className="h-[500px] rounded-lg border border-gray-200 bg-white shadow-sm">
      {/* Header */}
      <div className="border-b border-gray-200 px-6 py-5">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-medium text-gray-900">Employee Access</h2>
          <div className="flex space-x-3">
            <button 
              onClick={sendEmailToAllEmployeesSubmit}
              className="flex items-center rounded-lg bg-[#0D4029] px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
            >
              <Send className="mr-2 h-4 w-4" />
              Send to All
            </button>
            <button
              onClick={() => employeeModalOpen(true)}
              className="flex items-center rounded-lg border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              <Plus className="mr-2 h-4 w-4" />
              Add Employee
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="h-[342px] p-6">
        <div className="h-full overflow-y-auto">
          {employees.length === 0 ? (
            <div className="flex h-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200 px-6 py-8">
              <UserPlus className="mb-2 h-8 w-8 text-gray-400" />
              <h3 className="mb-1 text-base font-medium text-gray-900">No employees yet</h3>
              <p className="mb-4 text-center text-sm text-gray-500">
                Add your first employee to start managing POS access
              </p>
              <button
                onClick={() => employeeModalOpen(true)}
                className="flex items-center rounded-lg bg-[#0D4029] px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
              >
                <Plus className="mr-2 h-4 w-4" />
                Add Employee
              </button>
            </div>
          ) : (
            <div className="space-y-3">
              {employees.map((employee) => (
                <div
                  key={employee.id}
                  className="group flex items-center justify-between rounded-lg border border-gray-200 px-4 py-3 hover:border-gray-300 hover:bg-gray-50"
                >
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900">{employee.name}</span>
                    <span className="text-sm text-gray-500">{employee.email}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button 
                      onClick={() => sendEmailToOneEmployeeSubmit(employee.id)}
                      className="flex items-center rounded-lg bg-white px-3 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-gray-300 transition-all hover:bg-gray-50 hover:shadow focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                      <Lock className="mr-2 h-4 w-4" />
                      Send Credentials
                    </button>
                    <button 
                      onClick={() => handleDeleteClick(employee)}
                      className="flex items-center rounded-lg p-1.5 text-gray-400 opacity-0 transition-all hover:bg-red-50 hover:text-red-600 group-hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Footer Info */}
      <div className="border-t border-gray-200 bg-gray-50 px-6 py-4">
        <div className="flex items-start">
          <Mail className="mr-3 h-5 w-5 text-gray-500" />
          <p className="text-sm text-gray-600">
            Employees will receive an email with secure access to the POS system. Use "Send to All" to resend credentials to everyone.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


        {/* Delete Confirmation Modal */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
              <div className="mb-4 flex items-start">
                <AlertCircle className="mr-3 h-6 w-6 flex-shrink-0 text-red-500" />
                <div>
                  <h3 className="text-lg font-medium text-gray-900">Remove Employee</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Are you sure you want to remove {selectedEmployee?.name}? This action cannot be undone.
                  </p>
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="rounded-lg border border-gray-300 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="rounded-lg bg-red-600 px-4 py-2 text-sm text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Remove Employee
                </button>
              </div>
            </div>
          </div>
        )}
    

      <CredentialsModal
        isOpen={sendEmployeeModalOpen}
        onClose={() => setSendEmployeeModalOpen(false)}
        isLoading={sendEmailLoading}
        isSuccess={sendEmailSuccess}
        isError={sendEmailError}
        isAllEmployees={isAllEmployees}
        employeeName={employeeName}
      />
    </>
  );
};

export default POSCredentials;