import React, { useState } from "react";
import { Bell } from "lucide-react";
import NotificationModal from "./NotificationModal";

const NotificationsComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    < >
      {/* Notification Button */}
      <button
        onClick={toggleModal}
        className="relative text-gray-600 hover:text-gray-900"
      >
        <Bell className="h-6 w-6" />
      </button>

      {/* Notification Modal */}
      {isModalOpen && (
      <NotificationModal onClose={toggleModal} open={isModalOpen}/>
      )}
    </>
  );
};

export default NotificationsComponent;