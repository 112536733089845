import React, { useState } from "react";
import CombinedFunctionality from "../../combined/organisms/Functionality";
import VerificationDialog from "../../../signUp/email/ui/organisms/dialog/main";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  
  const {
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    codeValues,
    inputRefs,
    handleChange,
    handleKeyDown,
    resendCode,
    confirmCode,
    resetCode,
    handleGoToDashboard,
    handleCloseCodeDialog,
    handleEmailSubmit,
    isCodeDialogOpen,
    handleGoogleSignUp,
  } = CombinedFunctionality({ setError: setErrors });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6 && /[a-z]/.test(password) && /[0-9]/.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!validatePassword(password)) {
      newErrors.password = "Password must be at least 6 characters with letters and numbers";
    }

    if (!agreedToTerms) {
      newErrors.terms = "You must agree to the terms to continue";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleEmailSubmit(email, password);
    }
  };

  return (
    < >
      <VerificationDialog
        email={email}
        isLoadingCode={isLoadingCode}
        isLoadingAccount={isLoadingAccount}
        errorMessage={errorMessage}
        onResendCode={resendCode}
        open={isCodeDialogOpen}
        onClose={handleCloseCodeDialog}
        isSuccess={isSuccess}
        codeLength={6}
        onGoToDashboard={handleGoToDashboard}
        inputRefs={inputRefs}
        codeValues={codeValues}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isResendingCode={isResendingCode}
      />
 <div className="h-[calc(100vh-6rem)] flex items-start justify-center">
 <div className="w-full min-w-[500px] max-w-xl sm:min-w-[500px] min-w-full bg-white h-full px-16 pt-16 rounded-t-2xl">
        <div className="mb-6">
          <h1 className="text-3xl font-bold mb-2" style={{ color: "#3EB489" }}>
            Clavaa
          </h1>
          <h2 className="text-xl font-medium mb-1" style={{ color: "#0D4029" }}>
            Create your account
          </h2>
          <p className="text-gray-600 text-sm">Get started with your business today</p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email address
            </label>
            <input
              id="email"
              type="email"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full px-3 py-2 rounded-lg border text-sm focus:outline-none focus:ring-1 ${
                errors.email
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-[#0D4029] focus:border-[#0D4029]"
              }`}
            />
            {errors.email && (
              <p className="text-sm text-red-500 mt-1">{errors.email}</p>
            )}
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-3 py-2 rounded-lg border text-sm focus:outline-none focus:ring-1 ${
                errors.password
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-[#0D4029] focus:border-[#0D4029]"
              }`}
            />
            {errors.password && (
              <p className="text-sm text-red-500 mt-1">{errors.password}</p>
            )}
          </div>

          <div className="flex items-start space-x-2">
            <input
              type="checkbox"
              id="terms"
              checked={agreedToTerms}
              onChange={(e) => setAgreedToTerms(e.target.checked)}
              className="mt-0.5 h-4 w-4 rounded border-gray-300 text-[#0D4029] focus:ring-[#0D4029]"
            />
            <label htmlFor="terms" className="text-sm text-gray-600 leading-tight">
              I agree to the{" "}
              <a href="#" className="text-[#0D4029] hover:underline">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="text-[#0D4029] hover:underline">
                Privacy Policy
              </a>
            </label>
          </div>

          <button
            type="submit"
            disabled={isLoadingAccount}
            className="w-full py-2.5 bg-[#0D4029] hover:bg-opacity-90 text-white text-sm font-medium rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isLoadingAccount ? "Creating account..." : "Create account"}
          </button>

          <div className="relative my-5">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or continue with</span>
            </div>
          </div>

          <button
            type="button"
            onClick={() => handleGoogleSignUp(agreedToTerms)}
            className="w-full py-2.5 px-4 bg-white border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-[#0D4029] flex items-center justify-center"
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
            </svg>
            Google
          </button>

          <p className="text-center text-sm text-gray-600 mt-5">
            Already have an account?{" "}
            <a href="#" className="text-[#0D4029] hover:underline font-medium"
                onClick={() => navigate("/sign-in")}
            >
              Sign in
            </a>
          </p>
        </form>
      </div>
    </div>
    </>
  );
};

export default SignUpForm;