import GoogleMapsAutocomplete from "components/atoms/inputs/components/addressNew";

export const LocationDetailsStep = ({ formData, setFormData }) => {
  const handleAddressChange = (event) => {
    console.log(event.target.value);
    setFormData(prev => ({
      ...prev,
      address: event.target.value
    }));
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Location Details</h2>
        <p className="text-sm text-gray-500">Add your business location</p>
      </div>
      <div className="space-y-4">
        <GoogleMapsAutocomplete 
          onChange={handleAddressChange}
          name="location"
          value={formData.location}
        />
      </div>
    </div>
  );
};