import React, { useState, useEffect } from "react";
import { ShoppingCart } from "lucide-react";

const ShoppingCartComponent = ({onButtonClick}) => {
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    // Initial load
    const loadCartItems = () => {
      const cartItems = JSON.parse(localStorage.getItem('clavaaCart') || '{}');
      const total = Object.values(cartItems).reduce((sum, qty) => sum + (qty || 0), 0);
      setTotalItems(total);
    };

    loadCartItems();

    // Listen for storage changes
    const handleStorageChange = (e) => {
      if (e.key === 'clavaaCart') {
        loadCartItems();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    
    // Check periodically for changes within the same tab
    const interval = setInterval(loadCartItems, 500);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, []);

  return (
    <button className="relative text-gray-600 hover:text-gray-900" onClick={onButtonClick}>
      <ShoppingCart className="h-6 w-6" />
      {totalItems > 0 && (
        <div className="absolute -top-2 -right-2 bg-[#0D4029] text-white text-xs w-5 h-5 flex items-center justify-center rounded-full">
          {totalItems}
        </div>
      )}
    </button>
  );
};

export default ShoppingCartComponent;