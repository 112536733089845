import React, { useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import DescriptionIcon from "@mui/icons-material/Description";
import { useAuthHelpers } from "../../../../../../../functions/auth/signout";
import { useNavigate } from "react-router-dom";
import DocumentsModal from "../documents/DocumentsModal";

const SettingsSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const { handleSignOut } = useAuthHelpers();
  const navigate = useNavigate();

  const toggleDrawer = (open) => {
    setIsOpen(open);
  };

  const toggleDocumentsModal = (open) => {
    setDocumentsOpen(open);
  };

  return (
    <Box  sx={{marginLeft: 8,}}>
      {/* Settings Icon in Top Bar */}
      <IconButton
        color="inherit"
        onClick={() => toggleDrawer(true)}
        aria-label="Open Settings"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          backgroundColor: "#f0f0f0",
          borderRadius: "50%",
          "&:hover": { backgroundColor: "#e0e0e0" },
        }}
      >
        <SettingsIcon fontSize="large" sx={{ color: "#333333" }} />
      </IconButton>

      {/* Sidebar Menu */}
      <Drawer anchor="right" open={isOpen} onClose={() => toggleDrawer(false)}>
        <Box
          sx={{
            width: 300,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Sidebar Header */}
          <Box sx={{ p: 2, backgroundColor: "#3EB489", color: "white" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Settings
            </Typography>
          </Box>
          <Divider />

          {/* Menu Options */}
          <List sx={{ flex: 1 }}>
            {/* Account Management */}
            <ListItem
              button
              onClick={() => {
                navigate("/dashboard/kyc");
                toggleDrawer(false);
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Account Management" />
            </ListItem>

            {/* Help Center */}
            <ListItem
              button
              onClick={() => {
                navigate("/help/center");
                toggleDrawer(false);
              }}
            >
              <ListItemIcon>
                <HelpOutlineIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Help Center" />
            </ListItem>

            {/* Documents */}
            <ListItem
              button
              onClick={() => {
                toggleDocumentsModal(true);
                toggleDrawer(false);
              }}
            >
              <ListItemIcon>
                <DescriptionIcon color="action" />
              </ListItemIcon>
              <ListItemText primary="Documents" />
            </ListItem>
          </List>

          <Divider />

          {/* Logout Section */}
          <Box sx={{ p: 2 }}>
            <ListItem>
              <ListItemIcon>
                <LogoutIcon color="error" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
              <Button
                variant="contained"
                color="error"
                onClick={handleSignOut}
                sx={{
                  ml: 1,
                  backgroundColor: "#FF6B6B",
                  "&:hover": { backgroundColor: "#E63946" },
                }}
              >
                Logout
              </Button>
            </ListItem>
          </Box>
        </Box>
      </Drawer>

      {/* Documents Modal */}
      <DocumentsModal open={documentsOpen} onClose={() => toggleDocumentsModal(false)} />
    </Box>
  );
};

export default SettingsSidebar;