import React, { useEffect, useRef } from 'react';
import Success from './molecule/Success';
import AccountLoading from './molecule/AccountLoading';
import CodeLoading from './molecule/CodeLoading';
import ResendCodeLoading from './molecule/ResendCodeLoading';

// Brand color
const BRAND_COLOR = '#0D4029';

function BaseDialog({ children, open, onOpenChange }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && open) {
        onOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [open, onOpenChange]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/40 backdrop-blur-sm flex items-center justify-center p-4 md:p-6">
      {children}
    </div>
  );
}

function BaseDialogContent({ children, className = "" }) {
  const contentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        const dialogProps = contentRef.current.closest('[role="dialog"]')
          ?.parentElement?.__reactProps$;
        if (dialogProps?.onOpenChange) {
          dialogProps.onOpenChange(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={contentRef}
      role="dialog"
      aria-modal="true"
      className={`relative bg-white rounded-xl shadow-2xl transform transition-all duration-200 
                 w-full max-w-[calc(100vw-2rem)] md:max-w-md mx-auto ${className}`}
      style={{
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.12)',
      }}
    >
      {children}
    </div>
  );
}

function VerificationDialog(props) {
  const {
    email,
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    onResendCode,
    open,
    inputRefs,
    codeValues,
    onChange,
    onKeyDown,
    onClose,
    onGoToDashboard,
  } = props;

  useEffect(() => {
    if (!isLoadingCode && !isLoadingAccount && open && !isSuccess) {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }, [isLoadingCode, isLoadingAccount, open, inputRefs, isSuccess]);

  const inputFields = [];
  for (let i = 0; i < 5; i++) {
    inputFields.push(
      <input
        key={i}
        ref={(el) => {
          inputRefs.current[i] = el;
        }}
        type="text"
        maxLength={1}
        value={codeValues[i]}
        onChange={(e) => onChange(i, e.target.value)}
        onKeyDown={(e) => onKeyDown(i, e)}
        className="w-12 h-12 md:w-14 md:h-14 text-center text-xl md:text-2xl font-semibold rounded-lg 
                 border-2 border-gray-200 outline-none
                 focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20 
                 hover:border-gray-300 transition-all duration-200"
        style={{
          fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif'
        }}
      />
    );
  }

  return (
    <BaseDialog open={open} onOpenChange={onClose}>
      <BaseDialogContent>
        <div className="relative flex flex-col items-center p-6 sm:p-8 md:p-10">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute right-3 top-3 md:right-4 md:top-4 rounded-full p-2 
                     text-gray-400 hover:text-gray-600 hover:bg-gray-100/80 transition-colors"
            aria-label="Close dialog"
          >
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>

          {/* Main Content */}
          {isSuccess ? (
            <Success onGoToDashboard={onGoToDashboard} />
          ) : isLoadingAccount ? (
            <AccountLoading />
          ) : isLoadingCode ? (
            <CodeLoading />
          ) : isResendingCode ? (
            <ResendCodeLoading />
          ) : (
            <div className="w-full flex flex-col items-center space-y-6 md:space-y-7">
              <div className="space-y-2 text-center">
                <h2 className="text-xl md:text-2xl font-semibold text-gray-900"
                    style={{ fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif' }}>
                  Verify Your Identity
                </h2>
                <p className="text-sm md:text-base text-gray-600 px-2">
                  Enter the verification code sent to{' '}
                  <span className="font-medium text-gray-900">{email}</span>
                </p>
              </div>
              
              <div className="flex gap-2 md:gap-3">
                {inputFields}
              </div>

              {errorMessage && (
                <div className="text-red-600 text-sm flex items-center gap-2 px-4 text-center">
                  <svg className="w-4 h-4 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <span>{errorMessage}</span>
                </div>
              )}

              <div className="flex flex-col items-center gap-3 md:gap-4 w-full pt-2">
                <button
                  onClick={onResendCode}
                  className="text-[#0D4029] hover:text-[#0D4029]/80 text-sm font-medium 
                           transition-colors duration-200 px-3 py-2 rounded-lg
                           hover:bg-[#0D4029]/5 active:bg-[#0D4029]/10"
                >
                  Resend Code
                </button>

                <div className="text-xs text-gray-500 text-center px-4">
                  Didn't receive the code? Check your spam folder
                </div>
              </div>
            </div>
          )}
        </div>
      </BaseDialogContent>
    </BaseDialog>
  );
}

export default VerificationDialog;