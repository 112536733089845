import { useCallback, useState } from 'react';

// material-ui
import { Avatar, Box, Tooltip, Modal } from '@mui/material';

// assets
import { IconBell } from '@tabler/icons-react';
import NotificationModal from './NotificationModal.js'; // Import the new modal component

const Notifications = () => {
    const [open, setOpen] = useState(false);

    // Toggle modal visibility
    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    // Define your color palette
    const colors = {
        default: {
            background: '#ffffff', // Clean white background for a default state
            border: '#E0E0E0', // Light gray for subtle borders
            hoverBackground: '#e0e0e0', // Slightly darker shade for hover effect
            color: '#333333', // Google's primary text color for default state
        },
        active: {
            background: '#e0e0e0', // Google Blue for active state
            border: '#185ABC', // Darker shade for active borders
            hoverBackground: '#e0e0e0', // Even darker shade for hover in active state
            color: '#333333', // White text for contrast
        },
    };
    const selectedColor = open ? colors.active : colors.default;

    return (
        <Box sx={{ ml: 2, mr: 2 }}>
            <Tooltip title="Notifications">
                <Avatar
                    variant="rounded"
                    sx={{
                        width: 40,
                        height: 40,
                        border: `1px solid ${selectedColor.border}`,
                        backgroundColor: selectedColor.background,
                        color: selectedColor.color,
                        transition: 'all 0.2s ease-in-out',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: colors.active.border,
                            backgroundColor: colors.active.hoverBackground,
                            color: colors.active.color,
                        },
                    }}
                    onClick={handleToggle}
                >
                    <IconBell />
                </Avatar>
            </Tooltip>

            {/* Render the modal */}
            {open && <NotificationModal open={open} onClose={handleToggle} />}
        </Box>
    );
};

export default Notifications;