import React, { useState } from 'react';
import { Search, ChevronRight, CreditCard, Users, BarChart, Settings, Book, MessageCircle, HelpCircle, Phone } from 'lucide-react';

const HelpCenterPage = ({ onArticleSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [copiedText, setCopiedText] = useState("");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(""), 2000); // Reset after 2 seconds
  };


  const categories = [
    {
      title: 'Getting Started',
      icon: Book,
      description: 'Learn the basics of setting up and using your Clavaa dashboard',
      articles: [
        { title: 'Quick Start Guide', id: 'quick-start-guide' },
        { title: 'Dashboard Overview', id: 'dashboard-overview' },
        { title: 'Setting Up Your Account', id: 'setting-up-your-account' },
        { title: 'How Hard is the Clavaa setup', id: 'how-hard-is-clavaa-setup' },
      ],
    },
    {
      title: 'Loyalty Program',
      icon: Users,
      description: 'Manage and customize your loyalty program settings',
      articles: [
        { title: 'Creating Reward Tiers', id: 'creating-reward-tiers' },
        { title: 'Setting Cashback Rates', id: 'setting-cashback-rates' },
        { title: 'Competing with Co-Brand Cards', id: 'cometing-with-big-brands' },
        { title: 'Program Analytics', id: 'program-analytics' },
      ],
    },
    {
      title: 'Payments',
      icon: CreditCard,
      description: 'Everything about processing payments and managing transactions',
      articles: [
        { title: 'Payment Processing Guide', id: 'payment-processing-guide' },
        { title: 'Integrating Clavaa with Your System', id: 'integrating-with-your-system' },
        { title: 'Refund Process', id: 'refund-process' },
        { title: 'Fee Structure', id: 'fee-structure' },
      ],
    },
    {
      title: 'Analytics & Reports',
      icon: BarChart,
      description: 'Understanding your data and generating insights',
      articles: [
        { title: 'Dashboard Metrics Explained', id: 'dashboard-metrics-explained' },
        { title: 'Custom Report Creation', id: 'custom-report-creation' },
        { title: 'Data Export Guide', id: 'data-export-guide' },
        { title: 'Performance Tracking', id: 'performance-tracking' },
      ],
    },
    {
      title: 'Boosts',
      icon: Settings,
      description: 'How Cashback Boosts work and how to set them up',
      articles: [
        { title: 'Boost Configuration', id: 'boost-configuration' },
        { title: 'Boost Performance Tracking', id: 'boost-performance-tracking' },
        { title: 'Boost Analytics', id: 'boost-analytics' },
        { title: 'Boost Optimization', id: 'boost-optimization' },
      ],
    },
    {
      title: 'General Help',
      icon: HelpCircle,
      description: 'Other helpful articles and resources',
      articles: [
        { title: 'How to Set Up Your First Loyalty Program', id: 'set-up-first-loyalty-program' },
        { title: 'Understanding Transaction Fees', id: 'understanding-transaction-fees' },
        { title: 'Customizing Customer Rewards', id: 'customizing-customer-rewards' },
        { title: 'Exporting Analytics Reports', id: 'exporting-analytics-reports' },
      ],
    },
  ];

  const popularArticles = [
    { title: 'How to Set Up Your First Loyalty Program', id: 'set-up-first-loyalty-program' },
    { title: 'Understanding Transaction Fees', id: 'understanding-transaction-fees' },
    { title: 'Customizing Customer Rewards', id: 'customizing-customer-rewards' },
    { title: 'Exporting Analytics Reports', id: 'exporting-analytics-reports' },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
  {/* Header */}
  <header className="bg-white border-b">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-[#0D4029]">Clavaa Help Center</div>
            <button className="text-[#3EB489] hover:text-[#0D4029] font-medium">
            <a href="https://merchant.clavaa.com/dashboard" >
              Back to Dashboard
              </a>
            </button>
          </div>
        </div>
      </header>

      {/* Hero Search */}
      <section className="bg-[#3EB489] py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-white text-4xl font-bold mb-6">How can we help you?</h1>
            <div className="relative">
              <input
                type="text"
                placeholder="Search for help..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-6 py-4 rounded-lg text-lg shadow-lg pl-12 focus:outline-none focus:ring-2 focus:ring-[#0D4029]"
              />
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
        </div>
      </section>

      {/* Popular Articles */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-6">Popular Articles</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {popularArticles.map((article) => (
                <a
                  key={article.id}
                  onClick={() => onArticleSelect(article.id)}
                  className="p-6 border rounded-lg hover:shadow-md transition-shadow group cursor-pointer"
                >
                  <div className="flex items-center justify-between">
                    <h3 className="font-medium text-gray-900 group-hover:text-[#3EB489]">{article.title}</h3>
                    <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-[#3EB489]" />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Categories */}
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-8">Browse by Category</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {categories.map(({ title, icon: Icon, description, articles }) => (
                <div key={title} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-start gap-4">
                    <div className="p-2 bg-[#3EB489]/10 rounded-lg">
                      <Icon className="w-6 h-6 text-[#3EB489]" />
                    </div>
                    <div>
                      <h3 className="font-bold text-lg mb-2">{title}</h3>
                      <p className="text-gray-600 text-sm mb-4">{description}</p>
                      <ul className="space-y-2">
                        {articles.map((article) => (
                          <li
                            key={article.id}
                            onClick={() => onArticleSelect(article.id)}
                            className="text-sm text-[#3EB489] hover:text-[#0D4029] flex items-center gap-1 cursor-pointer"
                          >
                            <ChevronRight className="w-4 h-4" />
                            {article.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      
      <div className="border-t pt-12 mt-12">
      <div className="bg-[#3EB489]/10 rounded-lg p-8">
        <div className="flex flex-col items-center gap-6 text-center">
          <div>
            <h2 className="text-2xl font-bold mb-2">Still need help?</h2>
            <p className="text-gray-600">
              Our support team is here to assist you 24/7
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 items-center">
            <button
              onClick={() => handleCopy("help@clavaa.com")}
              className="flex items-center gap-2 bg-[#3EB489] text-white px-6 py-3 rounded-lg hover:opacity-90"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              Email
            </button>
            <button
              onClick={() => handleCopy("1-848-448-6951")}
              className="flex items-center gap-2 border-2 border-[#3EB489] text-[#3EB489] px-6 py-3 rounded-lg hover:bg-[#3EB489] hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              Call
            </button>
          </div>
          {copiedText && (
            <div className="text-sm text-green-600">
              Copied "{copiedText}" to clipboard!
            </div>
          )}
        </div>
      </div>
    </div>
       
      
   



      {/* Footer */}
      <footer className="bg-gray-50 border-t">
        <div className="container mx-auto px-4 py-8">
          <div className="flex justify-between items-center">
            <p className="text-gray-600 text-sm">© 2024 Clavaa. All rights reserved.</p>
            <div className="flex items-center gap-6">
              <a href="#" className="text-gray-600 hover:text-[#3EB489] text-sm">Terms of Service</a>
              <a href="#" className="text-gray-600 hover:text-[#3EB489] text-sm">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
    
  );
};

export default HelpCenterPage;