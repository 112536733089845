import { createSlice } from '@reduxjs/toolkit';

const photo = require('../../images/fullPOS.png');
const photo2 = require('../../images/scanner.png');
const photo3 = require('../../images/bigSign.png');
const photo4 = require('../../images/smallSign.png');

const initialState = {
  items: {
    1: {
      id: 1,
      name: "Complete POS Package",
      description: "Everything you need to get started: Tablet, secure holder, and QR scanner",
      price: "0.00",
      image: photo,
      features: [
        "10.2-inch tablet optimized for Clavaa",
        "Adjustable tablet holder with 360° rotation",
        "Professional-grade QR scanner",
        "Quick setup guide",
        "24/7 technical support"
      ],
      highlights: "Most Popular"
    },
    2: {
      id: 2,
      name: "Scanner & Holder Bundle",
      description: "Perfect if you already have a compatible tablet",
      price: "0.00",
      image: photo2,
      features: [
        "Adjustable tablet holder",
        "Professional-grade QR scanner",
        "Compatibility guide",
        "Installation manual"
      ]
    },
    3: {
      id: 3,
      name: "Premium Store Sign",
      description: "Large format, illuminated sign for storefront display",
      price: "0.00",
      image: photo3,
      features: [
        "LED backlit display",
        "Weather-resistant",
        "Easy installation kit included",
        "2-year warranty"
      ]
    },
    4: {
      id: 4,
      name: "Countertop Display",
      description: "Elegant countertop sign with QR code display",
      price: "0.00",
      image: photo4,
      features: [
        "Sleek acrylic design",
        "Built-in QR code display",
        "Customizable insert",
        "Non-slip base"
      ]
    }
  }
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {} // No reducers needed if items are static
});
export default itemsSlice.reducer;
