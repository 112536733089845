import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

export const Card = ({ children, selected, onClick, className = '', isCustom = false }) => (
    <motion.div
      whileHover={{ scale: isCustom ? 1 : 1.02, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
      whileTap={{ scale: isCustom ? 1 : 0.98 }}
      className={`
        relative bg-white rounded-xl p-6 shadow-md hover:shadow-xl
        transition-all duration-300
        ${selected ? 'ring-1 ring-gray-200' : 'ring-1 ring-gray-100'} 
        ${isCustom ? '' : 'cursor-pointer'}
        ${className}
      `}
      onClick={onClick}
    >
      {selected && (
        <motion.div 
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="absolute -top-2 -right-2 bg-emerald-800 rounded-full p-1"
        >
          <Check className="w-4 h-4 text-white" />
        </motion.div>
      )}
      {children}
    </motion.div>
   );