import DashboardLayout from "sideBar/main";

import BusinessInformation from "./mainComponent";



const KycPageStripe = () => {
  return (
    <DashboardLayout>
     <BusinessInformation/>
    </DashboardLayout>
  );
};

export default KycPageStripe;
