// components/cashback/UpdateCashbackDialog.jsx
import React, { useState } from 'react';
import { X, ArrowLeft, ArrowRight, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import * as Dialog from '@radix-ui/react-dialog';
import { useDispatch } from 'react-redux';
import { setNextGeneralCashbackRate, setNextGold, setNextGoldRequiredSpend } from '../../../../features/cashback/cashbackSlice';
import useFullFetchFeedback from '../../../../functions/apiFetchers/fullPageLoaders';
import { Card, Button, Badge, Input } from '../ui';
import { CashbackPlans, CashbackReview } from './';

const UpdateCashbackDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [customValues, setCustomValues] = useState({ regular: null, gold: null });
  const [cashback, setCashback] = useState({
    regular: 0,
    gold: 0,
    gold_required_spend: 200,
  });

  const handleSelectCashbackPlan = (plan, value) => {
    if (!value && value !== 0) {
      return setCashback(prev => ({
        ...prev,
        [plan]: null
      }));
    }
  
    const numValue = Number(value);
    
    if (plan === "regular") {
      const newGoldRate = Number((numValue + (numValue * 0.75)).toFixed(2));
      setCashback(prev => ({
        ...prev,
        regular: numValue,
        gold: newGoldRate,
      }));
    } else {
      setCashback(prev => ({ ...prev, [plan]: numValue }));
    }
  };
  const handleChangeThresholdValue = (action) => {
    if (action === "decrement" && cashback.gold_required_spend === 0) return;
    setCashback(prev => ({
      ...prev,
      gold_required_spend: prev.gold_required_spend + (action === "increment" ? 100 : -100),
    }));
  };

  const calculateGoldPercentage = (percentage) => {
    return Number((cashback?.regular + cashback?.regular * (percentage / 100)).toFixed(2));
  };

  const submitCashbackDetailsApi = async () => {
    setLoading(true);
    try {
      await fetchFeedback({
        endpoint: 'merchant/update-cashback/all/',
        loaderType: "dialogueLoader",
        data: { cashback },
        requestType: "POST",
        onSuccess: () => {
          dispatch(setNextGold(cashback.gold));
          dispatch(setNextGoldRequiredSpend(cashback.gold_required_spend));
          dispatch(setNextGeneralCashbackRate(cashback.regular));
          setSuccess(true);
          setTimeout(() => {
            handleUpdateCashbackDialogClose();
          }, 1500);
        },
        onError: () => {
          setError("Failed to update cashback. Please try again.");
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNextButtonClick = () => {
    setError("");
    if (currentStep === 0 && cashback.regular < 2.5) {
      return setError("Regular Cashback should be greater than 2.5%");
    } else if (
      currentStep === 1 &&
      cashback.gold < calculateGoldPercentage(35)
    ) {
      return setError("Gold Cashback should be 35% more than Regular Cashback");
    }
    if (currentStep === 2) {
      submitCashbackDetailsApi();
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleUpdateCashbackDialogClose = () => {
    onClose();
    setCurrentStep(0);
    setError("");
    setSuccess(false);
    setCustomValues({ regular: 0, gold: 0 });
  };

  return (
    <Dialog.Root open={open} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay 
          className="fixed inset-0 bg-black/40 backdrop-blur-sm z-50"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
        <Dialog.Content 
          className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] 
                   w-[90vw] max-w-[900px] bg-white rounded-2xl shadow-2xl p-8 z-50"
        >
          {success ? (
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="flex flex-col items-center justify-center py-12"
            >
              <div className="w-16 h-16 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                <Check className="w-8 h-8 text-emerald-800" />
              </div>
              <h2 className="text-2xl font-bold text-gray-900">Changes Saved Successfully!</h2>
            </motion.div>
          ) : (
            <>
              <div className="absolute right-4 top-4">
                <Button variant="ghost" onClick={handleUpdateCashbackDialogClose}>
                  <X className="w-4 h-4" />
                </Button>
              </div>

              {currentStep < 2 && (
                <div className="mb-8">
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-emerald-800 transition-all duration-500"
                      style={{ width: `${currentStep === 0 ? 50 : 100}%` }}
                    />
                  </div>
                </div>
              )}

              <div className="mb-6">
                <motion.h2 
                  key={currentStep}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="text-3xl font-bold text-gray-900 mb-2"
                >
                  {currentStep === 0 ? "Set Regular Cashback" : 
                   currentStep === 1 ? "Set Gold Tier Cashback" : 
                   "Review Changes"}
                </motion.h2>
                {currentStep < 2 && (
                  <motion.p 
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="text-gray-500"
                  >
                    {currentStep === 0 
                      ? "Choose the base cashback rate for all customers" 
                      : "Set an enhanced rate for your gold tier members"}
                  </motion.p>
                )}
              </div>

              <AnimatePresence mode="wait">
                {currentStep < 2 ? (
                  <CashbackPlans
                    step={currentStep}
                    regularRate={cashback.regular}
                    currentStep={currentStep}
                    handleSelectCashback={handleSelectCashbackPlan}
                    cashback={cashback}
                    customValues={customValues}
                    setCustomValues={setCustomValues}
                    error={error}
                  />
                ) : (
                  <CashbackReview
                    cashback={cashback}
                    handleChangeThreshold={handleChangeThresholdValue}
                  />
                )}
              </AnimatePresence>

              {error && (
                <motion.p 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-red-500 mt-4 text-sm"
                >
                  {error}
                </motion.p>
              )}

              <div className="flex justify-between mt-8">
                {currentStep > 0 && (
                  <Button 
                    variant="secondary" 
                    onClick={() => setCurrentStep(prev => prev - 1)}
                    className="mr-4"
                  >
                    <ArrowLeft className="w-4 h-4" /> Back
                  </Button>
                )}
                <Button 
                  className="ml-auto"
                  onClick={handleNextButtonClick}
                  loading={loading}
                >
                  {currentStep === 2 ? "Confirm Changes" : "Continue"}
                  {!loading && <ArrowRight className="w-4 h-4" />}
                </Button>
              </div>
            </>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default UpdateCashbackDialog;