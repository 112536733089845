import React, { useState, useEffect } from 'react';
import {
  Menu,
  Home,
  Users,
  Settings,
  Bell,
  Search,
  MenuIcon,
  Wallet,
  LucideDollarSign,
  Gift,
  Store,
  Building,
} from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsPanel from './settings';

import SearchComponent from './search';

import ShoppingCartComponent from './shoppingCart';
import NotificationsComponent from './notifications';
import AccountManager from './status/main';
import CartPanel from './shopingCartPanel';
import { Storefront } from '@mui/icons-material';

const DashboardLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [settingsPanelOpen, setSettingsPanelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
    const [cartPanelOpen, setCartPanelOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Detect screen size to set mobile state
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 1324;
      setIsMobile(mobile);
      if (mobile) setSidebarOpen(false);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigation = (url) => {
    navigate(url);
  };

  const menuSections = [
    {
      title: 'Analytics',
      items: [{ icon: Home, label: 'Dashboard', path: '/dashboard' }],
    },
    {
      title: 'Financial',
      items: [
        { icon: Wallet, label: 'Transactions', path: '/transactions' },
        { icon: LucideDollarSign, label: 'Banking', path: '/bank' },
      ],
    },
    {
      title: 'Loyalty & Marketing',
      items: [
        { icon: Gift, label: 'Cashback', path: '/cashback' },
        // { icon: Bell, label: 'Boosts', path: '/boosts' },
      ],
    },
    {
      title: 'Store Management',
      items: [
        { icon: Store, label: 'POS Credentials', path: '/pos/register' },
        { 
            icon: Storefront, 
            label: 'Order Store Products', 
            path: '/dashboard/material/order' 
          },
        { icon: Building, label: 'Location Management', path: '/profile' },
      ],
    },
    {
      title: 'Account',
      items: [
        { icon: Users, label: 'Business Information', path: '/dashboard/kyc' },
      ],
    },
  ];

  // Function to check if a menu item is active
  const isItemActive = (itemPath) => {
    // Handle exact dashboard path
    if (itemPath === '/dashboard' && location.pathname === '/dashboard') {
      return true;
    }
    // Handle other paths
    return location.pathname.startsWith(itemPath) && itemPath !== '/dashboard';
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`sidebar fixed top-0 z-40 h-screen transform bg-white shadow-lg transition-all duration-200 ${
          isMobile
            ? `w-64 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`
            : `${sidebarOpen ? 'w-64' : 'w-16'}`
        }`}
      >
        <div className="flex h-16 items-center px-4 border-b">
          <span
            className={`text-xl font-semibold text-[#3EB489] transition-opacity duration-200 ${
              sidebarOpen ? 'opacity-100' : 'opacity-0'
            }`}
          >
            Clavaa
          </span>
        </div>

        <nav className="mt-6">
          {menuSections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6">
              <h3
                className={`mb-2 px-4 text-xs font-semibold uppercase tracking-wider text-gray-500 transition-opacity duration-200 ${
                  sidebarOpen ? 'opacity-100' : 'opacity-0'
                }`}
              >
                {section.title}
              </h3>
              {section.items.map((item, itemIndex) => (
                <a
                  key={itemIndex}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation(item.path);
                  }}
                  className={`mt-1 flex items-center px-4 py-2 text-sm transition-colors duration-200 ${
                    isItemActive(item.path)
                      ? 'bg-gray-200 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-100 active:bg-gray-200'
                  } ${!sidebarOpen ? 'justify-center' : ''}`}
                >
                  <item.icon
                    className={`h-5 w-5 ${isItemActive(item.path) ? 'text-gray-900' : ''}`}
                  />
                  <span
                    className={`ml-3 transition-opacity duration-200 ${
                      sidebarOpen ? 'opacity-100' : 'opacity-0 w-0'
                    }`}
                  >
                    {item.label}
                  </span>
                </a>
              ))}
            </div>
          ))}
        </nav>
      </aside>

      {/* Sidebar Overlay for Mobile */}
      {isMobile && sidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      {/* Main Content */}
      <div 
        className={`min-h-screen ${
          !isMobile && (sidebarOpen ? 'ml-64' : 'ml-16')
        }`}
      >
        <header className="sticky top-0 z-30 bg-white shadow w-full">
          <div className="flex h-16 items-center justify-between px-4">
            <div className="flex flex-1 items-center">
              <button
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                <MenuIcon className="h-6 w-6" />
              </button>
              <SearchComponent />
            </div>

            <div className="flex items-center space-x-6">
              <AccountManager />
              <ShoppingCartComponent  onButtonClick={() => setCartPanelOpen(!cartPanelOpen) }/>
              <NotificationsComponent />
              <button
                onClick={() => setSettingsPanelOpen(!settingsPanelOpen)}
                className="text-gray-600 hover:text-gray-900"
              >
                <Settings className="h-6 w-6" />
              </button>
            </div>
          </div>
        </header>

        <main className="p-4 md:p-6">
        <div className="min-h-screen">
        <div className="mx-auto max-w-[1600px] px-8 pb-12">
        <div className="p-3 sm:p-4 md:p-6">
            {children}
            </div>
            </div>
            </div>
            </main>
      </div>
{cartPanelOpen && (
      <CartPanel 
      isOpen={cartPanelOpen}
        onClose={() => setCartPanelOpen(false)}
      />
    )
}

      <SettingsPanel isOpen={settingsPanelOpen} onClose={() => setSettingsPanelOpen(false)} />
    </div>
  );
};

export default DashboardLayout;