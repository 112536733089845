// src/components/DialogManager.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Infopop from '../components/molecules/dialogues/infoNoIcon';
import TermsPop from '../components/molecules/dialogues/terms'; 
import SuccessDialog from '../components/molecules/dialogues/success';
import OnboardingModal from './welcome';
import { hideDialog } from '../features/dialog/dialogSlice';
import { 
  selectDialogMessage, 
  selectDialogTitle, 
  selectDialogSubTitle, 
  selectIsDialogOpen,
  selectGuideDialog // Renamed selector for clarity
} from '../features/dialog/dialogSelector';

const DialogManager = () => {
  const dispatch = useDispatch();
  
  // Retrieve dialog states
  const isInfoOpen = useSelector((state) => selectIsDialogOpen(state, 'info'));
  const infoMessage = useSelector((state) => selectDialogMessage(state, 'info'));
  const infoTitle = useSelector((state) => selectDialogTitle(state, 'info'));
  const infoSubTitle = useSelector((state) => selectDialogSubTitle(state, 'info'));

  const isTermsOpen = useSelector((state) => selectIsDialogOpen(state, 'terms'));
  const termsMessage = useSelector((state) => selectDialogMessage(state, 'terms'));
  const termsTitle = useSelector((state) => selectDialogTitle(state, 'terms'));
  const termsSubTitle = useSelector((state) => selectDialogSubTitle(state, 'terms'));

  const isSuccessOpen = useSelector((state) => selectIsDialogOpen(state, 'success'));
  const successMessage = useSelector((state) => selectDialogMessage(state, 'success'));

  // Guide (welcome) dialog
  const isGuideOpen = useSelector(selectGuideDialog);

  // Close handlers
  const handleInfoClose = () => dispatch(hideDialog('info'));
  const handleTermsClose = () => dispatch(hideDialog('terms'));
  const handleSuccessClose = () => dispatch(hideDialog('success'));
  const handleGuideClose = () => dispatch(hideDialog('guide'));

  return (
    <>
      {isInfoOpen && (
        <Infopop
          open={isInfoOpen}
          onClose={handleInfoClose}
          title={infoTitle}
          subTitle={infoSubTitle}
          info={infoMessage}
        />
      )}
      {isTermsOpen && (
        <TermsPop
          open={isTermsOpen}
          onClose={handleTermsClose}
          title={termsTitle}
          subTitle={termsSubTitle}
          content={termsMessage}
        />
      )}
      {isSuccessOpen && (
        <SuccessDialog
          open={isSuccessOpen}
          onClose={handleSuccessClose}
          message={successMessage}
        />
      )}
      {isGuideOpen && (
        <OnboardingModal
          open={isGuideOpen}
          onClose={handleGuideClose}
        />
      )}
    </>
  );
};

export default DialogManager;
