import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const VerificationSuccess = () => {
  const theme = useTheme();
  const primaryColor = '#3EB489';

  return (
    <Box
    sx={{
      border: `1px solid #E0E0E0`,
      borderRadius: '16px',
      padding: theme.spacing(4),
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-4px)',
      },
    }}
  >
    <Box textAlign="center">
      <CheckCircleIcon sx={{ fontSize: 50, color: primaryColor, marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Verification Complete
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(2) }}>
        Congratulations! Your information has been successfully verified.
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        Check your account status to see what else needs to be completed to start accepting payments through Clavaa.
      </Typography>
    </Box>
    </Box>
  );
};

export default VerificationSuccess;
