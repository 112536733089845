import React, { useState } from 'react';

export const ContactDetailsStep = ({ formData, setFormData, phoneOptions, websiteOptions }) => {
  const [localPhone, setLocalPhone] = useState(formData.number || '');
  const [localWebsite, setLocalWebsite] = useState(formData.website?.replace(/^https?:\/\/(www\.)?/, '') || '');
  const [websiteError, setWebsiteError] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const formatPhoneNumber = (input) => {
    const digits = input.replace(/\D/g, '');
    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  const isValidDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z]{2,})+$/;
    return domainRegex.test(domain);
  };

  const handlePhoneChange = (e) => {
    const rawValue = e.target.value;
    const formattedValue = formatPhoneNumber(rawValue);
    setLocalPhone(formattedValue);
    
    if (formattedValue.replace(/\D/g, '').length === 10) {
      setFormData({ ...formData, number: formattedValue });
    } else {
      setFormData({ ...formData, number: '' });
    }
  };

  const handleWebsiteChange = (e) => {
    let value = e.target.value.trim();
    value = value.replace(/^(https?:\/\/)?(www\.)?/, '');
    setLocalWebsite(value);

    if (!value) {
      setWebsiteError('');
      setFormData({ ...formData, website: '' });
      return;
    }

    if (isValidDomain(value)) {
      setWebsiteError('');
      setFormData({ ...formData, website: `https://${value}` });
    } else {
      setWebsiteError('Please enter a valid domain (e.g., example.com)');
      setFormData({ ...formData, website: '' });
    }
  };

  const handleWebsiteBlur = () => {
    setIsFocused(false);
    if (localWebsite && !isValidDomain(localWebsite)) {
      setWebsiteError('Please enter a valid domain (e.g., example.com)');
    }
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Contact Details</h2>
        <p className="text-sm text-gray-500">Enter your business contact information</p>
      </div>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <div>
            <input
              type="tel"
              value={localPhone}
              onChange={handlePhoneChange}
              className="w-full border border-gray-200 rounded-lg p-2.5 outline-none focus:ring-0 focus:border-[#0D4029]"
              placeholder="(555) 555-5555"
              list="phone-options"
            />
            <datalist id="phone-options">
              {phoneOptions?.map((option, index) => (
                <option key={index} value={option.value} />
              ))}
            </datalist>
            <p className="mt-1 text-xs text-gray-500">
              Enter a 10-digit phone number
            </p>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Website
          </label>
          <div className="relative flex items-stretch">
            <div className={`inline-flex items-center px-3 rounded-l-lg border border-r-0 border-gray-200 bg-gray-50 
              ${isFocused ? 'border-[#0D4029]' : ''}`}>
              <span className="text-gray-500 text-sm whitespace-nowrap">https://</span>
            </div>
            <input
              type="text"
              value={localWebsite}
              onChange={handleWebsiteChange}
              onBlur={handleWebsiteBlur}
              onFocus={() => setIsFocused(true)}
              className={`flex-1 border border-gray-200 rounded-r-lg p-2.5 outline-none focus:ring-0 focus:border-[#0D4029]
                ${websiteError ? 'border-red-300' : ''}`}
              placeholder="mywebsite.com"
              list="website-options"
              spellCheck="false"
              autoCapitalize="none"
            />
            <datalist id="website-options">
              {websiteOptions?.map((option, index) => (
                <option key={index} value={option.value?.replace(/^https?:\/\/(www\.)?/, '')} />
              ))}
            </datalist>
          </div>
          {websiteError ? (
            <p className="mt-1 text-xs text-red-500">
              {websiteError}
            </p>
          ) : (
            <p className="mt-1 text-xs text-gray-500">
              Enter your website domain (e.g., mywebsite.com)
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsStep;