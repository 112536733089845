import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader2, CheckCircle2, AlertTriangle, XCircle } from 'lucide-react';

import useFullFetchFeedback from '../../functions/apiFetchers/fullPageLoaders';
import { setStatus } from '../../features/user/userSlice';

const AccountStatusCheck = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatusState] = useState(null);
  const [hasChecked, setHasChecked] = useState(false);
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasChecked) return;

    const checkStatus = async () => {
      setHasChecked(true);
      setLoading(true);
      setError(null);

      try {
        const data = await new Promise((resolve, reject) => {
          fetchFeedback({
            endpoint: 'merchant/stripe/check/status/',
            requestType: 'GET',
            onSuccess: resolve,
            onError: reject,
          });
        });
        console.error(data);

        if (data.account_link.active) {
          dispatch(setStatus("complete"));
          setStatusState('verified');
        } else if (data.account_link.submitted) {
          dispatch(setStatus("disabled"));
          setStatusState('pending');
        } else {
          setStatusState('failed');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while checking the account status.');
        console.error('Error checking account status:', err);
      } finally {
        setLoading(false);
      }
    };

    checkStatus();
  }, [fetchFeedback, dispatch]);

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <div className="relative">
            <Loader2 className="w-8 h-8 text-[#0D4029] animate-spin" />
            <div className="absolute -bottom-1 -right-1 w-3 h-3">
              <span className="absolute inline-flex h-full w-full rounded-full bg-[#0D4029] opacity-75 animate-ping" />
              <span className="relative inline-flex rounded-full h-3 w-3 bg-[#0D4029]" />
            </div>
          </div>
          <div className="text-center">
            <h3 className="text-base font-semibold text-gray-900">
              Checking Account Status
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please wait while we verify your account...
            </p>
          </div>
        </>
      );
    }

    if (error) {
      return (
        <>
          <div className="rounded-full bg-red-50 p-2">
            <XCircle className="w-8 h-8 text-red-500" />
          </div>
          <div className="text-center">
            <h3 className="text-base font-semibold text-gray-900">
              Error Checking Status
            </h3>
            <p className="mt-1 text-sm text-red-500">
              {error}
            </p>
          </div>
          <button
            onClick={onClose}
            className="mt-4 w-full inline-flex justify-center items-center px-4 py-2 border border-[#0D4029] text-sm font-medium rounded-md text-[#0D4029] hover:bg-[#0D4029]/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0D4029] transition-colors"
          >
            Close
          </button>
        </>
      );
    }

    if (status === 'verified') {
      return (
        <>
          <div className="rounded-full bg-green-50 p-2">
            <CheckCircle2 className="w-8 h-8 text-[#0D4029]" />
          </div>
          <div className="text-center">
            <h3 className="text-base font-semibold text-gray-900">
              Account Verified
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Congratulations! Your account has been successfully verified.
            </p>
          </div>
          <button
            onClick={onClose}
            className="mt-4 w-full inline-flex justify-center items-center px-4 py-2 bg-[#0D4029] text-white text-sm font-medium rounded-md hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0D4029] transition-colors"
          >
            Continue
          </button>
        </>
      );
    }

    if (status === 'pending') {
      return (
        <>
          <div className="rounded-full bg-yellow-50 p-2">
            <AlertTriangle className="w-8 h-8 text-yellow-600" />
          </div>
          <div className="text-center">
            <h3 className="text-base font-semibold text-gray-900">
              Verification Required
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please verify your information to activate your account.
            </p>
          </div>
          <div className="mt-4 p-3 bg-yellow-50 rounded-md">
            <ul className="text-xs text-yellow-700 list-disc list-inside space-y-1">
              <li>Complete all required verification steps</li>
              <li>Ensure all provided information is accurate</li>
              <li>Submit any missing documentation</li>
            </ul>
          </div>
          <button
            onClick={onClose}
            className="mt-4 w-full inline-flex justify-center items-center px-4 py-2 border border-[#0D4029] text-sm font-medium rounded-md text-[#0D4029] hover:bg-[#0D4029]/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0D4029] transition-colors"
          >
            Close
          </button>
        </>
      );
    }

    if (status === 'failed') {
      return (
        <>
          <div className="rounded-full bg-red-50 p-2">
            <XCircle className="w-8 h-8 text-red-500" />
          </div>
          <div className="text-center">
            <h3 className="text-base font-semibold text-gray-900">
              Verification Incomplete
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              We couldn't verify your account. Please complete the verification process.
            </p>
          </div>
          <div className="mt-4 p-3 bg-red-50 rounded-md">
            <ul className="text-xs text-red-700 list-disc list-inside space-y-1">
              <li>Complete all required information</li>
              <li>Provide necessary documentation</li>
              <li>Ensure details are accurate and up-to-date</li>
            </ul>
          </div>
          <button
            onClick={onClose}
            className="mt-4 w-full inline-flex justify-center items-center px-4 py-2 border border-[#0D4029] text-sm font-medium rounded-md text-[#0D4029] hover:bg-[#0D4029]/5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0D4029] transition-colors"
          >
            Close
          </button>
        </>
      );
    }

    return null;
  };

  if (!loading && !error && !status) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
      <div 
        className="relative bg-white rounded-lg shadow-lg w-full max-w-sm mx-4 transform transition-all"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 py-6">
          <div className="flex flex-col items-center justify-center gap-4">
            {renderContent()}
          </div>
        </div>

        {/* Decorative Elements */}
        <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-[#0D4029]/20 to-transparent" />
        <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-[#0D4029]/20 to-transparent" />
        <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-[#0D4029]/20 to-transparent" />
        <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-[#0D4029]/20 to-transparent" />
      </div>
    </div>
  );
};

export default AccountStatusCheck;