import { Check } from "lucide-react";

export const SuccessStep = () => {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-center">
        <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
          <Check className="w-6 h-6 text-green-600" />
        </div>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">Location created successfully!</h2>
        <p className="text-gray-500">Your business location has been added.</p>
      </div>
    );
  };