import React, { useState } from "react";
import {
  ConnectComponentsProvider,
  ConnectPaymentDetails,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";

const ManageContent = ({data, onClose, invoiceId}) => {
  const fetchFeedback = useFullFetchFeedback();
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      try {
        const sessionData = await new Promise((resolve, reject) => {
          fetchFeedback({
            endpoint: "merchant/stripe/create/payments/token/",
            requestType: "GET",
            onSuccess: (data) => resolve(data),
            onError: (error) => reject(error),
          });
        });

        if (sessionData?.client_secret) {
          return sessionData.client_secret;
        } else {
          console.error("Client secret not found in session data.");
          return undefined;
        }
      } catch (error) {
        console.error("Failed to fetch client secret:", error);
        return undefined;
      }
    };

    return loadConnectAndInitialize({
      publishableKey: "pk_live_51QFSOAP1SGiwmmH3YlolMRKcLk2ddVxZZFwvW8wa4yFyk8ebTHk5PvjtVLV0IGVdigGEOJbPXpxavpP6bqrAXWgb00DPC8CDx8",
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: "#3da843",
        },
      },
    });
  });

  return (
    <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white border border-gray-100 shadow-xl p-6">
        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-medium text-gray-900">
              Manage Invoice
            </h2>
            <p className="text-sm text-gray-500 mt-1">#{invoiceId}</p>
          </div>

          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectPaymentDetails 
              payment={data.stripe_id}
              onClose={onClose}
            />
          </ConnectComponentsProvider>

          <button
            onClick={onClose}
            className="w-full text-xs font-medium text-gray-500 hover:text-gray-700 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageContent;