import React from 'react';
import { ArrowLeft, Info } from 'lucide-react';

const PageHeader = ({
  title,
  description,
  onBackClick,
  backArrow,
  infoText,
  onButtonClick,
  action
}) => {
  return (
    <div className="w-full mb-6">
      <div className="flex items-start justify-between w-full min-w-0">
        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-3">
            {backArrow && (
              <button
                onClick={onBackClick}
                className="flex-shrink-0 p-1.5 -ml-1.5 text-gray-500 hover:text-gray-900 transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
            )}
            <h1 className="text-2xl font-semibold text-gray-900 truncate">{title}</h1>
          </div>
          
          {description && (
            <p className="mt-1.5 text-sm text-gray-600 max-w-3xl">{description}</p>
          )}
        </div>

        {(infoText || action) && (
          <div className="flex items-center gap-3 ml-4 flex-shrink-0 mt-8">
            {infoText && (
              <button
                onClick={onButtonClick}
                className="inline-flex items-center px-3.5 h-8 text-sm font-medium bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:border-gray-400 active:bg-gray-100 transition-colors"
              >
                <Info className="w-4 h-4 mr-1.5 text-gray-500" />
                <span className="text-gray-700">{infoText}</span>
              </button>
            )}
            {action && (
              <div className="flex-shrink-0">
                {action}
              </div>
            )}
          </div>
        )}
      </div>
      
      <div className="mt-4 h-px w-full bg-gray-200" />
    </div>
  );
};

export default PageHeader;