import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AccountStatusComponent from './status';
import AccountStatusCard from './AccountStatusCard'; // Import the new AccountStatusCard component
import SetupStepsModal from './modal';
import { selectActivationStatus, selectUserStatus } from '../../../../../../../features/user/userSelectors';
import { selectBankData } from '../../../../../../../features/banking/bankSelectors';

import { selectCurrentCashback } from '../../../../../../../features/cashback/cashbackSelectors';
import { selectAllStoreLocations } from '../../../../../../../features/stores/storesSelectors';
import { setActivationStatus } from '../../../../../../../features/user/userSlice';
import useFullFetchFeedback from '../../../../../../../functions/apiFetchers/fullPageLoaders';

const routeMapping = [
  '/dashboard/kyc', // Step 1: Business Details Verified (KYC)

  '/profile',       // Step 3: Setup Location


];

const AccountManager = ({ useEnhancedStatusCard = false }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();

  // Fetch data from Redux selectors
  const activationStatus = useSelector(selectActivationStatus);
  const userkycStatus = useSelector(selectUserStatus);
  const bankData = useSelector(selectBankData);
  
  const currentCashback = useSelector(selectCurrentCashback);
  const storeLocations = useSelector(selectAllStoreLocations);

  // Determine the current statuses for the steps
  const hasBankAccount = !!(bankData && bankData.accountNumber);

  const isCashbackSet = parseFloat(currentCashback) > 0;
  const hasVerifiedLocations = storeLocations.some(location => location.status === 'Active');
  const accountActive = activationStatus === 'activated';
  const kycstatus = userkycStatus === 'complete';

  // Statuses for the steps
  const stepStatuses = {
    kyc: kycstatus,
 
   

    locationVerified: hasVerifiedLocations,
  };

  // Handle modal opening
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Handle modal closing
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Handle step navigation
  const handleCompleteStep = (stepIndex) => {
    const route = routeMapping[stepIndex];
    if (route) {
      navigate(route);
    } else {
      console.warn(`No route defined for step ${stepIndex + 1}`);
    }
  };

  // Handle account activation
  const handleActivateAccount = async () => {
    setActivationLoading(true); // Start loading

    await fetchFeedback({
      endpoint: `merchant/activate-account/`, // Activation endpoint
      requestType: 'GET', // Assuming GET request, adjust as necessary
      onSuccess: (responseData) => {
        console.log('Account activated successfully:', responseData);
        dispatch(setActivationStatus(true));
        setActivationLoading(false); // Stop loading after success
      },
      onError: (error) => {
        console.error('Account activation failed:', error);
        setActivationLoading(false); // Stop loading after failure
      },
    });
  };

  return (
    <>
      {useEnhancedStatusCard ? (
        <AccountStatusCard
          accountStatus={accountActive ? 'Active' : 'Pending'}
          onPress={handleOpenModal}
        />
      ) : (
        <AccountStatusComponent onPress={handleOpenModal} status={accountActive ? 'Active' : 'Pending'} />
      )}

      <SetupStepsModal
        open={modalOpen}
        onClose={handleCloseModal}
        onComplete={handleCompleteStep}
        accountActive={accountActive} // Activation status
        stepStatuses={stepStatuses} // Pass the step statuses to the modal
        onActivateAccount={handleActivateAccount} // Activation handler
        activationLoading={activationLoading} // Loading state for activation
      />
    </>
  );
};

export default AccountManager;
