import React from 'react';

function CodeLoading() {
  return (
    <div className="flex flex-col items-center justify-center w-full p-8 space-y-8">
      {/* Primary Loading Animation */}
      <div className="relative w-16 h-16">
        {/* Outer rotating ring */}
        <div className="absolute inset-0 border-4 border-[#0D4029]/10 rounded-full" />
        <div className="absolute inset-0 border-4 border-[#0D4029] rounded-full border-t-transparent animate-spin" 
             style={{ animationDuration: '1s', animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)' }} />
        
        {/* Center dot */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-2 h-2 bg-[#0D4029] rounded-full" />
        </div>
      </div>

      {/* Status Message */}
      <div className="space-y-2 text-center">
        <h2 
          className="text-lg font-medium text-gray-900"
          style={{ 
            fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif',
          }}
        >
          Verifying Code
        </h2>
        <p className="text-sm text-gray-500">
          This will only take a moment
        </p>
      </div>

      {/* Progress Indicator */}
      <div className="w-full max-w-[200px] h-1 bg-[#0D4029]/10 rounded-full overflow-hidden">
        <div 
          className="h-full bg-[#0D4029] rounded-full animate-[loading_2s_ease-in-out_infinite]"
          style={{
            '@keyframes loading': {
              '0%': { transform: 'translateX(-100%)' },
              '50%': { transform: 'translateX(100%)' },
              '100%': { transform: 'translateX(-100%)' }
            }
          }}
        />
      </div>
    </div>
  );
}

export default CodeLoading;