import React, { useState } from 'react';
import { Plus, AlertCircle, SearchX } from 'lucide-react';
import PageHeader from 'pages/locationNew/header';
import BoostCard from './boostCard';
import SearchAndFilter from './SearchAndFilter';
import NoBoostsState from './NoBoostsState';
import NoSearchResultsState from './NoSearchResultsState';
import CreateBoostModal from './create-boostModal';
import BoostExplanationModal from './explainedModal';

const BoostDashboard = () => {
  const [expandedBoosts, setExpandedBoosts] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'active-scheduled',
    customerType: 'all',
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExplainedModalOpen, setIsExplainedModalOpen] = useState(false);

  const [boosts, setBoosts] = useState([
    {
      id: 1,
      name: "New Customer Welcome",
      targetGroup: "Never shopped",
      minSpend: 50,
      cashbackAmount: 10,
      duration: "30 days",
      status: "active",
      redemptions: 124,
      totalSpent: 6200,
      startDate: "Mar 1, 2024",
      endDate: "Mar 31, 2024"
    },
    {
      id: 2,
      name: "Win-back Campaign",
      targetGroup: "Inactive 30+ days",
      minSpend: 75,
      cashbackAmount: 15,
      duration: "14 days",
      status: "scheduled",
      redemptions: 20,
      totalSpent: 500,
      startDate: "Apr 1, 2024",
      endDate: "Apr 14, 2024"
    }
  ]);

  const toggleExpand = (id) => {
    setExpandedBoosts((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(id)) {
        newExpanded.delete(id);
      } else {
        newExpanded.add(id);
      }
      return newExpanded;
    });
  };

  const deleteBoost = (id) => {
    if (window.confirm('Are you sure you want to delete this boost? This action cannot be undone.')) {
      setBoosts((prev) => prev.filter((boost) => boost.id !== id));
    }
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const filteredBoosts = boosts.filter((boost) => {
    return (
      (filters.status === 'all' ? true : 
       filters.status === 'active-scheduled' ? 
       (boost.status === 'active' || boost.status === 'scheduled') : 
       boost.status === filters.status) &&
      (filters.customerType === 'all' || boost.targetGroup === filters.customerType) &&
      (searchTerm === '' || boost.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const addBoostClick = () => {
    setIsCreateModalOpen(true);
  }

  return (
    <>
    <div className="min-h-screen flex flex-col">
      <PageHeader
        title="Boosts"
        description="Create targeted cashback offers to attract and retain customers"
        action={
          <button
            onClick={addBoostClick}
            className="inline-flex items-center px-4 py-2 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
          >
            Create Boost
            <Plus className="w-4 h-4 ml-2" />
          </button>
        }
      />

      <div className="px-6 max-w-[1600px] mx-auto w-full flex-1">
        <SearchAndFilter
          onSearchChange={handleSearchChange}
          filters={[
            {
              key: 'status',
              value: filters.status,
              options: [
                { label: 'Active & scheduled', value: 'active-scheduled' },
                { label: 'Active', value: 'active' },
                { label: 'Scheduled', value: 'scheduled' },
                { label: 'Ended', value: 'ended' },
                { label: 'All statuses', value: 'all' },
              ],
            },
            {
              key: 'customerType',
              value: filters.customerType,
              options: [
                { label: 'All customer types', value: 'all' },
                { label: 'New customers', value: 'new' },
                { label: 'Returning customers', value: 'returning' },
                { label: 'Gold members', value: 'gold' },
              ],
            },
          ]}
          onFilterChange={handleFilterChange}
        />

        <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 mb-8">
          {filteredBoosts.map((boost) => (
            <BoostCard
              key={boost.id}
              boost={boost}
              isExpanded={expandedBoosts.has(boost.id)}
              onToggle={toggleExpand}
              onDelete={deleteBoost}
            />
          ))}
        </div>

        {boosts.length === 0 ? (
          <NoBoostsState addBoostClick={addBoostClick}/>
        ) : filteredBoosts.length === 0 ? (
          <NoSearchResultsState setFilters={setFilters} setSearchTerm={setSearchTerm} addBoostClick={addBoostClick}/>
        ) : null}

        <div className="mt-auto border-t border-gray-200 pt-6 pb-8">
          <button 
            onClick={() => console.log('Learn about boosts')}
            className="text-sm text-gray-600 hover:text-gray-900 transition-colors flex items-center gap-2"
            onClick={ () => setIsExplainedModalOpen(true)}
          >
            <AlertCircle className="w-4 h-4" />
            Understand how boosts work
          </button>
        </div>
      </div>
    </div>
{isCreateModalOpen && 

    <CreateBoostModal
  isOpen={isCreateModalOpen}
  onClose={() => setIsCreateModalOpen(false)}
/>}

{isExplainedModalOpen &&
<BoostExplanationModal
isOpen={isExplainedModalOpen}
onClose={() => { setIsExplainedModalOpen(false);  }}

 />
}

    </>
  );
};

export default BoostDashboard;