import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';

const SuccessDialog = ({ open, message, onClose }) => {
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      PaperProps={{
        style: {
          padding: '30px',
          borderRadius: '20px',
          textAlign: 'center',
        },
        elevation: 0,
      }}
    >
      <DialogContent>
        <motion.div
          initial={{ scale: 0.7, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        >
          <CheckCircleIcon style={{ fontSize: '80px', color: '#3EB489' }} />
        </motion.div>
        <Typography variant="h5" style={{ marginTop: '20px' }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginTop: '20px' }}>
        <Button
          onClick={onClose}
          variant="contained"
          style={{
            backgroundColor: '#3EB489',
            color: '#FFFFFF',
            padding: '10px 40px',
            borderRadius: '30px',
            textTransform: 'none',
            fontSize: '16px',
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
