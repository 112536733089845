import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

const RequestDocumentation = ({ onUploadDocs }) => {
  const theme = useTheme();
  const primaryColor = '#3EB489';

  return (
    <Box
      sx={{
        maxWidth: 500,
        width: "100%",
        mx: "auto",
        textAlign: "center",
      }}
    >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <DescriptionIcon sx={{ fontSize: 50, color: primaryColor, marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Additional Documentation Required
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        To complete your verification, we need some additional documentation. Please upload the requested documents to proceed.
      </Typography>
      <Button
        variant="contained"
        onClick={onUploadDocs}
        sx={{
          backgroundColor: primaryColor,
          color: '#FFFFFF',
          borderRadius: '30px',
          paddingX: theme.spacing(4),
          paddingY: theme.spacing(1.5),
          textTransform: 'none',
          fontWeight: '500',
          '&:hover': {
            backgroundColor: '#17b563',
          },
        }}
      >
        Get started
      </Button>
    </Box>
    </Box>

    
    
  );
};

export default RequestDocumentation;
