import React from "react";

import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";

import Dashboard from "../trial/Default"
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";
import { Box } from "@mui/material";
import DashboardLayout from "sideBar/main";
const DashboardPage = () => {
  return (
    <DashboardLayout>

<Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <PageHeader
          title={"Dashboard and analytics"}
          description={
            "Understand how your Clavaa loyalty program is performing."
          }
          backArrow={false}
         
        
        />
      <Dashboard />
      </Box>
    </DashboardLayout>
  );
};

export default DashboardPage;
