import React, { useEffect, useState } from 'react';

function Success({ onGoToDashboard }) {
  const [show, setShow] = useState(false);
  
  // Trigger entrance animation
  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-md mx-auto p-6 sm:p-8">
      {/* Success Icon with Animation */}
      <div 
        className={`relative transform transition-all duration-700 ease-out ${
          show ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
        }`}
      >
        <div className="relative">
          {/* Background Pulse Effect */}
          <div className="absolute inset-0 bg-[#0D4029]/5 rounded-full animate-[pulse_2s_infinite] scale-150" />
          <div className="absolute inset-0 bg-[#0D4029]/10 rounded-full animate-[pulse_2s_infinite_500ms] scale-125" />
          
          {/* Success Checkmark */}
          <div className="relative w-20 h-20 rounded-full bg-[#0D4029] flex items-center justify-center">
            <svg 
              className={`w-10 h-10 text-white transform transition-all duration-700 ease-out ${
                show ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
              }`}
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor"
              strokeWidth="3"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Success Message */}
      <div 
        className={`mt-8 text-center transform transition-all duration-700 delay-300 ease-out ${
          show ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
        }`}
      >
        <h1 
          className="text-2xl font-semibold text-gray-900 mb-3"
          style={{ fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif' }}
        >
          Success! Account Created
        </h1>
        <p className="text-gray-600 text-base mb-8 leading-relaxed max-w-sm">
          A sales representative will reach out shortly to help you set up your account. If you have any questions, feel free to contact us at{' '}
          <a 
            href="mailto:help@clavaa.com" 
            className="text-[#0D4029] font-medium hover:text-[#0D4029]/80 transition-colors"
          >
            help@clavaa.com
          </a>
        </p>
      </div>

      {/* Action Button */}
      <div 
        className={`w-full max-w-xs transform transition-all duration-700 delay-500 ease-out ${
          show ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
        }`}
      >
        <button
          onClick={onGoToDashboard}
          className="w-full bg-[#0D4029] text-white rounded-lg py-3 px-6 font-medium
                   hover:bg-[#0D4029]/90 active:bg-[#0D4029]/95 
                   transform transition-all duration-200 
                   hover:scale-[1.02] active:scale-[0.98]
                   focus:outline-none focus:ring-2 focus:ring-[#0D4029]/20
                   shadow-lg hover:shadow-xl active:shadow-md"
          style={{ fontFamily: 'SF Pro Text, -apple-system, BlinkMacSystemFont, sans-serif' }}
        >
          Go to Dashboard
        </button>

        {/* Optional: Add subtle loading dots while dashboard prep happens */}
        <p className="text-center text-sm text-gray-500 mt-4 opacity-80">
          Preparing your dashboard...
        </p>
      </div>
    </div>
  );
}

// Add these keyframe animations to your global CSS
const style = document.createElement('style');
style.textContent = `
  @keyframes pulse {
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(1.5); opacity: 0; }
    100% { transform: scale(1); opacity: 0; }
  }
`;
document.head.appendChild(style);

export default Success;