import React, { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import useFullFetchFeedback from '../../functions/apiFetchers/fullPageLoaders'; // Assuming fetchFeedback is here
import { showAlert } from '../../features/alerts/alertSlice';

const StripeLinkRedirect = () => {
  const [error, setError] = useState(null);
  const fetchFeedback = useFullFetchFeedback(); // Assuming this is the fetch wrapper
  const dispatch = useDispatch();

  useEffect(() => {
    let hasFetched = false;

    const fetchNewStripeLink = async () => {
      if (hasFetched) return;
      hasFetched = true;

      try {
        const data = await new Promise((resolve, reject) => {
          fetchFeedback({
            endpoint: 'merchant/stripe/create/link/',
            requestType: 'GET',
            onSuccess: resolve,
            onError: reject,
          });
        });

        if (data.account_link?.url) {
          window.location.href = data.account_link.url;
          return;
        } else {
          throw new Error('Stripe link not available.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching the Stripe link.');
        console.error('Error fetching Stripe link:', err);
        dispatch(showAlert({ message: 'Error fetching Stripe link', severity: 'error' }));
      }
    };

    fetchNewStripeLink();
  }, [fetchFeedback, dispatch]);

  return (
    <Modal open={!error}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: 300,
        }}
      >
        {!error ? (
          <>
            <CircularProgress />
            <Typography variant="body1">Fetching a new Stripe link...</Typography>
          </>
        ) : (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default StripeLinkRedirect;