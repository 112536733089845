import React from 'react';


export const Input = ({ value, onChange, error, className = '' }) => (
  <div className="w-20 h-20 bg-emerald-800/10 rounded-full flex items-center justify-center">
    <input
      type="number" 
      value={value}
      onChange={onChange}
      className={`
        w-16 text-center text-2xl font-bold text-emerald-800
        bg-transparent focus:outline-none
        transition-all duration-300
        [-moz-appearance:textfield]
        [&::-webkit-outer-spin-button]:appearance-none
        [&::-webkit-inner-spin-button]:appearance-none
        placeholder-emerald-800/50
        ${error ? 'text-red-500' : ''}
        ${className}
      `}
      placeholder="%"
    />
  </div>
);
