import React, { useState, useEffect, useReducer } from 'react';
import { X, MapPin, PenLine, Check, ShoppingBag, ArrowRight } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllItems } from 'features/cart/cartSelector';
import { showAlert } from 'features/alerts/alertSlice';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';

// Form reducer to handle all form state changes
const formReducer = (state, action) => {
  switch (action.type) {
    case 'updateField':
      return { ...state, [action.field]: action.value };
    case 'reset':
      return action.initialState;
    default:
      return state;
  }
};

// Separate the form into its own component
const AddressForm = ({ onSubmit, initialAddress = {} }) => {
  const [formState, dispatch] = useReducer(formReducer, {
    street: '',
    unit: '',
    city: '',
    state: '',
    zip: '',
    ...initialAddress
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'updateField', field: name, value });
  };

  const isComplete = () => {
    const required = ['street', 'city', 'state', 'zip'];
    return required.every(field => formState[field].trim());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formState);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div className="relative">
          <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
          <input
            type="text"
            name="street"
            value={formState.street}
            onChange={handleChange}
            className="w-full pl-10 pr-4 py-3.5 border border-gray-200 rounded-xl outline-none focus:border-[#0D4029]"
            placeholder="Street Address"
          />
        </div>

        <input
          type="text"
          name="unit"
          value={formState.unit}
          onChange={handleChange}
          className="w-full px-4 py-3.5 border border-gray-200 rounded-xl outline-none focus:border-[#0D4029]"
          placeholder="Apt, Suite, Unit (Optional)"
        />

        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-3">
            <input
              type="text"
              name="city"
              value={formState.city}
              onChange={handleChange}
              className="w-full px-4 py-3.5 border border-gray-200 rounded-xl outline-none focus:border-[#0D4029]"
              placeholder="City"
            />
          </div>
          <div className="col-span-1">
            <input
              type="text"
              name="state"
              value={formState.state}
              onChange={handleChange}
              className="w-full px-4 py-3.5 border border-gray-200 rounded-xl outline-none focus:border-[#0D4029]"
              placeholder="State"
            />
          </div>
          <div className="col-span-2">
            <input
              type="text"
              name="zip"
              value={formState.zip}
              onChange={handleChange}
              className="w-full px-4 py-3.5 border border-gray-200 rounded-xl outline-none focus:border-[#0D4029]"
              placeholder="ZIP Code"
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={!isComplete()}
        className="w-full bg-[#0D4029] text-white py-4 rounded-xl hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-[#0D4029] disabled:transform-none flex items-center justify-center group"
      >
        <span>Review Order</span>
        <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-0.5 transition-transform" />
      </button>
    </form>
  );
};

const CheckoutModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState('address');
  const [cartItems, setCartItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(null);   
  const [address, setAddress] = useState({});
  const allItems = useSelector(selectAllItems);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const fetchFeedback = useFullFetchFeedback();

  useEffect(() => {
    const loadCart = () => {
      const savedCart = JSON.parse(localStorage.getItem('clavaaCart') || '{}');
      const items = Object.entries(savedCart)
        .map(([id, quantity]) => {
          const item = allItems[id];
          return item ? { ...item, quantity } : null;
        })
        .filter(Boolean);
      setCartItems(items);
    };

    loadCart();
  }, [allItems]);

  const handleAddressSubmit = (addressData) => {
    setAddress(addressData);
    setStep('review');
  };

  const handlePlaceOrder = async () => {
    setIsSubmitting(true);
    setError(null);
    
    const product_ids_and_quantity = cartItems.reduce((acc, item) => {
      acc[item.id] = item.quantity;
      return acc;
    }, {});

    try {
      await fetchFeedback({
        endpoint: 'merchant/order/place',
        requestType: 'POST',
        data: {
          product_ids_and_quantity,
          shipping_address: {
            street: address.street,
            unit: address.unit || null,
            city: address.city,
            state: address.state,
            zip: address.zip
          }
        },
        onSuccess: (responseData) => {
          localStorage.setItem('clavaaCart', '{}');
          setCartItems([]);
          setStep('confirmed');
          setConfirmationCode(responseData.order_id);
        },
        onError: (error) => {
          console.error(error);
          setError("Failed to place order. Please try again.");
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const ReviewStep = () => (
    <div className="space-y-6">
      <div className="bg-gray-50 rounded-xl p-4 transition-all hover:bg-gray-100">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-gray-900">Shipping To</h3>
          <button
            onClick={() => setStep('address')}
            className="text-[#0D4029] hover:text-[#0D4029]/80 text-sm font-medium flex items-center gap-1.5 transition-colors"
          >
            <PenLine className="w-4 h-4" />
            <span>Edit</span>
          </button>
        </div>
        <div className="text-sm text-gray-600 space-y-1">
          <p>{address.street} {address.unit}</p>
          <p>{address.city}, {address.state} {address.zip}</p>
        </div>
      </div>

      <div>
        <h3 className="text-sm font-medium text-gray-900 mb-4">Order Summary</h3>
        <div className="space-y-4">
          {cartItems.map((item) => (
            <div key={item.id} className="flex justify-between items-center p-2 -mx-2 rounded-lg transition-colors hover:bg-gray-50">
              <div className="flex items-center gap-4">
                <div className="w-16 h-16 rounded-lg bg-gray-100 overflow-hidden">
                  <img 
                    src={item.image} 
                    alt={item.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <p className="font-medium text-gray-900">{item.name}</p>
                  <p className="text-sm text-gray-500">Qty: {item.quantity}</p>
                </div>
              </div>
              <p className="font-medium text-gray-900">${(item.price * item.quantity).toFixed(2)}</p>
            </div>
          ))}
        </div>

        <div className="border-t pt-4 mt-6">
          <div className="flex justify-between items-center">
            <p className="text-lg font-semibold text-gray-900">Total</p>
            <p className="text-lg font-semibold text-gray-900">
              ${cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0).toFixed(2)}
            </p>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-xl text-sm mb-4">
          {error}
        </div>
      )}
      
      <button
        onClick={handlePlaceOrder}
        disabled={isSubmitting}
        className="w-full bg-[#0D4029] text-white py-4 rounded-xl hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
      >
        {isSubmitting ? (
          <>
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            <span>Processing Order...</span>
          </>
        ) : (
          <>
            <span>Place Order</span>
            <Check className="w-5 h-5" />
          </>
        )}
      </button>
    </div>
  );

  const ConfirmedStep = () => (
    <div className="py-8 px-4">
      <div className="w-20 h-20 bg-[#0D4029]/10 rounded-full flex items-center justify-center mx-auto mb-6 animate-appear">
        <Check className="w-10 h-10 text-[#0D4029]" />
      </div>
      
      <div className="text-center space-y-2 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900">Order Confirmed!</h3>
        <p className="text-gray-600">
          Order #{confirmationCode} has been placed successfully
        </p>
      </div>

      <div className="bg-gray-50 rounded-xl p-4 mb-8">
        <div className="flex items-center gap-3 text-sm text-gray-600">
          <ShoppingBag className="w-5 h-5 text-[#0D4029]" />
          <span>Your order confirmation and details have been sent to your email</span>
        </div>
      </div>

      <button
        onClick={onClose}
        className="w-full bg-[#0D4029] text-white py-4 rounded-xl hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] font-medium"
      >
        Done
      </button>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 animate-fadeIn">
      <div className="bg-white rounded-2xl max-w-xl w-full max-h-[90vh] overflow-y-auto animate-slideUp">
        <div className="px-6 py-4 border-b border-gray-100 flex items-center justify-between sticky top-0 bg-white/95 backdrop-blur-sm z-10">
          <h2 className="text-xl font-semibold text-gray-900">
            {step === 'address' ? 'Shipping Address' : 
             step === 'review' ? 'Review Order' : 
             'Order Confirmation'}
          </h2>
          {step !== 'confirmed' && (
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors p-1.5 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5" />
            </button>
          )}
        </div>

        <div className="p-6">
          {step === 'address' && (
            <AddressForm 
              onSubmit={handleAddressSubmit} 
              initialAddress={address}
            />
          )}
          {step === 'review' && <ReviewStep />}
          {step === 'confirmed' && <ConfirmedStep />}
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;