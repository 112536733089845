import React from 'react';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

export const Button = ({ children, variant = 'primary', onClick, className = '', loading = false }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    disabled={loading}
    className={`
      px-6 py-3 rounded-lg font-medium flex items-center gap-2
      transition-all duration-300 disabled:opacity-50
      ${variant === 'primary' ? 'bg-emerald-800 text-white hover:bg-emerald-900' : 
        variant === 'secondary' ? 'bg-gray-100 text-gray-700 hover:bg-gray-200' :
        'text-gray-700 hover:bg-gray-100'}
      ${className}
    `}
    onClick={onClick}
  >
    {loading ? <Loader2 className="w-4 h-4 animate-spin" /> : children}
  </motion.button>
);