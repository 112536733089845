import React from 'react';
import { Loader2 } from 'lucide-react';

const Loader = ({ 
  text = 'Loading...',
  fullScreen = true,
  size = 'default'  // 'small' | 'default' | 'large'
}) => {
  // Size mappings for the loader icon
  const sizeClasses = {
    small: 'w-4 h-4',
    default: 'w-8 h-8',
    large: 'w-12 h-12'
  };

  // Base component with loader and text
  const LoaderContent = () => (
    <div className="flex flex-col items-center justify-center">
      <Loader2 
        className={`text-[#0D4029] animate-spin ${sizeClasses[size]}`}
      />
      {text && (
        <p className="mt-3 text-[#0D4029] font-medium text-sm">
          {text}
        </p>
      )}
    </div>
  );

  // If fullScreen is true, render with overlay
  if (fullScreen) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-90 flex items-center justify-center z-50">
        <LoaderContent />
      </div>
    );
  }

  // Otherwise render inline
  return <LoaderContent />;
};

export default Loader;