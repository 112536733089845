import React, { useState, useEffect, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HelpCenterPage from "./main";
import ArticlePage from "./ArticlePage";

const MainPageHelpCenter = () => {
  const { articleId } = useParams(); // Extract articleId from the URL
  const navigate = useNavigate(); // Use for navigation
  const [currentArticle, setCurrentArticle] = useState(null);
  const [loading, setLoading] = useState(false);

  // Dynamically load an article based on the articleId
  const loadArticle = async (id) => {
    console.log(`Loading article "${id}"...`);
    setLoading(true);
    try {
      const articleModule = await import(`./content/${id}.js`);
      if (articleModule.default) {
        setCurrentArticle(articleModule.default);
      } else {
        throw new Error(`No default export found for "${id}"`);
      }
    } catch (error) {
      console.error(`Failed to load article "${id}":`, error);
      setCurrentArticle({
        id: "error",
        title: "Article Not Found",
        sections: [
          {
            id: "error-message",
            type: "text",
            title: "Oops!",
            content: "The requested article could not be found. Please try another one.",
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  // Return to the Help Center
  const returnToHelpCenter = () => {
    setCurrentArticle(null);
    navigate("/help/center");
  };

  // Load the article when articleId changes
  useEffect(() => {
    if (articleId) {
      loadArticle(articleId);
    } else {
      setCurrentArticle(null); // Clear article when no articleId is present
    }
  }, [articleId]);

  return (
    <div className="min-h-screen">
      <Suspense fallback={<div>Loading...</div>}>
        {loading ? (
          <div>Loading article...</div>
        ) : currentArticle ? (
          <ArticlePage article={currentArticle} onBack={returnToHelpCenter} />
        ) : (
          <HelpCenterPage
            onArticleSelect={(id) => navigate(`/help/center/${id}`)} // Navigate to the dynamic route
          />
        )}
      </Suspense>
    </div>
  );
};

export default MainPageHelpCenter;