import React from "react";
import TransactionsContainer from "../organisms/TransactionsContainer";
import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import DashboardLayout from "sideBar/main";

const TransactionsPage = () => {
  return (
    <DashboardLayout>
      <TransactionsContainer />
    </DashboardLayout>
  );
};

export default TransactionsPage;
