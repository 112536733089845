import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard/kyc", { state: { wasRedirected: true } });
  }, [navigate]);

  return <p>Redirecting...</p>;
};

export default RedirectingPage;