import React from "react";
import DashboardLayout from "sideBar/main";

import MaterialsPage from "./mainInside";


const OrderMaterialPage = ({
 
}) => {
  return (
    <>
    
<DashboardLayout>
<MaterialsPage />

</DashboardLayout>

    </>
  );
};

export default OrderMaterialPage;
