import React from 'react';

export const BusinessDescriptionStep = ({ formData, setFormData }) => {
  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Business Description</h2>
        <p className="text-sm text-gray-500">Tell customers about your business</p>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={formData.description}
            className="w-full border border-gray-200 rounded-lg p-2.5 outline-none focus:ring-0 focus:border-[#0D4029]"
            placeholder="Enter business description"
            rows={4}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessDescriptionStep;