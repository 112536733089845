import React from "react";
import DashboardLayout from "sideBar/main";
import POSCredentials from "./mainInside";
import { selectEmployees, selectRegisterData } from "features/register/registerSelectors";
import { useDispatch, useSelector } from "react-redux";
import useFullFetchFeedback from "functions/apiFetchers/fullPageLoaders";
import { deleteEmployee } from "features/register/registerSlice";
import { add } from "lodash";
import EmployeeModal from "./addEmployeeModal";
import UpdateCredentialsModal from "./updateModal";



const RegisterPageNew = () => {  
    const [employeeModalOpen, setEmployeeModalOpen] = React.useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = React.useState(false);


    const registerData = useSelector(selectRegisterData);
    const employees = useSelector(selectEmployees);
    const fetchFeedback = useFullFetchFeedback();
    const dispatch = useDispatch();


   

    
    
    return (
        <>
        <DashboardLayout>

<POSCredentials
credentials={registerData}
handleOpenResetPasswordDialog={() => setOpenResetPasswordDialog(true)}
employees={employees}
employeeModalOpen={ () => setEmployeeModalOpen(true)}


/>
         
        </DashboardLayout>

     <EmployeeModal
        isOpen={employeeModalOpen}
        onClose={() => setEmployeeModalOpen(false)}
        />

        <UpdateCredentialsModal
        isOpen={openResetPasswordDialog}
        onClose={() => setOpenResetPasswordDialog(false)}
        />
        </>
    );
}

export default RegisterPageNew;