import React from "react";
import DashboardLayout from "sideBar/main";
import LocationsManagement from "./register";
import { data } from "autoprefixer";
import useFetchFeedback from "functions/apiFetchers/fetchFeedback";
import BoostDashboard from "./boosts/mainInside";


const DevelopmentPageUI = ({
 
}) => {


  const fetchFeedback = useFetchFeedback();


   const handleAPlaceOrder = async ({product_ids_and_quantity, shipping_address}) => {
    
  
      await fetchFeedback({
        endpoint: `merchant/order/place`, // Activation endpoint
        requestType: 'POST', // Assuming GET request, adjust as necessary
        data: {product_ids_and_quantity: product_ids_and_quantity, shipping_address: shipping_address},

        onSuccess: (responseData) => {
         console.log(responseData.order_id)
        },
        onError: (error) => {
        
        },
      });
    };


  return (
    <>
    
<DashboardLayout>
<BoostDashboard />

</DashboardLayout>

    </>
  );
};

export default DevelopmentPageUI;
