import React, { useState, useEffect } from 'react';
import { X, Building2, Loader2, CheckCircle } from 'lucide-react';

const successKeyframes = `
  @keyframes ping {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(2); opacity: 0; }
  }
  @keyframes scale {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
`;

if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.textContent = successKeyframes;
  document.head.appendChild(style);
}

const DeleteModal = ({ 
  open,
  onClose, 
  onConfirm,
  deleteConfirmation,
  deleteLoading
}) => {
  const [isClosing, setIsClosing] = useState(false);
  
  const isSuccess = deleteConfirmation;
  const isLoading = deleteLoading;

  useEffect(() => {
    if (open) {
      setIsClosing(false);
    }
  }, [open]);

  const handleDelete = async () => {
    if (onConfirm) {
      await onConfirm();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsClosing(true);
        setTimeout(handleClose, 300);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  const SuccessState = () => (
    <div className="flex flex-col items-center justify-center py-8">
      <div className="relative mb-4">
        <div className="absolute inset-0 animate-[ping_1s_ease-out_1]">
          <div className="h-20 w-20 rounded-full bg-green-500/10" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.75s_1]">
          <div className="h-20 w-20 rounded-full bg-green-500/20" />
        </div>
        <div className="relative flex h-20 w-20 items-center justify-center rounded-full bg-green-100 animate-[scale_0.3s_ease-out_0.2s_both]">
          <CheckCircle className="h-12 w-12 text-green-600 animate-[scale_0.2s_ease-out_0.4s_both]" />
        </div>
      </div>
      <div className="space-y-1 text-center animate-[fadeIn_0.5s_ease-out_0.6s_both]">
        <h3 className="text-xl font-semibold text-gray-900">
          Location Removed
        </h3>
      </div>
    </div>
  );

  const LoadingState = () => (
    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90">
      <div className="flex flex-col items-center space-y-4">
        <div className="relative">
          <div className="h-16 w-16 rounded-full border-4 border-red-500/20 opacity-75 animate-ping" />
          <Loader2 className="absolute inset-0 h-16 w-16 text-red-500 animate-spin" />
        </div>
      </div>
    </div>
  );

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/40 backdrop-blur-sm transition-opacity" onClick={handleClose} />
      
      <div className={`relative w-full max-w-md transform transition-all duration-300 ${
        isClosing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
      }`}>
        <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
          {!isSuccess && (
            <div className="relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-b from-red-500/5 to-transparent" />
              <div className="px-6 pt-12 pb-8">
                <div className="flex justify-center mb-8">
                  <div className="relative">
                    <div className="absolute inset-0 animate-[pulse_2s_ease-in-out_infinite]">
                      <div className="h-24 w-24 rounded-full bg-red-500/10" />
                    </div>
                    <div className="relative flex h-24 w-24 items-center justify-center rounded-full bg-red-100">
                      <Building2 className="h-12 w-12 text-red-600" />
                    </div>
                  </div>
                </div>
                
                <div className="text-center space-y-2">
                  <h2 className="text-2xl font-semibold text-gray-900">Delete Location?</h2>
                  <p className="text-sm text-gray-500">
                    This location will be removed from your account and the Discover page
                  </p>
                </div>
              </div>
            </div>
          )}

          {isSuccess ? (
            <SuccessState />
          ) : (
            <div className="border-t border-gray-200 bg-white px-6 py-4 rounded-b-xl">
              <div className="flex space-x-3">
                <button
                  onClick={handleClose}
                  className="flex-1 rounded-lg border border-gray-300 px-4 py-3 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  disabled={isLoading}
                  className="flex-1 rounded-lg bg-red-600 px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-red-700 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  <span className="flex items-center justify-center">
                    Delete Location
                  </span>
                </button>
              </div>
            </div>
          )}

          {!isSuccess && !isLoading && (
            <button
              onClick={handleClose}
              className="absolute right-4 top-4 rounded-full p-1 text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          )}

          {isLoading && <LoadingState />}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;