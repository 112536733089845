import { useDispatch } from "react-redux";
import { showDialog } from "../../features/dialog/dialogSlice";
import {
  activateLoader,
  deactivateLoader,
} from "../../features/loading/loadingSlice";

/**
 * Custom hook to handle any action with loading, success, and error feedback.
 *
 * @returns {Function} A function to perform an action with feedback handling.
 *
 * Usage:
 * const fetchFeedback = useFetchFeedback();
 * fetchFeedback({
 *   action: () => { /* perform some action * / },
 *   loaderType: 'dialogueLoader',
 *   loaderMessage: 'Processing...',
 *   successMessage: 'Action successful!',
 *   onSuccess: (response) => { (response); },
 *   onError: (error) => { console.error(error); },
 *   validate: (data) => { return {}; }, // validation function
 *   setError: (errors) => { (errors); }, // function to handle validation errors
 *   data: {}, // data to be validated
 * });
 */
const useFetchFeedback = () => {
  const dispatch = useDispatch();

  return async ({
    action,
    loaderType = "dialogueLoader",
    loaderMessage,
    successMessage,
    onSuccess = () => {},
    onError = () => {},
    validate = () => ({}),
    setError = () => {},
    data = {},
  }) => {
    // Perform validation
    const errors = validate(data);
    console.log(errors)
    if (Object.keys(errors).length !== 0) {
      console.log(errors)
    
      setError(errors);
      return;
    }

    if (loaderMessage) {
      dispatch(activateLoader({ loaderType, message: loaderMessage }));
    }

    try {
      const response = await action();

      if (loaderMessage) {
        dispatch(deactivateLoader(loaderType));
      }

      if (successMessage) {
        dispatch(
          showDialog({ dialogType: "success", message: successMessage })
        );
      }

      onSuccess(response);
    } catch (error) {
      console.error(error);

      if (loaderMessage) {
        dispatch(deactivateLoader(loaderType));
      }

     
      onError(error);
    }
  };
};

export default useFetchFeedback;
