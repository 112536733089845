import React, { useEffect } from 'react';
import { Loader2 } from 'lucide-react';

const LoaderDialog = ({ 
  open = false, 
  message = '', 
  description = '',
  onClose = null 
}) => {
  // Prevent body scroll when dialog is open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialog-title"
    >
      {/* Backdrop with Square's signature fade */}
      <div 
        className="fixed inset-0 bg-black/40 backdrop-blur-sm transition-opacity duration-300"
        aria-hidden="true"
      />
      
      {/* Dialog Positioning */}
      <div className="flex min-h-full items-center justify-center p-4">
        {/* Dialog Content */}
        <div 
          className="relative transform overflow-hidden rounded-xl bg-white p-8 text-center shadow-xl transition-all duration-200 ease-out w-full max-w-md"
          style={{
            boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)'
          }}
        >
          {/* Loader Icon with Square's signature animation */}
          <div className="relative mx-auto h-12 w-12">
            <Loader2 
              className="absolute inset-0 h-full w-full text-[#0D4029] animate-spin" 
              style={{ 
                animationDuration: '1.5s',
                animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)'
              }} 
            />
            <div className="absolute inset-0 h-full w-full animate-pulse bg-[#0D4029]/5 rounded-full" />
          </div>
          
          {/* Message with Square's Typography */}
          {message && (
            <div className="mt-6">
              <h3 
                id="dialog-title"
                className="text-lg font-semibold text-gray-900 tracking-tight"
              >
                {message}
              </h3>
              {description && (
                <p className="mt-2 text-sm text-gray-600">
                  {description}
                </p>
              )}
            </div>
          )}
          
          {/* Close Button with Square's signature styling */}
          {onClose && (
            <button
              onClick={onClose}
              className="mt-6 w-full px-4 py-2.5 bg-[#0D4029] text-white text-sm font-medium rounded-lg 
                       hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2
                       transition-all duration-200 ease-out
                       disabled:opacity-50 disabled:cursor-not-allowed
                       active:transform active:scale-[0.98]"
            >
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoaderDialog;