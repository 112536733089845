import React, { useState } from "react";
import { Box } from "@mui/material";
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";

import {
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import DashboardLayout from "sideBar/main";

const BankPageStripe = () => {
  const fetchFeedback = useFullFetchFeedback(); // Move the hook to the top level
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      try {
        const sessionData = await new Promise((resolve, reject) => {
          fetchFeedback({
            endpoint: "merchant/stripe/create/bank/token/",
            requestType: "GET",
            onSuccess: (data) => resolve(data),
            onError: (error) => reject(error),
          });
        });

        if (sessionData?.client_secret) {
          return sessionData.client_secret;
        } else {
          console.error("Client secret not found in session data.");
          return undefined;
        }
      } catch (error) {
        console.error("Failed to fetch client secret:", error);
        return undefined;
      }
    };

    // Initialize Stripe Connect instance
    return loadConnectAndInitialize({
      publishableKey: "pk_live_51QFSOAP1SGiwmmH3YlolMRKcLk2ddVxZZFwvW8wa4yFyk8ebTHk5PvjtVLV0IGVdigGEOJbPXpxavpP6bqrAXWgb00DPC8CDx8",
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          // Core Colors - Minimal & Professional
          colorPrimary: "#0D4029",
          colorText: "#1C2024",
          
          // Typography - Clean & Professional
          fontFamily: "Inter, -apple-system, system-ui, sans-serif",
          fontSizeBase: "14px",
          spacingUnit: "8px",
          borderRadius: "8px",
      
          // Button Styling - Refined
          buttonPrimaryColorBackground: "#0D4029",
          buttonPrimaryColorText: "#FFFFFF",
          buttonPrimaryColorBorder: "#0D4029",
          
          buttonSecondaryColorBackground: "transparent",
          buttonSecondaryColorText: "#1C2024",
          buttonSecondaryColorBorder: "#D9DDE1",
      
          // Interactive Elements
          actionPrimaryColorText: "#0D4029",
          actionPrimaryTextDecorationLine: "none",
          
          // Form Elements - Subtle
          formBorderRadius: "6px",
          formHighlightColorBorder: "#0D4029",
          
          // Badge Styling - Understated
          badgeNeutralColorBackground: "#F7F8F9",
          badgeNeutralColorText: "#1C2024",
          badgeNeutralColorBorder: "#E5E7EB",
          
          // Text Hierarchy
          colorSecondaryText: "#4B5563",
          
          // Borders & Dividers
          colorBorder: "#E5E7EB",
          
          // Component Specific
          buttonBorderRadius: "6px",
          overlayBorderRadius: "8px",
          
          // Typography Scale
          bodyMdFontSize: "14px",
          bodyMdFontWeight: "450",
          bodySmFontSize: "13px",
          bodySmFontWeight: "450",
          
          headingLgFontSize: "18px",
          headingLgFontWeight: "600",
          headingMdFontSize: "16px",
          headingMdFontWeight: "600",
          headingSmFontSize: "14px",
          headingSmFontWeight: "600",
          
          // Form Accents
          formAccentColor: "#0D4029"
        },
      }
    });
  });

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <PageHeader
          title="Banking"
          description="Manage your bank accounts and pending funds."
          backArrow={false}
        />
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayouts />
        </ConnectComponentsProvider>
      </Box>
    </DashboardLayout>
  );
};

export default BankPageStripe;