import React, { useState } from 'react';
import { X, AlertCircle, CheckCircle, Loader2, ShieldCheck, Key, Users, RefreshCw } from 'lucide-react';

// Keyframes defined outside component
const successKeyframes = `
  @keyframes ping {
    0% { transform: scale(1); opacity: 1; }
    100% { transform: scale(2); opacity: 0; }
  }
  @keyframes scale {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  @keyframes slideUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

// Add styles to document head
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.textContent = successKeyframes;
  document.head.appendChild(style);
}

const CredentialsModal = ({
  isOpen,
  onClose,
  isLoading = false,
  isSuccess = false,
  isError = false,
  errorMessage = '',
  isAllEmployees = false,
  employeeName = '',
  onSend = () => {},
}) => {
  const [selectedFeatures, setSelectedFeatures] = useState(['pos', 'pin']);

  if (!isOpen) return null;

  const FeatureCard = ({ icon: Icon, title, description, id }) => {
    const isSelected = selectedFeatures.includes(id);
    
    const toggleFeature = () => {
      if (isSelected) {
        setSelectedFeatures(selectedFeatures.filter(f => f !== id));
      } else {
        setSelectedFeatures([...selectedFeatures, id]);
      }
    };

    return (
      <div
        onClick={toggleFeature}
        className={`group relative overflow-hidden rounded-lg border p-4 transition-all duration-300 cursor-pointer ${
          isSelected 
            ? 'border-[#0D4029] bg-[#0D4029]/5 ring-2 ring-[#0D4029] ring-opacity-20' 
            : 'border-gray-200 hover:border-[#0D4029] hover:bg-gray-50'
        }`}
      >
        <div className="flex items-start space-x-3">
          <div className={`rounded-lg p-2 transition-colors duration-300 ${
            isSelected ? 'bg-[#0D4029]' : 'bg-gray-100 group-hover:bg-[#0D4029]/10'
          }`}>
            <Icon className={`h-5 w-5 transition-colors duration-300 ${
              isSelected ? 'text-white' : 'text-gray-600 group-hover:text-[#0D4029]'
            }`} />
          </div>
          <div className="flex-1">
            <h3 className={`text-sm font-medium transition-colors duration-300 ${
              isSelected ? 'text-[#0D4029]' : 'text-gray-700'
            }`}>
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>
          <div className={`ml-2 h-5 w-5 rounded-full border-2 transition-all duration-300 ${
            isSelected 
              ? 'border-[#0D4029] bg-[#0D4029]' 
              : 'border-gray-300 group-hover:border-[#0D4029]'
          }`}>
            {isSelected && (
              <CheckCircle className="h-4 w-4 text-white" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <div className="relative mb-6">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="h-16 w-16 rounded-full border-4 border-[#0D4029]/20 opacity-75 animate-ping" />
        </div>
        <Loader2 className="h-12 w-12 text-[#0D4029] animate-spin" />
      </div>
      <h3 className="mb-2 text-lg font-medium text-gray-900">
        {isAllEmployees ? 'Sending credentials to all employees' : `Sending credentials to ${employeeName}`}
      </h3>
      <p className="text-sm text-gray-500">This may take a few moments...</p>
    </div>
  );

  const SuccessState = () => (
    <div className="flex flex-col items-center justify-center py-6">
      <div className="relative mb-4">
        <div className="absolute inset-0 animate-[ping_1s_ease-out_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/10" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.75s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/20" />
        </div>
        <div className="absolute inset-0 animate-[ping_1s_ease-out_0.5s_1]">
          <div className="h-16 w-16 rounded-full bg-green-500/30" />
        </div>
        <div className="relative flex h-16 w-16 items-center justify-center rounded-full bg-green-100 animate-[scale_0.3s_ease-out_0.2s_both]">
          <CheckCircle className="h-10 w-10 text-green-600 animate-[scale_0.2s_ease-out_0.4s_both]" />
        </div>
      </div>
      <div className="space-y-1 text-center animate-[fadeIn_0.5s_ease-out_0.6s_both]">
        <h3 className="text-lg font-semibold text-gray-900">
          {isAllEmployees 
            ? 'Credentials successfully sent!' 
            : `Credentials sent to ${employeeName}`}
        </h3>
        <p className="text-sm text-gray-500">
          Access instructions have been sent via email
        </p>
      </div>
      <div className="mt-4 w-full animate-[slideUp_0.3s_ease-out_0.8s_both]">
        <button
          onClick={onClose}
          className="w-full rounded-lg bg-[#0D4029] px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-[#0D4029]/90 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
        >
          Done
        </button>
      </div>
    </div>
  );

  const ErrorState = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <div className="mb-6 rounded-full bg-red-100 p-4">
        <AlertCircle className="h-12 w-12 text-red-600" />
      </div>
      <h3 className="mb-2 text-lg font-medium text-gray-900">Unable to send credentials</h3>
      <p className="mb-8 text-center text-sm text-gray-500 max-w-sm">
        {errorMessage || 'An error occurred while sending credentials. Please try again.'}
      </p>
      <div className="flex w-full space-x-3">
        <button
          onClick={onClose}
          className="flex-1 rounded-lg border border-gray-300 px-4 py-2.5 text-sm font-medium text-gray-700 transition-colors hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
        >
          Cancel
        </button>
        <button
          onClick={onSend}
          className="flex-1 rounded-lg bg-[#0D4029] px-4 py-2.5 text-sm font-medium text-white transition-colors hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
        >
          Try Again
        </button>
      </div>
    </div>
  );

  const DefaultState = () => (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        {/* Info Alert */}
        <div className="mb-6 rounded-lg bg-[#0D4029]/5 p-4">
          <div className="flex items-start">
            <ShieldCheck className="h-5 w-5 text-[#0D4029]" />
            <p className="ml-3 text-sm text-[#0D4029]">
              {isAllEmployees 
                ? 'You are about to send secure credentials to all employees' 
                : `You are about to send secure credentials to ${employeeName}`}
            </p>
          </div>
        </div>

        {/* Feature Selection */}
        <div className="space-y-3">
          <FeatureCard
            id="pos"
            icon={Key}
            title="POS System Access"
            description="Secure credentials for the point-of-sale system"
          />
          
          <FeatureCard
            id="pin"
            icon={RefreshCw}
            title="Refund Authorization"
            description="Unique PIN for processing customer refunds"
          />

          {isAllEmployees && (
            <FeatureCard
              id="bulk"
              icon={Users}
              title="Bulk Distribution"
              description="Send credentials to all active employees"
            />
          )}
        </div>

        <div className="mt-6 rounded-md bg-gray-50 p-4">
          <p className="text-sm text-gray-600">
            Selected employees will receive an email with their access credentials and setup instructions.
          </p>
        </div>
      </div>


    </div>
  );

  const getModalContent = () => {
    if (isLoading) return <LoadingState />;
    if (isSuccess) return <SuccessState />;
    if (isError) return <ErrorState />;
    return <DefaultState />;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/30 transition-opacity" onClick={onClose} />
      
      <div className="relative w-full max-w-md bg-white shadow-xl flex flex-col rounded-lg">
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-900">
            {isAllEmployees ? 'Send Credentials - All Employees' : 'Send Credentials'}
          </h2>
          <button
            onClick={onClose}
            className="rounded-full p-1 text-gray-500 hover:bg-gray-100"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto px-6 py-4 max-h-[calc(100vh-16rem)]">
          {getModalContent()}
        </div>

       
      </div>
    </div>
  );
};

export default CredentialsModal;