import { Loader } from "lucide-react";

export const ProcessingStep = () => {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-center">
        <Loader className="w-12 h-12 text-[#0D4029] animate-spin" />
        <h2 className="text-xl font-semibold text-gray-900 mt-4 mb-2">Creating your location...</h2>
        <p className="text-gray-500">Please wait while we set up your business location.</p>
      </div>
    );
  };