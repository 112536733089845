import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/molecules/pageHeader/PageHeader";
import AccountStatusCheck from "./statusChecking";
import AccountOnboardingStripe from "./stripe_account_onboarding_comonenet";
import AccountStripe from "./stripe_account_comonenet";
import { selectUserStatus } from "../../features/user/userSelectors";

const BusinessInformation = () => {
  const userStatus = useSelector(selectUserStatus);
  const location = useLocation();
  const wasRedirected = location.state?.wasRedirected;
  const [showVerify, setShowVerify] = useState(wasRedirected);
  const [showOnboarding, setShowOnboarding] = useState(true);

  const handleExit = () => {
    setShowVerify(false);
    // Reset the onboarding component by toggling visibility
    setShowOnboarding(false);
    setTimeout(() => {
      setShowOnboarding(true);
    }, 0);
  };

  return (
    <>
      {showVerify && <AccountStatusCheck onClose={handleExit} />}

      <PageHeader
        title={"Business Information"}
        description={
          "Manage your business verifications and settings here."
        }
        backArrow={false}
      />

      {userStatus === "inactive"  &&  showOnboarding && (
        <AccountOnboardingStripe onExit={() => setShowVerify(true)} />
      )}

      {userStatus != "inactive" && <AccountStripe />}
    </>
  );
};

export default BusinessInformation;