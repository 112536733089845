import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Search, Book, Users, CreditCard, BarChart, Settings, HelpCircle, ChevronRight } from "lucide-react";

const categories = [
    {
      title: "Getting Started",
      icon: Book,
      description: "Learn the basics of setting up and using your Clavaa dashboard",
      articles: [
        {
          title: "Quick Start Guide",
          id: "quick-start-guide",
          relevance: 10,
          keywords: [
            "start", "quick start", "beginner", "setup", "getting started", 
            "initial setup", "onboarding", "first steps", "Clavaa basics", 
            "how to start", "new user guide", "introduction", 
            "how to use Clavaa", "Clavaa for beginners", 
            "simple guide", "quick guide", "easy setup", "tutorial"
          ],
        },
        {
          title: "Dashboard Overview",
          id: "dashboard-overview",
          relevance: 9,
          keywords: [
            "dashboard", "overview", "home page", "main menu", "features", 
            "navigation", "analytics dashboard", "Clavaa interface", 
            "key features", "getting familiar", "using dashboard", 
            "explore dashboard", "dashboard insights", "dashboard tools", 
            "merchant tools", "user dashboard", "business analytics"
          ],
        },
        {
          title: "Setting Up Your Account",
          id: "setting-up-your-account",
          relevance: 8,
          keywords: [
            "account", "setup", "create account", "configuration", 
            "account creation", "getting started", "user account", 
            "step-by-step guide", "setting preferences", "Clavaa setup", 
            "account activation", "signing up", "user registration", 
            "business account", "personal account", "new user setup", 
            "starting with Clavaa"
          ],
        },
        {
          title: "How Hard is the Clavaa Setup",
          id: "how-hard-is-clavaa-setup",
          relevance: 7,
          keywords: [
            "setup", "difficulty", "easy setup", "configuration", 
            "setup process", "effort required", "user-friendly setup", 
            "simple steps", "Clavaa onboarding", "configuration overview", 
            "how easy is Clavaa", "Clavaa for beginners", 
            "is Clavaa hard to use", "setup difficulty level"
          ],
        },
      ],
    },
    {
      title: "Loyalty Program",
      icon: Users,
      description: "Manage and customize your loyalty program settings",
      articles: [
        {
          title: "Creating Reward Tiers",
          id: "creating-reward-tiers",
          relevance: 9,
          keywords: [
            "loyalty", "rewards", "tiers", "levels", "program", "reward structure", 
            "incentives", "tier creation", "loyalty customization", 
            "customer engagement", "loyalty strategy", "program design", 
            "reward levels", "how to create reward tiers", 
            "Clavaa loyalty setup", "customer rewards setup", 
            "advanced loyalty features"
          ],
        },
        {
          title: "Setting Cashback Rates",
          id: "setting-cashback-rates",
          relevance: 8,
          keywords: [
            "cashback", "rates", "loyalty", "program", "incentive rates", 
            "reward rates", "customer retention", "cashback program", 
            "loyalty benefits", "Clavaa cashback", "setting rates", 
            "reward customization", "cashback percentage", "adjusting rates", 
            "reward program configuration", "maximizing rewards"
          ],
        },
        {
          title: "Competing with Co-Brand Cards",
          id: "competing-with-big-brands",
          relevance: 7,
          keywords: [
            "competition", "co-brand", "cards", "strategy", 
            "big brands", "competitive analysis", "market strategy", 
            "loyalty differentiation", "value proposition", "customer attraction", 
            "compete with big brands", "Clavaa vs big cards", 
            "market competition", "local business loyalty strategy"
          ],
        },
        {
          title: "Program Analytics",
          id: "program-analytics",
          relevance: 6,
          keywords: [
            "analytics", "program", "loyalty", "tracking", "metrics", 
            "program performance", "data insights", "program success", 
            "customer behavior", "data-driven decisions", "dashboard analytics", 
            "program statistics", "loyalty program data", 
            "reward tracking", "program optimization"
          ],
        },
      ],
    },
    {
      title: "Payments",
      icon: CreditCard,
      description: "Everything about processing payments and managing transactions",
      articles: [
        {
          title: "Payment Processing Guide",
          id: "payment-processing-guide",
          relevance: 10,
          keywords: [
            "payments", "processing", "guide", "transactions", 
            "payment systems", "Clavaa payments", "ACH transfers", 
            "payment methods", "merchant transactions", "processing fees", 
            "payment workflow", "financial transactions", 
            "how to process payments", "Clavaa transaction guide", 
            "merchant payment processing", "seamless transactions"
          ],
        },
        {
          title: "Integrating Clavaa with Your System",
          id: "integrating-with-your-system",
          relevance: 9,
          keywords: [
            "integration", "system", "setup", "Clavaa", "system configuration", 
            "merchant tools", "API integration", "business systems", 
            "compatibility", "setup guide", "custom integration", 
            "connecting Clavaa", "system synchronization", 
            "merchant system setup", "streamlining integrations"
          ],
        },
        {
          title: "Refund Process",
          id: "refund-process",
          relevance: 8,
          keywords: [
            "refunds", "process", "payments", "transactions", "refund policy", 
            "payment reversals", "customer refunds", "Clavaa refunds", 
            "refund handling", "dispute resolution", "merchant support", 
            "how to process refunds", "refund requests", "Clavaa refund guide", 
            "resolving customer refunds", "transaction reversals"
          ],
        },
        {
          title: "Fee Structure",
          id: "fee-structure",
          relevance: 7,
          keywords: [
            "fees", "structure", "payments", "cost", "pricing", 
            "transaction fees", "payment costs", "Clavaa pricing", 
            "merchant costs", "fee breakdown", "service charges", 
            "Clavaa fee guide", "understanding costs", "fee transparency", 
            "Clavaa payment fees", "transaction cost overview"
          ],
        },
      ],
    },
    {
      title: "Analytics & Reports",
      icon: BarChart,
      description: "Understanding your data and generating insights",
      articles: [
        {
          title: "Dashboard Metrics Explained",
          id: "dashboard-metrics-explained",
          relevance: 10,
          keywords: [
            "metrics", "dashboard", "analytics", "insights", 
            "performance data", "data visualization", "business metrics", 
            "key performance indicators", "KPIs", "Clavaa analytics", 
            "understanding metrics", "dashboard insights", 
            "reporting tools", "metrics explanation", "business data tracking"
          ],
        },
        {
          title: "Custom Report Creation",
          id: "custom-report-creation",
          relevance: 9,
          keywords: [
            "reports", "custom", "creation", "analytics", "data insights", 
            "customized reporting", "Clavaa reports", "business insights", 
            "data customization", "advanced reporting", 
            "create custom reports", "Clavaa report guide", 
            "business analytics", "report generation tools", 
            "insightful reporting"
          ],
        },
        {
          title: "Data Export Guide",
          id: "data-export-guide",
          relevance: 8,
          keywords: [
            "data", "export", "guide", "reports", "file export", 
            "CSV export", "analytics export", "Clavaa data", 
            "exporting insights", "data tools", "how to export data", 
            "report exports", "analytics file download", 
            "Clavaa export tutorial", "data sharing tools"
          ],
        },
        {
          title: "Performance Tracking",
          id: "performance-tracking",
          relevance: 7,
          keywords: [
            "tracking", "performance", "metrics", "analytics", 
            "business performance", "tracking tools", "Clavaa insights", 
            "real-time data", "progress tracking", "performance overview", 
            "track metrics", "business tracking tools", 
            "performance optimization", "business insights", "tracking data"
          ],
        },
      ],
    },
    {
      title: "Boosts",
      icon: Settings,
      description: "How Cashback Boosts work and how to set them up",
      articles: [
        {
          title: "Boost Configuration",
          id: "boost-configuration",
          relevance: 9,
          keywords: [
            "boosts", "configuration", "setup", "cashback", "Clavaa boosts", 
            "boost options", "custom boost setup", "reward enhancements", 
            "cashback customization", "boost settings", 
            "how to set up boosts", "reward optimization", 
            "boost strategy", "cashback promotions", 
            "Clavaa rewards boost"
          ],
        },
        {
          title: "Boost Performance Tracking",
          id: "boost-performance-tracking",
          relevance: 8,
          keywords: [
            "boosts", "performance", "tracking", "analytics", 
            "program performance", "Clavaa tracking", "boost metrics", 
            "customer engagement", "boost insights", "reward monitoring", 
            "boost analytics tools", "performance data", 
            "tracking cashback boosts", "Clavaa performance guide", 
            "reward engagement metrics"
          ],
        },
        {
          title: "Boost Analytics",
          id: "boost-analytics",
          relevance: 7,
          keywords: [
            "analytics", "boosts", "insights", "tracking", 
            "data metrics", "reward data", "boost program", 
            "Clavaa analytics", "reward insights", "boost performance", 
            "understanding boosts", "cashback program insights", 
            "Clavaa rewards data", "boost visualization", 
            "cashback metrics tracking"
          ],
        },
        {
          title: "Boost Optimization",
          id: "boost-optimization",
          relevance: 6,
          keywords: [
            "optimization", "boosts", "strategy", "setup", 
            "reward optimization", "Clavaa tools", "boost success", 
            "engagement strategy", "cashback tools", "program design", 
            "maximizing boosts", "improving reward programs", 
            "Clavaa boost strategy", "boost setup optimization", 
            "enhancing rewards"
          ],
        },
      ],
    },
  ];
  
  const popularArticles = [
    {
      title: "How to Set Up Your First Loyalty Program",
      id: "set-up-first-loyalty-program",
      relevance: 10,
      keywords: [
        "loyalty", "setup", "program", "rewards", "first loyalty program", 
        "new user loyalty", "Clavaa loyalty", "program design", 
        "reward setup", "customer rewards", "engagement strategy", 
        "how to set up loyalty", "Clavaa loyalty guide", 
        "reward creation", "first time loyalty setup", 
        "step-by-step loyalty setup"
      ],
    },
    {
      title: "Understanding Transaction Fees",
      id: "understanding-transaction-fees",
      relevance: 9,
      keywords: [
        "fees", "transactions", "costs", "payments", "pricing structure", 
        "Clavaa fees", "service charges", "merchant costs", 
        "transaction pricing", "cost transparency", "financial overview", 
        "understanding Clavaa fees", "payment fees guide", 
        "transaction cost breakdown", "merchant pricing structure"
      ],
    },
    {
      title: "Customizing Customer Rewards",
      id: "customizing-customer-rewards",
      relevance: 8,
      keywords: [
        "rewards", "customization", "loyalty", "program", "customer rewards", 
        "reward settings", "reward personalization", "Clavaa loyalty", 
        "reward tiers", "program design", "customer incentives", 
        "how to customize rewards", "loyalty reward setup", 
        "Clavaa program customization", "reward configuration tools"
      ],
    },
    {
      title: "Exporting Analytics Reports",
      id: "exporting-analytics-reports",
      relevance: 7,
      keywords: [
        "export", "analytics", "reports", "data", "data export", 
        "report generation", "Clavaa insights", "CSV export", 
        "file export", "performance data", "business insights", 
        "how to export analytics", "Clavaa data export", 
        "report file downloads", "exporting business data"
      ],
    },
  ];
const SearchComponent = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const inputRef = useRef(null);
    const suggestionsRef = useRef(null);
    const navigate = useNavigate();
  
    const allArticles = [
      ...categories.flatMap((category) =>
        category.articles.map((article) => ({
          ...article,
          category: category.title,
          description: category.description,
        }))
      ),
      ...popularArticles.map((article) => ({
        ...article,
        category: "Popular Articles",
        description: "Frequently accessed articles",
      })),
    ];
  
    const suggestions = allArticles
      .filter((article) =>
        [article.title, ...article.keywords].some((keyword) =>
          keyword.toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => b.relevance - a.relevance);
  
    const handleSuggestionClick = (id) => {
      setSearchTerm("");
      setShowSuggestions(false);
      navigate(`/help/center/${id}`);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setFocusedIndex(prev => 
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setFocusedIndex(prev => prev > 0 ? prev - 1 : -1);
      } else if (e.key === "Enter" && focusedIndex >= 0) {
        handleSuggestionClick(suggestions[focusedIndex].id);
      } else if (e.key === "Escape") {
        setShowSuggestions(false);
        inputRef.current?.blur();
      }
    };
  
    useEffect(() => {
      if (focusedIndex >= 0 && suggestionsRef.current) {
        const focusedElement = suggestionsRef.current.children[focusedIndex];
        focusedElement?.scrollIntoView({ block: 'nearest' });
      }
    }, [focusedIndex]);
  
    return (
      <div className="relative hidden md:block w-full max-w-md">
        <div className="relative">
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setShowSuggestions(true)}
            onKeyDown={handleKeyDown}
            className="w-full h-12 pl-12 pr-4 text-base bg-white border border-gray-200 rounded-lg shadow-sm transition-all duration-200 outline-none focus:ring-2 focus:ring-blue-100 focus:border-blue-400 hover:border-gray-300"
            placeholder="Search help articles..."
          />
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        </div>
  
        {showSuggestions && suggestions.length > 0 && (
          <>
            <div 
              ref={suggestionsRef}
              className="absolute mt-2 w-full bg-white rounded-lg border border-gray-200 shadow-lg overflow-hidden z-50 max-h-96 overflow-y-auto"
            >
              {suggestions.map((article, index) => (
                <button
                  key={article.id}
                  className={`w-full px-4 py-3 text-left transition-colors duration-150 flex items-start space-x-3 group hover:bg-gray-50 ${
                    focusedIndex === index ? 'bg-gray-50' : ''
                  }`}
                  onClick={() => handleSuggestionClick(article.id)}
                  onMouseEnter={() => setFocusedIndex(index)}
                >
                  <div className="flex-shrink-0 mt-1">
                    <Search className="h-4 w-4 text-gray-400 group-hover:text-gray-600" />
                  </div>
                  <div className="flex-grow min-w-0">
                    <p className="font-medium text-gray-900 truncate">
                      {article.title}
                    </p>
                    <p className="text-sm text-gray-500 mt-0.5">
                      {article.category}
                    </p>
                  </div>
                  <ChevronRight className="flex-shrink-0 h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity mt-1" />
                </button>
              ))}
            </div>
            <div 
              className="fixed inset-0 bg-black/5 backdrop-blur-[2px] z-40"
              onClick={() => setShowSuggestions(false)}
            />
          </>
        )}
      </div>
    );
  };
  
  export default SearchComponent;