import React, { useState, useEffect } from "react";
import { ChevronRight, Plus, Minus, Trash2, ShoppingBag, Package, ArrowRight } from "lucide-react";
import { useSelector } from "react-redux";
import { selectAllItems } from "features/cart/cartSelector";
import { useNavigate } from "react-router-dom";
import CheckoutModal from "./checkout.js/main";

const CartItem = ({ item, quantity, onUpdateQuantity, onRemove }) => (
  <div className="flex items-center gap-4 py-3 px-2 bg-gray-50/50 rounded-lg mb-2">
    <div className="relative h-16 w-16 flex-shrink-0 overflow-hidden rounded-lg bg-white">
      <img
        src={item.image}
        alt={item.name}
        className="h-full w-full object-cover"
      />
    </div>
    
    <div className="flex flex-1 flex-col">
      <div className="flex justify-between">
        <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
        <button
          onClick={onRemove}
          className="text-gray-400 hover:text-red-500 transition-colors"
        >
          <Trash2 className="h-4 w-4" />
        </button>
      </div>
      
      <div className="mt-2 flex items-center justify-between">
        <div className="flex items-center space-x-1 rounded-lg bg-white border border-gray-200">
          <button
            onClick={() => onUpdateQuantity(-1)}
            className="p-1 text-gray-500 hover:text-[#0D4029] disabled:opacity-50 transition-colors"
            disabled={quantity <= 1}
          >
            <Minus className="h-3 w-3" />
          </button>
          <span className="w-6 text-center text-sm font-medium">{quantity}</span>
          <button
            onClick={() => onUpdateQuantity(1)}
            className="p-1 text-gray-500 hover:text-[#0D4029] transition-colors"
          >
            <Plus className="h-3 w-3" />
          </button>
        </div>
        <span className="text-sm font-medium text-gray-900">${item.price}</span>
      </div>
    </div>
  </div>
);

const EmptyState = ({ onBrowse }) => (
  <div className="flex h-full flex-col items-center justify-center px-6 py-12 text-center">
    <div className="w-full max-w-sm">
      <div className="mb-6">
        <div className="relative mx-auto h-20 w-20">
          <div className="absolute inset-0 animate-pulse rounded-full bg-gray-50"></div>
          <ShoppingBag className="absolute inset-0 m-auto h-10 w-10 text-gray-400" />
        </div>
      </div>
      
      <h3 className="text-lg font-semibold text-gray-900 mb-2">
        Your cart is empty
      </h3>
      
      <p className="text-sm text-gray-500 mb-6">
        Browse our selection of premium materials to start your order
      </p>
      
      <button
        onClick={onBrowse}
        className="group relative w-full inline-flex items-center justify-center rounded-lg bg-[#0D4029] px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
      >
        <span className="inline-flex items-center">
          Browse Materials
          <ArrowRight className="ml-2 h-4 w-4 transition-transform duration-200 group-hover:translate-x-1" />
        </span>
      </button>
      
      <div className="mt-6 flex items-center justify-center space-x-2 text-xs text-gray-400">
        <Package className="h-3 w-3" />
        <span>Free shipping on orders over $100</span>
      </div>
    </div>
  </div>
);

const CartPanel = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const allItems = useSelector(selectAllItems);
  const [cartItems, setCartItems] = useState([]);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);

  useEffect(() => {
    const loadCart = () => {
      const savedCart = JSON.parse(localStorage.getItem('clavaaCart') || '{}');
      
      // Solution: Use find() to look up items by their actual id property
      const items = Object.entries(savedCart)
        .map(([id, quantity]) => {
          const item = allItems.find(item => item.id.toString() === id);
          return item ? { ...item, quantity } : null;
        })
        .filter(Boolean);
      
      setCartItems(items);
    };
    loadCart();
  }, [allItems]);

  const updateCart = (items) => {
    const storageData = items.reduce((acc, item) => {
      acc[String(item.id)] = item.quantity;
      return acc;
    }, {});
    
    localStorage.setItem('clavaaCart', JSON.stringify(storageData));
    setCartItems(items);
  };

  const handleQuantityChange = (itemId, change) => {
    const newItems = cartItems.map(item => {
      if (item.id !== itemId) return item;
      const newQuantity = item.quantity + change;
      if (newQuantity <= 0) return null;
      return { ...item, quantity: newQuantity };
    }).filter(Boolean);
    
    updateCart(newItems);
  };

  const handleRemoveItem = (itemId) => {
    const newItems = cartItems.filter(item => String(item.id) !== String(itemId));
    updateCart(newItems);
  };


  const handleCheckoutClose = () => {
    setIsCheckoutOpen(false);
    onClose();
    };

  const navigateToMaterials = () => {
    navigate('/dashboard/material/order');
    onClose();
  };

  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);
  const subtotal = cartItems.reduce((sum, item) => 
    sum + (parseFloat(item.price || 0) * item.quantity), 0);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-black/20 backdrop-blur-sm transition-opacity"
          onClick={onClose}
        />
      )}

      <div
        className={`fixed right-0 top-0 z-50 flex h-screen w-80 flex-col bg-white shadow-lg transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Header */}
        <div className="flex h-14 items-center justify-between border-b border-gray-100 px-4">
          <div className="flex items-center space-x-2">
            <ShoppingBag className="h-4 w-4 text-gray-700" />
            <h2 className="text-base font-medium text-gray-900">
              Cart {totalItems > 0 && `(${totalItems})`}
            </h2>
          </div>
          <button
            onClick={onClose}
            className="rounded-lg p-1.5 text-gray-400 hover:bg-gray-50 hover:text-gray-600 transition-colors"
          >
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          {cartItems.length === 0 ? (
            <EmptyState onBrowse={navigateToMaterials} />
          ) : (
            <div className="p-4">
              {cartItems.map((item) => (
                <CartItem
                  key={item.id}
                  item={item}
                  quantity={item.quantity}
                  onUpdateQuantity={(change) => handleQuantityChange(item.id, change)}
                  onRemove={() => handleRemoveItem(item.id)}
                />
              ))}
            </div>
          )}
        </div>

        {/* Footer */}
        {cartItems.length > 0 && (
          <div className="border-t border-gray-100 px-4 py-3">
            <div className="mb-3 flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700">Subtotal</span>
              <span className="text-base font-semibold text-gray-900">
                ${subtotal.toFixed(2)}
              </span>
            </div>
            
            <button className="group relative flex w-full items-center justify-center rounded-lg bg-[#0D4029] px-4 py-2.5 text-sm font-medium text-white transition-all hover:bg-[#0D4029]/90 focus:outline-none focus:ring-2 focus:ring-[#0D4029] focus:ring-offset-2"
              onClick={() => setIsCheckoutOpen(true)}
            >
              Proceed to Checkout
              <ArrowRight className="ml-2 h-4 w-4 transition-transform duration-200 group-hover:translate-x-1" />
            </button>
            
            <p className="mt-2 text-center text-xs text-gray-400">
              Shipping and taxes calculated at checkout
            </p>
          </div>
        )}
      </div>

      <CheckoutModal
      onClose={ handleCheckoutClose }
        isOpen={isCheckoutOpen}
      />
    </>
  );
};

export default CartPanel;