import React from 'react';

function ResendCodeLoading() {
  return (
    <div className="flex flex-col items-center justify-center w-full p-8 space-y-8">
      {/* Email Icon with Animation Container */}
      <div className="relative">
        {/* Animated Email Icon */}
        <div className="relative w-16 h-16 animate-[sendEmail_2s_ease-in-out_infinite]">
          <svg 
            className="w-12 h-12 text-[#0D4029]" 
            viewBox="0 0 24 24" 
            fill="currentColor"
          >
            <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-.4 4.25l-6.54 4.09c-.65.41-1.47.41-2.12 0L4.4 8.25c-.25-.16-.4-.43-.4-.72 0-.67.73-1.07 1.3-.72L12 11l6.7-4.19c.57-.35 1.3.05 1.3.72 0 .29-.15.56-.4.72z"/>
          </svg>
        </div>

        {/* Rotating Ring Animation */}
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="w-20 h-20 border-4 border-[#0D4029]/10 rounded-full" />
          <div 
            className="absolute inset-0 border-4 border-[#0D4029] rounded-full border-t-transparent animate-spin"
            style={{ 
              animationDuration: '1.5s', 
              animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)' 
            }} 
          />
        </div>
      </div>

      {/* Status Message */}
      <div className="space-y-2 text-center">
        <h2 
          className="text-lg font-medium text-gray-900"
          style={{ 
            fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif',
          }}
        >
          Resending Code
        </h2>
        <p className="text-sm text-gray-500">
          Sending to your email
        </p>
      </div>

      {/* Progress Bar */}
      <div className="w-full max-w-[200px]">
        <div className="h-1 bg-[#0D4029]/10 rounded-full overflow-hidden">
          <div 
            className="h-full w-full bg-[#0D4029] animate-[progress_2s_ease-in-out_infinite]"
            style={{
              transformOrigin: 'left center'
            }}
          />
        </div>
      </div>

      {/* Optional: Status Steps */}
      <div className="text-xs text-gray-400 text-center">
        Check your inbox in a moment
      </div>
    </div>
  );
}

// Add these keyframe animations to your global CSS
const style = document.createElement('style');
style.textContent = `
  @keyframes sendEmail {
    0% { transform: translateY(0) rotate(0deg); }
    25% { transform: translateY(-2px) rotate(-3deg); }
    75% { transform: translateY(2px) rotate(3deg); }
    100% { transform: translateY(0) rotate(0deg); }
  }

  @keyframes progress {
    0% { transform: translateX(-100%); }
    50% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
`;
document.head.appendChild(style);

export default ResendCodeLoading;