import React, { useState, useEffect } from 'react';

function OnboardingModal({ open, onClose }) {
  const [currentStep, setCurrentStep] = useState(-1);
  const [animatingOut, setAnimatingOut] = useState(false);

  const steps = [
    {
      title: 'Business Information',
      description: 'Add your business details for payments and compliance',
      icon: (
        <svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                d="M21 11.5V17.5C21 20 19.5 22 17 22H7C4.5 22 3 20 3 17.5V6.5C3 4 4.5 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C19.5 6 21 8 21 10.5" />
        </svg>
      ),
      features: [
        'Legal business name',
        'Tax identification',
        'Business address',
        'Contact information'
      ]
    },
    {
      title: 'Location Setup',
      description: 'Help customers discover your business',
      icon: (
        <svg className="w-7 h-7" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
                d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" />
        </svg>
      ),
      features: [
        'Store location',
        'Store Photo',
        'Contact details',
        'Business category'
      ]
    }
  ];

  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      setAnimatingOut(true);
      setTimeout(() => {
        onClose();
        setAnimatingOut(false);
      }, 300);
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === -1) {
      setAnimatingOut(true);
      setTimeout(() => {
        onClose();
        setAnimatingOut(false);
      }, 300);
    } else {
      setCurrentStep(prev => prev - 1);
    }
  };

  if (!open) return null;

  const renderStepContent = () => {
    if (currentStep === -1) {
      return (
        <div className="space-y-10">
          {/* Welcome Header */}
          <div className="text-center space-y-3 mb-12">
            <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900"
                style={{ fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif' }}>
              Welcome to Your Dashboard
            </h2>
            <p className="text-gray-600 text-lg max-w-xl mx-auto leading-relaxed">
              Complete these two steps to activate your account and start accepting payments with the Clavaa loyalty program.
            </p>
          </div>

          {/* Steps Overview */}
          <div className="grid sm:grid-cols-2 gap-6">
            {steps.map((step, index) => (
              <div key={index} 
                   className="group relative p-6 rounded-xl border border-gray-200 
                            hover:border-[#0D4029]/20 bg-white transition-all duration-200
                            hover:shadow-lg hover:-translate-y-0.5">
                <div className="flex items-start space-x-5">
                  <div className="flex-shrink-0">
                    <div className="w-12 h-12 rounded-full bg-[#0D4029]/10 text-[#0D4029]
                                  flex items-center justify-center
                                  group-hover:bg-[#0D4029] group-hover:text-white
                                  transition-colors duration-200">
                      {step.icon}
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {step.title}
                    </h3>
                    <p className="text-gray-600 mb-4 text-sm leading-relaxed">
                      {step.description}
                    </p>
                    <ul className="space-y-2">
                      {step.features.map((feature, idx) => (
                        <li key={idx} className="flex items-center text-sm text-gray-500">
                          <svg className="w-4 h-4 mr-2 text-[#0D4029]" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                          </svg>
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pre-configured Notice */}
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <div className="flex items-center justify-center space-x-2 mb-2 text-[#0D4029]">
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="font-medium">Already Configured</span>
            </div>
            <p className="text-gray-600 text-sm max-w-2xl mx-auto">
              We've optimized your payment settings and cashback rates. You can customize these 
              preferences anytime through your dashboard settings. 
            </p>
          </div>

          {/* Action Button */}
          <div className="text-center">
            <button
              onClick={handleNext}
              className="inline-flex items-center justify-center px-8 py-3 bg-[#0D4029] text-white 
                       rounded-lg font-medium text-lg hover:bg-[#0D4029]/90 active:bg-[#0D4029]/95 
                       transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]
                       focus:outline-none focus:ring-2 focus:ring-[#0D4029]/20 min-w-[200px]"
            >
              Get Started
              <svg className="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-8">
        {/* Progress Indicator */}
        <div className="flex items-center justify-center space-x-2 mb-8">
          {steps.map((_, index) => (
            <div key={index} className="flex items-center">
              <div className={`h-2 rounded-full transition-all duration-300 ${
                index === currentStep 
                  ? 'w-20 bg-[#0D4029]' 
                  : index < currentStep 
                    ? 'w-20 bg-[#0D4029]/80'
                    : 'w-2 bg-gray-200'
              }`} />
              {index < steps.length - 1 && (
                <div className="w-4 h-2 bg-transparent" />
              )}
            </div>
          ))}
        </div>

        {/* Step Content */}
        <div className="text-center space-y-8">
          <div className="mx-auto">
            <div className="w-20 h-20 rounded-full bg-[#0D4029]/10 text-[#0D4029]
                          flex items-center justify-center mx-auto">
              {steps[currentStep].icon}
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-3xl font-semibold text-gray-900">
              {steps[currentStep].title}
            </h2>
            <p className="text-lg text-gray-600 max-w-lg mx-auto">
              {steps[currentStep].description}
            </p>
          </div>

          {/* Features List */}
          <div className="bg-gray-50 rounded-2xl p-8 shadow-sm w-full">
            <h4 className="font-medium text-gray-900 mb-6 text-center">
              You'll need to provide:
            </h4>
            <div className="max-w-lg mx-auto">
              <ul className="space-y-4">
                {steps[currentStep].features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-gray-600 bg-white rounded-lg p-4 shadow-sm">
                    <svg className="w-5 h-5 mr-4 flex-shrink-0 text-[#0D4029]" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                    </svg>
                    <span className="text-base">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center space-x-4 pt-8">
          <button
            onClick={handleBack}
            className="px-6 py-2 text-gray-600 hover:text-gray-900 
                     font-medium transition-colors rounded-lg
                     hover:bg-gray-100"
          >
            Back
          </button>
          <button
            onClick={handleNext}
            className="px-8 py-3 bg-[#0D4029] text-white rounded-lg 
                     font-medium hover:bg-[#0D4029]/90 active:bg-[#0D4029]/95 
                     transform transition-all duration-200
                     hover:scale-[1.02] active:scale-[0.98]
                     focus:outline-none focus:ring-2 focus:ring-[#0D4029]/20
                     min-w-[140px]"
          >
            {currentStep === steps.length - 1 ? 'Complete Setup' : 'Continue'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto px-4 py-6 sm:py-12
                    transition-opacity duration-300 ${animatingOut ? 'opacity-0' : 'opacity-100'}`}>
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" onClick={handleBack} />
      
      <div className={`relative transform rounded-2xl bg-white shadow-2xl transition-all duration-300
                      w-full max-w-3xl ${animatingOut ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
        <div className="absolute right-4 top-4">
          <button 
            onClick={handleBack}
            className="rounded-full p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100/80 transition-colors"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="p-8 sm:p-10">
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
}

export default OnboardingModal;