import React, { useState } from 'react';
import {  ChevronDown,  Trash2 } from 'lucide-react';

const BoostCard = ({ boost, isExpanded, onToggle, onDelete }) => {
    const getStatusStyle = (status) => {
      switch(status) {
        case 'active':
          return 'border-green-200 text-green-700';
        case 'scheduled':
          return 'border-blue-200 text-blue-700';
        default:
          return 'border-gray-200 text-gray-700';
      }
    };
  
    return (
      <div className={`bg-white border border-gray-200 rounded-lg hover:border-[#0D4029]/30 transition-all duration-200 flex flex-col ${!isExpanded ? 'h-48' : ''}`}>
        <div className="flex-1 p-4">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-gray-900">
              {boost.name}
            </h3>
            <div className="flex items-center space-x-3">
              <span className={`px-2.5 py-0.5 text-sm rounded-full border ${getStatusStyle(boost.status)} font-medium`}>
                {boost.status}
              </span>
              {boost.status === 'scheduled' && (
                <button 
                  onClick={() => onDelete(boost.id)}
                  className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                  title="Delete boost"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">Target Group</p>
              <p className="mt-1 font-medium">{boost.targetGroup}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Cashback Amount</p>
              <p className="mt-1 text-lg font-semibold text-[#0D4029]">${boost.cashbackAmount}</p>
            </div>
          </div>
  
          {isExpanded && (
            <div className="mt-4 space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Minimum Spend</p>
                  <p className="mt-1 font-medium">${boost.minSpend}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Duration</p>
                  <p className="mt-1 font-medium">{boost.duration}</p>
                </div>
              </div>
              <div className="pt-3 border-t border-gray-100">
                <p className="text-sm text-gray-500 mb-2">Campaign Period</p>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Start Date</p>
                    <p className="mt-1 font-medium">{boost.startDate}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">End Date</p>
                    <p className="mt-1 font-medium">{boost.endDate}</p>
                  </div>
                </div>
              </div>
              <div className="pt-3 border-t border-gray-100">
                <p className="text-sm text-gray-500 mb-2">Performance</p>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Total Redemptions</p>
                    <p className="mt-1 font-semibold text-[#0D4029]">{boost.redemptions}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total Customer Spend</p>
                    <p className="mt-1 font-semibold text-[#0D4029]">${boost.totalSpent.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
  
        <div className="h-10 px-4 border-t border-gray-100">
          <button 
            onClick={() => onToggle(boost.id)}
            className="w-full h-full flex items-center justify-center text-sm text-[#0D4029] font-medium hover:text-[#0D4029]/70 transition-colors"
          >
            {isExpanded ? 'Show less' : 'More details'}
            <ChevronDown 
              className={`w-4 h-4 ml-1 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
            />
          </button>
        </div>
      </div>
    );
  };

export default BoostCard;